import { ComponentProps, FC } from 'react'

import { IconComponent } from '@/lib/types'
import { cn } from '@/lib/utilities'

export const withBreadcrumbIconStyles = (Icon: IconComponent) => {
  const BreadcrumbIcon: FC<ComponentProps<typeof Icon>> = ({
    className,
    ...properties
  }) => <Icon className={cn('size-4', className)} {...properties} />
  BreadcrumbIcon.displayName = 'BreadcrumbIcon'
  return BreadcrumbIcon
}
