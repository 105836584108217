import { ComponentType, HTMLAttributes } from 'react'
import { UnleashClient } from 'unleash-proxy-client'

import { Api } from '@/contexts/ApiProvider'
import { OidcAuthContextState } from '@/contexts/OidcAuthContext'
import { ConfigurationVariables } from '@/lib/configurationVariablesSchemaValidator'
import { IconComponent } from '@/lib/types'
import { FileRouteTypes, routeTree } from '@/routeTree.gen'
import { QueryClient } from '@tanstack/react-query'
import { createRouter, RouteContext } from '@tanstack/react-router'

// @ts-expect-error - Context is added in the Entry.tsx file
const temporaryContext: RouteContext = {}
export const router = createRouter({ context: temporaryContext, routeTree })
export type ValidRoute = FileRouteTypes['fullPaths']

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }

  interface RouteContext {
    queryClient: QueryClient
    api: Api
    getUser: OidcAuthContextState['getUser']
    configurationVariables: ConfigurationVariables
    unleashClient: UnleashClient
  }
}

declare module '@tanstack/router-core' {
  // @ts-expect-error - to suppress "All declarations of 'Route' must have identical type parameters." error
  interface Route {
    interloom?: {
      Breadcrumb?: ComponentType<HTMLAttributes<HTMLSpanElement>>
      NavigationIcon?: IconComponent
      tooltipText?: string
      defaultPageTitle?: string
    }
  }
}
