import { forwardRef, useCallback, useMemo } from 'react'

import { CopyLinkToClipboardIcon } from '@/components/icons/CopyLinkToClipboardIcon'
import { PasteClipboardIcon } from '@/components/icons/PasteClipboardIcon'
import { ContextMenuSeparator } from '@/components/ui/context-menu/ContextMenu'
import {
  ContextMenuGroupHeader,
  GroupedContextMenuContent,
  MenuItem,
} from '@/features/task/components/GroupedContextMenuContent'
import { ArrowRightIcon } from '@/features/task/components/subtasks/ArrowRightIcon'
import { RenameIcon } from '@/features/task/components/subtasks/RenameIcon'
import { TrashIcon } from '@/features/task/components/subtasks/TrashIcon'
import { TaskIcon } from '@/features/task/components/TaskIcon'
import { UnassignedIcon } from '@/features/task/components/UnassignedIcon'
import { useUpdateTask } from '@/features/task/hooks/useUpdateTask'
import { useUsers } from '@/features/task/hooks/useUsers'
import { TaskStatus } from '@/gql/generated/graphql'

interface SubtaskPopoverContentProperties {
  assigneeId?: string | null
  taskId: string
  title: string
  onCopyToClipboard: () => void
  onDelete: () => void
  onRename: () => void
  onTaskStatusChange: (status: TaskStatus) => void
  onOpenTask: () => void
  onPasteTask?: () => void
}

export const SubtaskPopoverContent = forwardRef<
  HTMLDivElement,
  SubtaskPopoverContentProperties
>(
  (
    {
      assigneeId,
      onCopyToClipboard,
      onDelete,
      onOpenTask,
      onPasteTask,
      onRename,
      onTaskStatusChange,
      taskId,
      title,
    },
    reference
  ) => {
    const { users } = useUsers()
    const { updateTask } = useUpdateTask(taskId)

    const handleSelectAssignee = useCallback(
      (assigneeId: string) => {
        updateTask({
          // eslint-disable-next-line unicorn/no-null
          assigneeId: assigneeId === 'unassigned' ? null : assigneeId,
          id: taskId,
        })
      },
      [taskId, updateTask]
    )

    const menuItems = useMemo(
      () =>
        [
          {
            content: <ContextMenuGroupHeader title="Set Status" />,
            id: 'set-status',
            type: 'groupHeader',
          },
          {
            content: (
              <>
                <TaskIcon
                  status="OPEN"
                  size="md"
                  className="group-focus:text-mono-paper"
                />
                Mark open
              </>
            ),
            id: 'subtask-context-mark-open',
            onClick: () => {
              onTaskStatusChange('OPEN')
            },
            type: 'item',
          },
          {
            content: (
              <>
                <TaskIcon
                  status="STARTED"
                  size="md"
                  className="group-focus:text-mono-paper"
                />
                Mark started
              </>
            ),
            id: 'subtask-context-mark-started',
            onClick: () => {
              onTaskStatusChange('STARTED')
            },
            type: 'item',
          },
          {
            content: (
              <>
                <TaskIcon
                  status="COMPLETED"
                  size="md"
                  className="group-focus:text-mono-paper"
                />
                Mark complete
              </>
            ),
            id: 'subtask-context-mark-complete',
            onClick: () => {
              onTaskStatusChange('COMPLETED')
            },
            type: 'item',
          },
          {
            content: (
              <>
                <TaskIcon
                  status="CANCELLED"
                  size="md"
                  className="group-focus:text-mono-paper"
                />
                Mark cancelled
              </>
            ),
            id: 'subtask-context-mark-cancelled',
            onClick: () => {
              onTaskStatusChange('CANCELLED')
            },
            type: 'item',
          },
          {
            content: (
              <>
                <ContextMenuSeparator />
                <ContextMenuGroupHeader title="Actions" />
              </>
            ),
            id: 'actions',
            type: 'groupHeader',
          },
          {
            content: (
              <>
                <ArrowRightIcon className="size-6" />
                Open
                <span className="sr-only">{title} task</span>
              </>
            ),
            id: 'subtask-context-open',
            onClick: onOpenTask,
            type: 'item',
          },
          {
            content: (
              <>
                <TrashIcon className="size-6" />
                Delete
              </>
            ),
            id: 'subtask-context-delete',
            onClick: onDelete,
            type: 'item',
          },
          {
            content: (
              <>
                <RenameIcon className="size-6" />
                Rename
              </>
            ),
            id: 'subtask-context-rename',
            onClick: onRename,
            type: 'item',
          },
          {
            content: (
              <>
                <CopyLinkToClipboardIcon className="size-6" />
                Copy link
              </>
            ),
            id: 'subtask-context-clipboard-copy',
            onClick: onCopyToClipboard,
            type: 'item',
          },
          {
            content: (
              <>
                <PasteClipboardIcon className="size-6" />
                Paste
              </>
            ),
            id: 'subtask-context-clipboard-paste',
            onClick: onPasteTask,
            type: 'item',
          },
          {
            assigneeId: assigneeId,
            content: (
              <>
                <UnassignedIcon className="size-6" />
                Assign to…
              </>
            ),
            id: 'subtask-context-clipboard-assign',
            onAssignUser: handleSelectAssignee,
            type: 'assignToSubMenu',
            users,
          },
        ] satisfies MenuItem[],
      [
        assigneeId,
        handleSelectAssignee,
        onCopyToClipboard,
        onDelete,
        onOpenTask,
        onPasteTask,
        onRename,
        onTaskStatusChange,
        title,
        users,
      ]
    )

    return (
      <GroupedContextMenuContent
        ref={reference}
        taskId={taskId}
        testId="subtask"
        menuItems={menuItems}
      />
    )
  }
)

SubtaskPopoverContent.displayName = 'SubtaskPopoverContent'
