import { FC } from 'react'

import { CancelCrossFilledStatusIcon } from '@/components/icons/CancelCrossFilledStatusIcon'
import { CheckedCircleSolidIcon } from '@/components/icons/CheckedCircleSolidIcon'
import { PlusMathIcon } from '@/components/icons/PlusMathIcon'
import { TaskCircularCheckboxHalfStartedIcon } from '@/components/icons/TaskCircularCheckboxHalfStartedIcon'
import { TaskUncheckedCircleSolidIcon } from '@/components/icons/TaskUncheckedCircleSolidIcon'
import { TaskThreadEventPayloadTaskUpdate } from '@/features/task/components/chat/threadPayloadTypes'
import { IconComponent } from '@/lib/types'
import { cn } from '@/lib/utilities'
import { TaskUpdateType } from '@/tests/generated-api-types'

interface ThreadStatus {
  icon: IconComponent
  text: string
}

const threadStatus: Record<TaskUpdateType, ThreadStatus> = {
  CREATED: {
    icon: PlusMathIcon,
    text: 'created task',
  },
  STATUS_CANCELLED: {
    icon: CancelCrossFilledStatusIcon,
    text: 'marked task as cancelled',
  },
  STATUS_COMPLETED: {
    icon: CheckedCircleSolidIcon,
    text: 'marked task as complete',
  },
  STATUS_OPENED: {
    icon: TaskUncheckedCircleSolidIcon,
    text: 'marked task as open',
  },
  STATUS_STARTED: {
    icon: TaskCircularCheckboxHalfStartedIcon,
    text: 'marked task as started',
  },
}

interface ChatMessagePayloadProperties {
  taskUpdate: TaskThreadEventPayloadTaskUpdate
}

export const TaskUpdateMessagePayload: FC<ChatMessagePayloadProperties> = ({
  taskUpdate,
}) => {
  const { icon: Icon } = threadStatus[taskUpdate.type]

  return (
    <div className="flex w-fit max-w-full items-center gap-1 rounded-full bg-[#ECE8E1] p-1.5 pr-3">
      <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gold p-1">
        <Icon
          className={cn('text-white', {
            'size-4': taskUpdate.type === 'CREATED',
          })}
        />
      </div>

      <div className="flex flex-col overflow-hidden">
        <p className="truncate text-sm font-medium text-[#694C2F]">
          {taskUpdate.affectedTask.title}
        </p>
        {!!taskUpdate.affectedTask.description && (
          <p className="truncate text-xs-regular text-mono-ink-subtle">
            {taskUpdate.affectedTask.description}
          </p>
        )}
      </div>
    </div>
  )
}

TaskUpdateMessagePayload.displayName = 'TaskUpdateMessagePayload'

export const TaskUpdateMessagePayloadForUser: FC<
  ChatMessagePayloadProperties
> = ({ taskUpdate }) => {
  return (
    <>
      <p className="text-sm-regular text-mono-ink-subtle">
        {threadStatus[taskUpdate.type].text}
      </p>
      <div className="h-0.75 w-0.75 rounded-full bg-film-strongest" />
    </>
  )
}

TaskUpdateMessagePayloadForUser.displayName = 'TaskUpdateMessagePayloadForUser'
