import { FC } from 'react'

import { ArrowDownIcon } from '@/components/icons/ArrowDownIcon'
import { Button } from '@/components/ui/button/Button'
import { ChatMessageInput } from '@/features/task/components/chat/ChatMessageInput'
import { useChatContext } from '@/features/task/components/chat/ChatProvider'

export const ChatMessageInputWithNewMessages: FC = () => {
  const {
    handleScrollToNewMessage,
    handleSendMessage,
    hasNewReceivedMessage,
    isCreatingNewMessage,
  } = useChatContext()

  return (
    <div className="relative">
      {hasNewReceivedMessage && (
        <Button
          size="sm"
          variant="solid"
          type="button"
          className="absolute -top-10 left-1/2 -translate-x-1/2 transform"
          onClick={handleScrollToNewMessage}
        >
          <ArrowDownIcon />
          New messages
        </Button>
      )}

      <ChatMessageInput
        onSendMessage={handleSendMessage}
        disabled={isCreatingNewMessage}
      />
    </div>
  )
}

ChatMessageInputWithNewMessages.displayName = 'ChatMessageInputWithNewMessages'
