import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { FetchViewerWithNotificationsQuery } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface UseMarkNotificationAsReadProperties {
  ids: string[]
  onMutate?: () => void
  onError?: () => void
}

export const useMarkNotificationsAsRead = (
  properties?: UseMarkNotificationAsReadProperties
) => {
  const api = useApi()

  const queryKey = ['viewerWithNotifications']
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.markNotificationsAsRead,
    onMutate: async (ids) => {
      const previousState =
        queryClient.getQueryData<FetchViewerWithNotificationsQuery>(queryKey)

      queryClient.setQueryData<FetchViewerWithNotificationsQuery>(
        queryKey,
        (previousState) => {
          if (!previousState?.viewer) return

          const notifications = previousState.viewer.notifications.map(
            (notification) => ({
              ...notification,
              ...(ids.includes(notification.id) && { isUnread: false }),
            })
          )

          return {
            viewer: {
              ...previousState.viewer,
              notifications,
            },
          }
        }
      )
      properties?.onMutate?.()

      return { previousState }
    },
    // Partition: Important to keep these definitions below onMutate property so the TS compiler infers context properly
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousState)
      logger.error(error)
      toast.error('Marking notification as read failed!')
      properties?.onError?.()
    },
  })
}
