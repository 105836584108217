import { useCallback, useEffect, useRef, useMemo } from 'react'

export const useDocumentKeyCapture = (
  keys: string | string[],
  callback: (parameters: { event: KeyboardEvent }) => void,
  isActive = true,
  phase: 'capture' | 'bubble' = 'bubble'
) => {
  const keyArray = useMemo(() => (Array.isArray(keys) ? keys : [keys]), [keys])
  const pressedKeys = useRef<string[]>([])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (pressedKeys.current.includes(event.key)) return

      pressedKeys.current.push(event.key)

      const isCorrectOrder = keyArray.every(
        (key, index) => pressedKeys.current[index] === key
      )

      if (isCorrectOrder && pressedKeys.current.length === keyArray.length) {
        callback({ event })
        pressedKeys.current = []
      }
    },
    [keyArray, callback]
  )

  const handleKeyUp = useCallback(() => {
    pressedKeys.current = []
  }, [])

  useEffect(() => {
    if (!isActive) return

    const capture = phase === 'capture'
    document.addEventListener('keydown', handleKeyDown, { capture })
    document.addEventListener('keyup', handleKeyUp, { capture })

    return () => {
      document.removeEventListener('keydown', handleKeyDown, {
        capture,
      })
      document.removeEventListener('keyup', handleKeyUp, {
        capture,
      })
    }
  }, [handleKeyDown, handleKeyUp, isActive, phase])
}
