import sortBy from 'lodash/sortBy'
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { toast } from 'react-toastify'

import {
  TaskThreadEventPayload,
  TaskThreadEventPayloadMessage,
} from '@/features/task/components/chat/threadPayloadTypes'
import { useCreateChatMessage } from '@/features/task/components/chat/useCreateChatMessage'
import { useTaskDetailPageContext } from '@/features/task/hooks/useTaskDetailPageContext'
import {
  CreateMessageInput,
  GetThreadEventsQuery,
  FileMetadata,
} from '@/gql/generated/graphql'
import { useViewer } from '@/lib/hooks/useViewer'
import { logger } from '@/lib/logger'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'

const MESSAGES_REFETCH_INTERVAL = 1000

type ThreadEvents = GetThreadEventsQuery['tasks'][number]

interface ChatContextValue {
  createNewMessage: ReturnType<typeof useCreateChatMessage>['mutate']
  handleScrollToNewMessage: () => void
  hasNewReceivedMessage: boolean
  isCreatingNewMessage: boolean
  newMessageCallback: (node: HTMLDivElement | null) => void
  previousMessageCallback: (node: HTMLDivElement | null) => void
  thread: ThreadEvents | undefined
  threadEvents: ThreadEvents['thread']['events']
  taskId: string
  handleSendMessage: (data: {
    message: string
    fileIds: string[]
    fileMetaData: FileMetadata[]
  }) => void
}

interface CreateFetchTaskMessagesOptions {
  api: RouteContext['api']
  taskId: string
}

const createFetchTaskThreadEventsOptions = ({
  api,
  taskId,
}: CreateFetchTaskMessagesOptions) => {
  return {
    queryFn: async () => {
      const response = await api.fetchTaskThreadEvents(taskId)
      return response[0]
    },
    queryKey: ['messages', taskId],
  }
}

const ChatContext = createContext<ChatContextValue | undefined>(undefined)
ChatContext.displayName = 'ChatContext'

interface ChatProviderProperties {
  taskId: string
  children: React.ReactNode
}

export const ChatProvider: React.FC<ChatProviderProperties> = ({
  children,
  taskId,
}) => {
  const { viewer } = useViewer()
  const { api } = useTaskDetailPageContext()
  const queryKey = useMemo(() => ['messages', taskId], [taskId])
  const queryClient = useQueryClient()

  const newReceivedMessageReference = useRef<HTMLDivElement | undefined>(
    undefined
  )
  const newMessageObserver = useRef<IntersectionObserver | undefined>(undefined)
  const previousMessageObserver = useRef<IntersectionObserver | undefined>(
    undefined
  )
  const previousMessagesCount = useRef<number>(0)

  const [isMessageInView, setIsMessageInView] = useState(false)
  const [hasNewReceivedMessage, setHasNewReceivedMessage] = useState(false)

  const handleScrollToNewMessage = useCallback(
    (options?: ScrollIntoViewOptions) => {
      newReceivedMessageReference.current?.scrollIntoView(
        options ?? { behavior: 'smooth' }
      )
    },
    []
  )

  const handleMutate = useCallback(
    async (
      variables: CreateMessageInput & {
        fileMetaData?: FileMetadata[]
      }
    ) => {
      await queryClient.cancelQueries({ queryKey })

      const previousThread = queryClient.getQueryData<ThreadEvents>(queryKey)

      if (!previousThread) return

      const messagePayload: TaskThreadEventPayloadMessage[] = [
        {
          __typename: 'Message',
          author: {
            avatarUrl: viewer.avatarUrl,
            id: viewer.id,
            name: viewer.name,
          },
          body: { text: variables.body.text },
          id: `not-synced-${Date.now()}`,
        },
      ]

      const filePayload: TaskThreadEventPayload[] =
        variables.body.fileIds?.flatMap((fileId) => {
          const fileMetadata = variables.fileMetaData?.find(
            (item) => item.id === fileId
          )

          if (!fileMetadata?.__typename) return []
          return [
            {
              ...fileMetadata,
              __typename: fileMetadata.__typename,
              mimeType: fileMetadata.type,
            },
          ]
        }) ?? []

      queryClient.setQueryData<ThreadEvents>(queryKey, {
        ...previousThread,
        thread: {
          ...previousThread.thread,
          events: [
            ...previousThread.thread.events,
            {
              createdAt: new Date().toISOString(),
              id: `not-synced-thread-event-${Date.now()}`,
              payload: [...messagePayload, ...filePayload],
            },
          ],
        },
      })

      setIsMessageInView(true)
      handleScrollToNewMessage({ behavior: 'instant' })

      return previousThread
    },
    [queryClient, queryKey, viewer, handleScrollToNewMessage]
  )

  const handleError = useCallback(
    (
      error: Error,
      _variables: CreateMessageInput,
      previousThread?: ThreadEvents | null
    ) => {
      if (previousThread) {
        queryClient.setQueryData(queryKey, previousThread)
      }

      logger.error(error)
      toast.error(error.message, {
        toastId: error.message,
      })
    },
    [queryClient, queryKey]
  )

  const handleSettled = useCallback(() => {
    handleScrollToNewMessage({ behavior: 'instant' })
  }, [handleScrollToNewMessage])

  const { isPending, mutate: createNewMessage } = useCreateChatMessage({
    handleError,
    handleMutate,
    handleSettled,
  })

  const threadEventsData = useQuery({
    ...createFetchTaskThreadEventsOptions({ api, taskId }),
    refetchInterval: MESSAGES_REFETCH_INTERVAL,
  })

  const threadEvents = useMemo(
    () =>
      sortBy(
        threadEventsData.data?.thread.events ?? [],
        (message) => new Date(message.createdAt)
      ),
    [threadEventsData.data?.thread.events]
  )

  const previousMessageCallback = useCallback((node: HTMLDivElement | null) => {
    // Scroll to bottom of chat on initialisation
    if (node && previousMessagesCount.current === 0) {
      node.scrollIntoView({
        behavior: 'instant',
      })

      return
    }

    if (previousMessageObserver.current) {
      previousMessageObserver.current.disconnect()
    }

    previousMessageObserver.current = new IntersectionObserver((entries) => {
      // Scroll to newly received message if last message is in view
      if (entries[0]?.isIntersecting) {
        entries[0].target.nextElementSibling?.scrollIntoView({
          behavior: 'instant',
        })
        setIsMessageInView(true)
      }
    })

    if (node) {
      previousMessageObserver.current.observe(node)
    }
  }, [])

  const newMessageCallback = useCallback(
    (node: HTMLDivElement | null) => {
      const messages = threadEvents

      if (messages.length === 0) return

      if (newMessageObserver.current) {
        newMessageObserver.current.disconnect()
      }

      newMessageObserver.current = new IntersectionObserver((entries) => {
        // Determine when new message is received if new message is out of scroll
        if (entries[0]?.isIntersecting) return

        if (previousMessagesCount.current === 0) {
          previousMessagesCount.current = messages.length
          return
        }

        if (previousMessagesCount.current < messages.length) {
          setHasNewReceivedMessage(true)
          previousMessagesCount.current = messages.length
        } else {
          setIsMessageInView(false)
          setHasNewReceivedMessage(false)
        }
      })

      if (node) {
        newMessageObserver.current.observe(node)
        newReceivedMessageReference.current = node
      }
    },
    [threadEvents]
  )

  const handleSendMessage = useCallback(
    (data: {
      message: string
      fileIds: string[]
      fileMetaData: FileMetadata[]
    }) => {
      // consolidate for different string format from react mention
      const formatMentions = (text: string) => {
        const mentionRegex = /@\[(.+?)\]\((.+?)\)/g

        return text.replaceAll(mentionRegex, '<@U$2> ')
      }

      if (threadEventsData.data?.thread.id) {
        createNewMessage({
          body: {
            fileIds: data.fileIds,
            text: formatMentions(data.message),
          },
          fileMetaData: data.fileMetaData,
          threadId: threadEventsData.data.thread.id,
        })
      }
    },
    [createNewMessage, threadEventsData.data?.thread.id]
  )

  return (
    <ChatContext.Provider
      value={{
        createNewMessage,
        handleScrollToNewMessage,
        handleSendMessage,
        hasNewReceivedMessage: !isMessageInView && hasNewReceivedMessage,
        isCreatingNewMessage: isPending,
        newMessageCallback,
        previousMessageCallback,
        taskId,
        thread: threadEventsData.data,
        threadEvents,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export const useChatContext = (): ChatContextValue => {
  const context = useContext(ChatContext)
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider')
  }
  return context
}
ChatProvider.displayName = 'ChatProvider'
