import { DownIcon } from '@/components/icons/DownIcon'
import FileListItem from '@/components/ui/file-list-item/FileListItem'
import { Tooltip } from '@/components/ui/tooltip/Tooltip'
import { FileTypeIcon } from '@/features/task/components/chat/FileTypeIcon'
import { getFileType } from '@/features/task/components/chat/supportedFileTypes'
import { TaskThreadEventPayloadFileMetadata } from '@/features/task/components/chat/threadPayloadTypes'
import { getApiHost } from '@/lib/getApiHost'
import { formatBytes } from '@/lib/utilities'

interface OtherFilesMessagePayloadProperties {
  files: TaskThreadEventPayloadFileMetadata[]
}

export const OtherFilesMessagePayload: React.FC<
  OtherFilesMessagePayloadProperties
> = ({ files }) => {
  return (
    <div className="grid grid-cols-[repeat(auto-fit,minmax(--container-sm,1fr))] gap-2">
      {files.map((file, index) => (
        <FileListItem.Root key={file.fileUrl + index}>
          <FileListItem.Content>
            <FileListItem.Icon asChild={true}>
              <FileTypeIcon fileType={file.mimeType} />
            </FileListItem.Icon>
            <FileListItem.LabelGroup>
              <FileListItem.Label size="lg">{file.name}</FileListItem.Label>
              <FileListItem.Label>
                {getFileType(file.mimeType)} · {formatBytes(file.size)}
              </FileListItem.Label>
            </FileListItem.LabelGroup>
          </FileListItem.Content>
          <FileListItem.ButtonGroup>
            <Tooltip content="Download">
              <FileListItem.Button asChild>
                <a href={getApiHost() + file.fileUrl} download>
                  <DownIcon className="fill-mono-ink-fade" />
                </a>
              </FileListItem.Button>
            </Tooltip>
          </FileListItem.ButtonGroup>
        </FileListItem.Root>
      ))}
    </div>
  )
}

OtherFilesMessagePayload.displayName = 'OtherFilesMessagePayload'
