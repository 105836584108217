import { cva, VariantProps } from 'class-variance-authority'
import { PropsWithChildren, HTMLAttributes, forwardRef } from 'react'

import { cn } from '@/lib/utilities'

const taskListItemVariants = cva(
  [
    'flex items-center gap-4 rounded-lg border border-film-subtle bg-mono-paper p-3',
    'transition-colors transition-text',
    'active:shadow-none active:transition-none',
  ],
  {
    defaultVariants: {
      size: 'large',
    },
    variants: {
      highlighted: {
        true: 'border-sky-300 bg-sky-50 outline-hidden hover:border-sky-300',
      },
      size: {
        large: 'h-16 p-3',
        medium: 'h-14 p-2',
      },
      withHover: {
        true: 'hover:border-film-stronger hover:shadow-nano cursor-pointer',
      },
    },
  }
)

interface TaskListItemProperties
  extends PropsWithChildren,
    HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof taskListItemVariants> {
  start?: React.ReactNode[] | React.ReactNode
  end?: React.ReactNode[] | React.ReactNode
  withHover?: boolean
}

export const TaskListItem = forwardRef<HTMLDivElement, TaskListItemProperties>(
  (
    {
      children,
      className,
      end,
      highlighted,
      size,
      start,
      withHover = true,
      ...rest
    },
    reference
  ) => {
    const startNodes = Array.isArray(start) ? start : [start]
    const endNodes = Array.isArray(end) ? end : [end]

    return (
      <div
        ref={reference}
        className={cn(
          taskListItemVariants({ className, highlighted, size, withHover })
        )}
        {...rest}
      >
        <div className="flex items-center gap-1 px-2">
          {startNodes.length > 0 &&
            startNodes.map((node, index) => <div key={index}>{node}</div>)}
        </div>

        <div className="flex w-1 grow flex-col">{children}</div>

        <div className="flex items-center gap-1 px-3">
          {endNodes.length > 0 &&
            endNodes.map((node, index) => <div key={index}>{node}</div>)}
        </div>
      </div>
    )
  }
)
TaskListItem.displayName = 'TaskListItem'
