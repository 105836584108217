import { SettingsCogIcon } from '@/components/icons/SettingsCogIcon'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_private/settings')()

const SettingsBreadcrumb = () => (
  <div className="flex items-center gap-2 text-xs-regular text-mono-ink-subtle">
    <SettingsCogIcon className="size-4" />
    <p>Settings</p>
  </div>
)

SettingsBreadcrumb.displayName = 'SettingsBreadcrumb'

Route.interloom = {
  Breadcrumb: SettingsBreadcrumb,
  defaultPageTitle: 'Settings',
  NavigationIcon: SettingsCogIcon,
  tooltipText: 'Settings',
}
