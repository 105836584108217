import { ComponentPropsWithoutRef, ComponentRef, forwardRef } from 'react'

import { cn } from '@/lib/utilities'
import * as PopoverPrimitive from '@radix-ui/react-popover'
export const Popover = PopoverPrimitive.Root
export const PopoverTrigger = PopoverPrimitive.Trigger
export const PopoverAnchor = PopoverPrimitive.Anchor
export const PopoverPortal = PopoverPrimitive.Portal
export const PopoverContent = forwardRef<
  ComponentRef<typeof PopoverPrimitive.Content>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    useTriggerWidth?: boolean
    innerContainerClassName?: string
  }
>(
  (
    {
      align = 'start',
      asChild = true,
      children,
      className,
      innerContainerClassName,
      sideOffset = 8,
      useTriggerWidth = true,
      ...properties
    },
    reference
  ) => (
    <PopoverPrimitive.Content
      align={align}
      asChild={asChild}
      className={cn(
        { 'min-w-[var(--radix-popover-trigger-width)]': useTriggerWidth },
        className
      )}
      ref={reference}
      sideOffset={sideOffset}
      {...properties}
    >
      <div
        className={cn(
          'rounded-lg bg-mono-paper p-1 shadow-just-shadow',
          innerContainerClassName
        )}
      >
        {children}
      </div>
    </PopoverPrimitive.Content>
  )
)
PopoverContent.displayName = PopoverPrimitive.Content.displayName
export const PopoverClose = PopoverPrimitive.Close
export const PopoverArrow = PopoverPrimitive.Arrow
