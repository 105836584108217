import { forwardRef, useMemo, Suspense } from 'react'

import { Skeleton } from '@/components/ui/skeleton/Skeleton'
import { ChatViewEventsThread } from '@/features/task/components/chat/chat-view/ChatViewEventsThread'
import { ChatMessageInputWithNewMessages } from '@/features/task/components/chat/ChatMessageInputWithNewMessages'
import { ChatProvider } from '@/features/task/components/chat/ChatProvider'
import { TaskSettings } from '@/features/task/components/chat/TaskSettings'
import { DeleteTaskPopover } from '@/features/task/components/DeleteTaskPopover'
import { useTask } from '@/features/task/hooks/useTask'
import { cn } from '@/lib/utilities'

interface TaskThreadProperties {
  className?: string
  isChatOverlayVisible: boolean
  id: string
}

const TaskThread = forwardRef<HTMLDivElement, TaskThreadProperties>(
  ({ className, id, isChatOverlayVisible, ...properties }, reference) => {
    const { task } = useTask(id)

    return (
      <ChatProvider taskId={id}>
        <div
          ref={reference}
          className={cn(
            'flex h-full flex-1 grow flex-col overflow-auto data-[state="inactive"]:hidden @2xl/task:flex-row',
            className
          )}
          {...properties}
        >
          <TaskSettings id={id} task={task} />

          <div className="flex flex-col items-center px-5 @2xl/task:flex-1 @2xl/task:overflow-y-auto">
            <div
              id="chat-overlay"
              className={cn(
                'flex-1 rounded-2xl border border-solid border-film-normal bg-mono-paper-darker shadow-just-shadow',
                {
                  'flex flex-col': isChatOverlayVisible,
                  hidden: !isChatOverlayVisible,
                }
              )}
            />
            {!isChatOverlayVisible && (
              <div
                className="flex w-full flex-col gap-6 py-6 @2xl/task:max-w-176"
                data-testid="chat-view"
              >
                <ChatViewEventsThread />
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full @2xl/task:self-end @2xl/task:before:max-w-176 @2xl/task:before:flex-1 @2xl/task:before:border-r @2xl/task:before:border-film-subtle @2xl/task:before:content-['']">
          <div className="flex flex-1 justify-center border-t border-film-subtle @2xl/task:border-t-0">
            <div className="max-w-176 flex-1 p-2">
              <ChatMessageInputWithNewMessages />
            </div>
          </div>
        </div>

        <DeleteTaskPopover
          taskId={task.id}
          parentTaskId={task.parentTask?.id}
        />
      </ChatProvider>
    )
  }
)

TaskThread.displayName = 'TaskThread'

const SuspendedTaskThread = forwardRef<HTMLDivElement, TaskThreadProperties>(
  ({ className, isChatOverlayVisible, ...properties }, reference) => {
    const skeleton = useMemo(
      () => (
        <div
          className={cn(
            'flex h-full flex-1 grow flex-col overflow-auto data-[state="inactive"]:hidden @2xl/task:flex-row @2xl/task:overflow-hidden',
            className
          )}
          data-testid="task-thread-skeleton"
        >
          <div className="w-full max-w-[44rem] border-film-subtle px-5 pb-5 @2xl/task:w-1/2 @2xl/task:flex-1 @2xl/task:overflow-y-auto @2xl/task:border-r">
            <div className="flex gap-2 py-4">
              <Skeleton className="h-8 flex-1 bg-film-subtle" />
              <Skeleton className="h-8 flex-1 bg-film-subtle" />
              <Skeleton className="h-8 flex-1 bg-film-subtle" />
            </div>
            <Skeleton className="h-9 w-full bg-film-subtle" />
            <div className="my-5 h-[1px] w-full bg-black/5" />
            <Skeleton className="h-36 w-full bg-film-subtle" />
          </div>
          <div className="mx-auto flex w-full grow flex-col justify-end p-5 @2xl/task:max-w-[44rem] @2xl/task:flex-1 @2xl/task:overflow-hidden">
            <Skeleton className="h-14 w-full bg-film-subtle" />
          </div>
        </div>
      ),
      [className]
    )

    return (
      <Suspense fallback={skeleton}>
        <TaskThread
          ref={reference}
          className={className}
          isChatOverlayVisible={isChatOverlayVisible}
          {...properties}
        />
      </Suspense>
    )
  }
)

SuspendedTaskThread.displayName = 'SuspendedTaskThread'

export { SuspendedTaskThread as TaskThread }
