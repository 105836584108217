import constant from 'lodash/constant'
import { useCallback } from 'react'

import { TaskStatus } from '@/gql/generated/graphql'
import { useViewer } from '@/lib/hooks/useViewer'
import { TASK_FILTER_VALUES } from '@/routes/_private'
import { useSearch } from '@tanstack/react-router'

const filterConditions: Record<
  (typeof TASK_FILTER_VALUES)[number],
  (status: TaskStatus) => boolean
> = {
  all: constant(true),
  cancelled: (status) => status === 'CANCELLED',
  concluded: (status) => status === 'COMPLETED' || status === 'CANCELLED',
  open: (status) => status === 'OPEN',
  started: (status) => status === 'STARTED',
}

export const useTaskFilter = () => {
  const { viewer } = useViewer()
  const search = useSearch({
    from: '/_private',
  })

  const activeFilter = search.filter ?? 'all'

  const filterTasks = useCallback(
    <TTask extends { status: TaskStatus; assignee?: { id: string } | null }>(
      tasks: TTask[]
    ) =>
      tasks.filter(
        (task) =>
          filterConditions[activeFilter](task.status) &&
          (!search.assignedToMe || task.assignee?.id === viewer.id)
      ),
    [activeFilter, search.assignedToMe, viewer.id]
  )

  return {
    activeFilter,
    filterTasks,
    isAssignedToMe: search.assignedToMe,
    search,
  }
}
