import { ClientError } from 'graphql-request'
import isNil from 'lodash/isNil'
import isObject from 'lodash/isObject'
import omitBy from 'lodash/omitBy'
import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import {
  FetchTasksByIdQuery,
  UpdateTaskInput,
  UpdateTaskMutation,
  FetchAllTasksQuery,
  FetchViewerWithTasksQuery,
  SimilarTasksQuery,
} from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from '@tanstack/react-router'

type Task = FetchTasksByIdQuery['tasks'][number]
type Subtask = Task['subtasks'][number]

interface UseUpdateTaskProperties {
  onError?: () => void
  onSuccess?: (data: UpdateTaskMutation, variables: UpdateTaskInput) => void
}

interface UseUpdateTaskListsCacheProperties {
  queryClient: ReturnType<typeof useQueryClient>
  spaceId?: string
  updatedTask:
    | UpdateTaskMutation['updateTask']
    | FetchTasksByIdQuery['tasks'][number]
  variables?: UpdateTaskInput
}

export const updateTaskListsCache = ({
  queryClient,
  spaceId,
  updatedTask,
  variables,
}: UseUpdateTaskListsCacheProperties) => {
  queryClient.setQueryData(
    ['tasks', ...(spaceId ? [spaceId] : [])],
    (previousState: FetchAllTasksQuery['tasks']) => {
      if (!previousState) return

      if (variables?.spaceId && variables.spaceId !== spaceId) {
        return previousState.filter((task) => task.id !== updatedTask.id)
      }

      return previousState.map((task) =>
        task.id === updatedTask.id ? { ...task, ...updatedTask } : task
      )
    }
  )

  queryClient.setQueryData(
    ['viewerWithTasks'],
    (previousState: FetchViewerWithTasksQuery) => {
      if (!previousState) return

      return {
        ...previousState,
        viewer: {
          ...previousState.viewer,
          tasks: previousState.viewer.tasks.map((task) =>
            task.id === updatedTask.id ? { ...task, ...updatedTask } : task
          ),
        },
      }
    }
  )
}

export const useUpdateTask = (
  taskId: string,
  properties?: UseUpdateTaskProperties
) => {
  const router = useRouter()
  const spacesRegex = /\/spaces\/([^/]+)/
  const spacesMatch = spacesRegex.exec(router.state.location.pathname)

  const api = useApi()
  const queryClient = useQueryClient()

  const taskQueryKey = ['task', taskId]

  const updateTaskMutation = useMutation({
    mutationFn: api.updateTask,
    onMutate: async ({ assigneeId, id, ...payload }) => {
      const previousState = queryClient.getQueryData<Task>(taskQueryKey)

      queryClient.setQueryData<Task>(taskQueryKey, (previousState) => {
        /* v8 ignore next */
        if (!previousState) return

        const payloadWithoutNilValues = omitBy(payload, isNil)

        if (taskId === id) {
          return {
            ...previousState,
            ...payloadWithoutNilValues,
          }
        }

        return {
          ...previousState,
          subtasks: previousState.subtasks.map((task) => {
            if (task.id === id) {
              return {
                ...task,
                ...payloadWithoutNilValues,
              }
            }

            return task
          }),
        }
      })

      return { previousState }
    },
    // Partition: Important to keep these definitions below onMutate property so the TS compiler infers context properly
    onError: (error, _, context) => {
      queryClient.setQueryData(taskQueryKey, context?.previousState)
      logger.error(error)
      if (error instanceof ClientError) {
        toast.error(error.response.errors?.[0]?.message)
      } else {
        toast.error('Task update failed!')
      }

      properties?.onError?.()
    },
    onSuccess: (data, variables) => {
      updateTaskListsCache({
        queryClient: queryClient,
        updatedTask: data.updateTask,
        ...(spacesMatch && { spaceId: spacesMatch[1] }),
        variables,
      })

      // Similar tasks
      queryClient.setQueriesData(
        { queryKey: ['similarTasks'] },
        (previousState) => {
          /* v8 ignore next */
          if (!previousState || !Array.isArray(previousState)) return

          return previousState.map(
            (task: SimilarTasksQuery['similarTasks'][number]) =>
              task.task.id === data.updateTask.id
                ? { ...task, task: { ...task.task, ...data.updateTask } }
                : task
          )
        }
      )

      // Subtasks
      queryClient.setQueriesData({ queryKey: ['task'] }, (previousState) => {
        if (
          !previousState ||
          !isObject(previousState) ||
          !('subtasks' in previousState) ||
          !Array.isArray(previousState.subtasks)
        ) {
          return
        }

        const updatedSubtasks = previousState.subtasks.map(
          (subtask: Subtask) =>
            subtask.id === data.updateTask.id
              ? { ...subtask, ...data.updateTask }
              : subtask
        )

        return {
          ...previousState,
          subtasks: updatedSubtasks,
        }
      })

      queryClient.setQueryData(taskQueryKey, (previousState: Task) => {
        /* v8 ignore next */
        if (!previousState) return data.updateTask

        return {
          ...previousState,
          ...data.updateTask,
        }
      })

      properties?.onSuccess?.(data, variables)
    },
  })

  return {
    isUpdatingTask: updateTaskMutation.isPending,
    updateTask: updateTaskMutation.mutate,
    updateTaskAsync: updateTaskMutation.mutateAsync,
  }
}
