import { useCallback, useMemo } from 'react'

import { AlertDialog } from '@/components/ui/alertDialog/AlertDialog'
import { SubtaskDialogItem } from '@/features/task/components/subtasks/SubtaskDialogItem'
import { useMoveTask } from '@/features/task/hooks/useMoveTask'
import { FetchTasksByIdQuery } from '@/gql/generated/graphql'

interface PasteTaskDialogProperties {
  parentTaskId: string
  onOpenChange: (open: boolean) => void
  taskToMoveOverId: string
  isDialogOpen: boolean
  taskToMove: FetchTasksByIdQuery['tasks'][number]['subtasks'][number]
}

export const PasteTasksDialog = ({
  isDialogOpen,
  onOpenChange,
  parentTaskId,
  taskToMove,
  taskToMoveOverId,
}: PasteTaskDialogProperties) => {
  const { moveTask } = useMoveTask(taskToMove.id)

  const handleMove = useCallback(() => {
    void moveTask(
      {
        ...taskToMove,
        parentTaskId,
      },
      taskToMoveOverId
    )
  }, [moveTask, parentTaskId, taskToMove, taskToMoveOverId])

  const DialogItem = useMemo(
    () => (
      <SubtaskDialogItem
        taskId={taskToMove.id}
        title={taskToMove.title}
        isCompleted={!!taskToMove.completedAt}
        assignee={taskToMove.assignee}
      />
    ),
    [taskToMove]
  )

  return (
    <AlertDialog
      actionText="Move"
      cancelText="Cancel"
      description="The thread and subordinate tasks will also be moved."
      onActionClick={handleMove}
      onOpenChange={onOpenChange}
      title="Move this task?"
      isDialogOpen={isDialogOpen}
      beforeTitle={DialogItem}
    />
  )
}

PasteTasksDialog.displayName = 'PasteTasksDialog'
