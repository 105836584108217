import { cn } from '@/lib/utilities'

export const SubtasksView = ({
  body,
  bodyClassName,
  containerClassName,
  footer,
  heading,
}: {
  containerClassName?: string
  bodyClassName?: string
  heading: React.ReactNode
  body: React.ReactNode
  footer?: React.ReactNode
}) => {
  return (
    <div className={cn('rounded-xl border p-2', containerClassName)}>
      <div className="flex items-center justify-between">{heading}</div>
      <div className={cn('mt-2 flex flex-col rounded-lg', bodyClassName)}>
        {body}
      </div>
      {footer}
    </div>
  )
}

SubtasksView.displayName = 'SubtasksView'
