import React, { useCallback } from 'react'

import { TaskIcon } from '@/features/task/components/TaskIcon'
import { TaskStatus } from '@/gql/generated/graphql'
import { cn } from '@/lib/utilities'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'

interface Properties
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  id: string
  status: TaskStatus
  label?: string
  onTaskStatusChange: (status: TaskStatus) => void
  className?: string
}

export const TaskStatusCheckbox = ({
  className,
  id,
  label,
  onTaskStatusChange,
  status,
  ...rest
}: Properties) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (event.altKey) {
        onTaskStatusChange('CANCELLED')
      } else {
        if (status === 'COMPLETED') {
          onTaskStatusChange('OPEN')
        } else {
          onTaskStatusChange('COMPLETED')
        }
      }
    },
    [onTaskStatusChange, status]
  )

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <CheckboxPrimitive.Root
        id={`${id}-checkbox`}
        className={cn(
          'relative flex h-[15px] w-[15px] min-w-[15px] items-center justify-center rounded-full',
          'focus:border-spacing-1 focus:bg-sky-50 focus:outline-sky-300'
        )}
        onClick={handleClick}
        {...rest}
      >
        <CheckboxPrimitive.Indicator tabIndex={-1} />
        <TaskIcon status={status} className="size-5 min-h-5 min-w-5 shrink-0" />
      </CheckboxPrimitive.Root>
      {label && <label className="truncate">{label}</label>}
    </div>
  )
}

TaskStatusCheckbox.displayName = 'TaskStatusCheckbox'
