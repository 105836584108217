import { ClientError } from 'graphql-request'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { FetchAgentsQuery } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useUpdateAgent = () => {
  const api = useApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.updateAgent,
    onMutate: async (payload) => {
      const previousStates: Record<
        'agent' | 'agents',
        FetchAgentsQuery | undefined
      > = {
        agent: undefined,
        agents: undefined,
      }

      for (const queryKey of [['agent', payload.id], ['agents']] as const) {
        const previousState =
          queryClient.getQueryData<FetchAgentsQuery>(queryKey)

        previousStates[queryKey[0]] = previousState

        queryClient.setQueryData<FetchAgentsQuery>(
          queryKey,
          (previousState) => {
            if (!previousState) return

            const updatedAgents = previousState.agents.map((agent) => {
              if (agent.id === payload.id) {
                const payloadWithoutNilValues = omitBy(payload, isNil)

                return {
                  ...agent,
                  ...payloadWithoutNilValues,
                }
              }

              return agent
            })

            return {
              agents: updatedAgents,
            }
          }
        )
      }

      return previousStates
    },
    // Partition: Important to keep these definitions below onMutate property so the TS compiler infers context properly
    onError: (error, variables, context) => {
      queryClient.setQueryData(['agent', variables.id], context?.agent)
      queryClient.setQueryData(['agents'], context?.agents)

      logger.error(error)

      if (error instanceof ClientError) {
        toast.error(error.response.errors?.[0]?.message)
      } else {
        toast.error('Agent update failed!')
      }
    },
  })
}
