import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { GetThreadEventsQuery } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface UseDeleteThreadEventProperties {
  taskId: string
}

type ThreadEvents = GetThreadEventsQuery['tasks'][number]

export const useDeleteThreadEvent = ({
  taskId,
}: UseDeleteThreadEventProperties) => {
  const api = useApi()

  const queryClient = useQueryClient()
  const queryKey = ['messages', taskId]

  return useMutation({
    mutationFn: api.deleteThreadEvent,
    onMutate: (threadEventId) => {
      const previousState = queryClient.getQueryData<ThreadEvents>(queryKey)

      queryClient.setQueryData<ThreadEvents>(queryKey, (previousState) => {
        if (!previousState) return

        return {
          ...previousState,
          thread: {
            ...previousState.thread,
            events: previousState.thread.events.filter(
              ({ id }) => id !== threadEventId
            ),
          },
        }
      })

      return { previousState }
    },
    // Partition: Important to keep these definitions below onMutate property so the TS compiler infers context properly
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousState)
      logger.error(error)
      toast.error(`Thread deletion failed!`)
    },
  })
}
