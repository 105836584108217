import constant from 'lodash/constant'
import { FC, Suspense, lazy } from 'react'
import FocusLock from 'react-focus-lock'
import { Flip, ToastContainer } from 'react-toastify'
import { z } from 'zod'

import { Breadcrumbs } from '@/components/layout/Breadcrumbs'
import { Navigation } from '@/components/layout/navigation/Navigation'
import { Stack } from '@/components/layout/Stack'
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'

// eslint-disable-next-line unicorn/no-null -- It is a null component so...
const NullComponent: FC = constant(null)
NullComponent.displayName = 'NullComponent'

/* v8 ignore next 10 */
const TanStackRouterDevtools =
  import.meta.env.PUBLIC_TANSTACK_ROUTER_DEV_TOOLS === 'true'
    ? lazy(
        async () =>
          await import('@tanstack/react-router-devtools').then(
            ({ TanStackRouterDevtools }) => ({
              default: TanStackRouterDevtools,
            })
          )
      )
    : NullComponent

/* v8 ignore next 10 */
const ReactQueryDevtoolsProduction =
  import.meta.env.PUBLIC_TANSTACK_QUERY_DEV_TOOLS === 'true'
    ? lazy(
        async () =>
          await import('@tanstack/react-query-devtools').then(
            ({ ReactQueryDevtools }) => ({
              default: ReactQueryDevtools,
            })
          )
      )
    : NullComponent

const PrivateLayout: FC = () => (
  <>
    <div
      className="relative flex h-screen w-[100vw] flex-row overflow-hidden"
      data-testid="root-container"
    >
      <Navigation />
      <div className="flex w-full flex-1 flex-col overflow-hidden">
        <Breadcrumbs />

        <Outlet />

        <ToastContainer
          draggable
          pauseOnHover
          position="bottom-right"
          transition={Flip}
        />
      </div>

      <FocusLock returnFocus>
        <Stack />
      </FocusLock>
    </div>

    <Suspense>
      <TanStackRouterDevtools position="bottom-right" />
    </Suspense>

    <Suspense>
      <ReactQueryDevtoolsProduction />
    </Suspense>
  </>
)
PrivateLayout.displayName = 'PrivateLayout'

const stackSearchParameters = z.object({
  agents: z.optional(z.array(z.string())),
  notes: z.optional(z.array(z.string())),
  stack: z.optional(z.array(z.string())),
})

export type SearchParameters = z.infer<typeof stackSearchParameters>
export const TASK_FILTER_VALUES = [
  'all',
  'open',
  'started',
  'concluded',
  'cancelled',
] as const

export const Route = createFileRoute('/_private')({
  beforeLoad: async function privateRouteLoader({ context }) {
    const user = await context.getUser()

    if (!user) {
      const redirectUrl = encodeURIComponent(location.href)
      location.replace(`${location.origin}/signin?redirect=${redirectUrl}`)
      return
    }

    // Redirect old /all-work/:id links to /tasks/:id
    const allWorkRegex = /^\/all-work\/([a-zA-Z0-9-]+)$/
    const match = allWorkRegex.exec(location.pathname)

    if (match) {
      const taskId = match[1] ?? ''
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({ params: { taskId }, to: `/tasks/$taskId` })
    }
  },
  component: PrivateLayout,
  validateSearch: stackSearchParameters.extend({
    assignedToMe: z.optional(z.boolean()),
    filter: z.optional(z.enum(TASK_FILTER_VALUES)),
  }),
})
