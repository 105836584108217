import { useCallback } from 'react'

import { SearchParameters } from '@/routes/_private'
import { useNavigate, useSearch } from '@tanstack/react-router'

type StackSearchParameters = Exclude<keyof SearchParameters, 'filter'>

export function useHandleOpenStack(stackType: StackSearchParameters): {
  handleOpenStack: (id: string | string[]) => void
  handleAddToStack: (id: string | string[]) => void
}
export function useHandleOpenStack(
  stackType: StackSearchParameters,
  stackItemId: string | string[]
): { handleOpenStack: () => void; handleAddToStack: () => void }

export function useHandleOpenStack(
  stackType: StackSearchParameters,
  stackItemId?: string | string[]
) {
  const search = useSearch({
    from: '/_private',
  })
  const navigate = useNavigate()

  const handleOpenStack = useCallback(
    (id?: string | string[]) => {
      const finalStackItemId = stackItemId ?? id
      if (!finalStackItemId) {
        throw new Error('stackItemId is required')
      }

      void navigate({
        search: {
          ...search,
          [stackType]: Array.isArray(finalStackItemId)
            ? finalStackItemId
            : [finalStackItemId],
        },
        to: '.',
      })
    },
    [navigate, search, stackItemId, stackType]
  )

  const handleAddToStack = useCallback(
    (id: string | string[]) => {
      const finalStackItemId = stackItemId ?? id
      if (!finalStackItemId) {
        throw new Error('stackItemId is required')
      }

      const existingStack = search[stackType] ?? []

      void navigate({
        search: {
          ...search,
          [stackType]: Array.isArray(finalStackItemId)
            ? [...existingStack, ...finalStackItemId]
            : [...existingStack, finalStackItemId],
        },
        to: '.',
      })
    },
    [navigate, search, stackItemId, stackType]
  )

  return { handleAddToStack, handleOpenStack }
}
