import {
  FC,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
  ReactNode,
  RefObject,
  KeyboardEvent,
} from 'react'

import { Button } from '@/components/ui/button/Button'
import { useOnMetaEnter } from '@/lib/hooks/useOnMetaEnter'
import { cn } from '@/lib/utilities'
import {
  Action,
  Cancel,
  Content,
  Description,
  Overlay,
  Portal,
  Root,
  Title,
  Trigger,
} from '@radix-ui/react-alert-dialog'

interface Properties {
  actionText?: string
  cancelText?: string
  description?: string
  onActionClick?: () => void
  onCancelClick?: () => void
  onOpenChange?: (open: boolean) => void
  title: ReactNode
  isDialogOpen?: boolean
  beforeTitle?: ReactNode
  content?: FC<{
    Description: typeof Description
    handleSubmitKeyDown: (event: KeyboardEvent<HTMLElement>) => void
    actionButtonReference: RefObject<HTMLButtonElement | null>
  }>
  className?: string
}

export const AlertDialog: FC<PropsWithChildren<Properties>> = ({
  actionText,
  beforeTitle,
  cancelText,
  children,
  className,
  content,
  description,
  isDialogOpen,
  onActionClick,
  onCancelClick,
  onOpenChange,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const actionButtonReference = useRef<HTMLButtonElement>(null)

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open)
      onOpenChange?.(open)
    },
    [onOpenChange]
  )

  const focusActionButton = useCallback((event: Event) => {
    event.preventDefault()
    actionButtonReference.current?.focus()
  }, [])

  const handleOverlayClick = useCallback(() => {
    setIsOpen(false)
    onOpenChange?.(false)
  }, [onOpenChange])

  const handleSubmitKeyDown = useOnMetaEnter(
    useCallback(() => {
      onActionClick?.()
    }, [onActionClick])
  )

  return (
    <Root onOpenChange={handleOpenChange} open={isDialogOpen ?? isOpen}>
      <Trigger asChild>{children}</Trigger>
      <Portal>
        <Overlay
          className="fixed inset-0 z-50 bg-black/[0.07]"
          onClick={handleOverlayClick}
          data-testid="alertdialog-overlay"
        />
        <Content
          className={cn(
            'fixed top-1/2 left-1/2 z-50 w-120 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-mono-paper p-6',
            className
          )}
          onOpenAutoFocus={focusActionButton}
        >
          {beforeTitle}
          {title && (
            <Title className="text-md-bold text-mono-ink-strong">{title}</Title>
          )}
          {content ? (
            <>
              {content({
                actionButtonReference,
                Description,
                handleSubmitKeyDown,
              })}
            </>
          ) : (
            <>
              {description && (
                <Description className="pt-1.5 font-normal text-mono-ink-subtle">
                  {description}
                </Description>
              )}
              <div className="flex justify-end gap-2 pt-3">
                {cancelText && (
                  <Cancel asChild>
                    <Button onClick={onCancelClick}>{cancelText}</Button>
                  </Cancel>
                )}
                {actionText && (
                  <Action asChild ref={actionButtonReference}>
                    <Button
                      onKeyDown={handleSubmitKeyDown}
                      data-testid="alertdialog-action-button"
                      onClick={onActionClick}
                      variant="solid"
                    >
                      {actionText}
                    </Button>
                  </Action>
                )}
              </div>
            </>
          )}
        </Content>
      </Portal>
    </Root>
  )
}
AlertDialog.displayName = 'AlertDialog'
