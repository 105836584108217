import { OpenBookArchiveIcon } from '@/components/icons/OpenBookArchiveIcon'
import { Api } from '@/contexts/ApiProvider'
import { createNotesQueryOptions } from '@/features/library/hooks/useNotes'
import { createFileRoute } from '@tanstack/react-router'

interface LibraryLoaderData {
  notes: Awaited<ReturnType<Api['fetchNotes']>>
}

export const Route = createFileRoute('/_private/library')({
  loader: async ({ context }): Promise<LibraryLoaderData> => ({
    notes: await context.queryClient.ensureQueryData(
      createNotesQueryOptions(context)
    ),
  }),
})

const LibraryBreadcrumb = () => {
  return (
    <div className="flex items-center gap-2 text-xs-regular text-mono-ink-subtle">
      <OpenBookArchiveIcon className="size-4" />
      <p>Library</p>
    </div>
  )
}

LibraryBreadcrumb.displayName = 'LibraryBreadcrumb'

Route.interloom = {
  Breadcrumb: LibraryBreadcrumb,
  defaultPageTitle: 'Library',
  NavigationIcon: OpenBookArchiveIcon,
  tooltipText: 'Library',
}
