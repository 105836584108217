import { useApi } from '@/contexts/ApiProvider'
import { FetchNotesQuery } from '@/gql/generated/graphql'
import { useSuspenseQuery } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'

interface CreateQueryOptionParameters {
  api: RouteContext['api']
  id?: string
}

export const createNotesQueryOptions = ({
  api,
  id,
}: CreateQueryOptionParameters) => {
  return {
    queryFn: api.fetchNotes,
    queryKey: id ? ['note', id] : ['notes'],
  }
}

export function useNotes(): FetchNotesQuery
export function useNotes(id: string): {
  note: FetchNotesQuery['notes'][number]
}
export function useNotes(id?: string) {
  const api = useApi()

  const queryOptions = createNotesQueryOptions({ api, id })

  const { data } = useSuspenseQuery(queryOptions)

  if (id) {
    // Id filter is not supported by the server yet
    const note = { note: data.notes.find((note) => note.id === id) }
    if (!note) {
      throw new Error('Note not found!')
    }

    return note
  }

  return data
}
