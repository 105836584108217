import { VariantProps, cva } from 'class-variance-authority'
import { ComponentProps, FC } from 'react'

import { cn } from '@/lib/utilities'
import { Slot } from '@radix-ui/react-slot'

const buttonVariants = cva(
  'flex items-center justify-center gap-1.5 rounded-lg text-sm font-medium transition-shadow transition-colors transition-text select-none shadow-button hover:shadow-button-hover focus:shadow-button-hover focus:outline-hidden disabled:pointer-events-none disabled:opacity-50 cursor-pointer',
  {
    defaultVariants: {
      size: 'default',
      variant: 'subtle',
    },
    variants: {
      size: {
        default: 'h-11 min-h-11 px-3.5',
        icon: 'h-9 w-9',
        lg: 'h-10 rounded-md px-8',
        sm: 'h-8 rounded-md px-2 text-xs',
      },
      variant: {
        naked:
          'bg-none shadow-none border border-transparent focus:border-sky-500 focus:text-sky-600 active:bg-sky-100 focus:bg-sky-100',
        solid:
          'text-sm-bold text-mono-paper border border-transparent bg-button-solid bg-mono-ink-strong cover-fill hover:bg-button-solid-hover hover:text-opacity-80 active:bg-gamma-ink-gamma disabled:bg-film-strongest focus:border-indigo-950 focus:bg-indigo-900',
        solidIndigo:
          'text-sm-bold text-mono-paper bg-indigo-900 cover-fill hover:bg-indigo-950 hover:text-white active:bg-gamma-ink-gamma disabled:bg-film-strongest',
        subtle:
          'bg-film-subtle active:bg-film-stronger border focus:border-sky-500 focus:text-sky-600 active:bg-sky-100 focus:bg-sky-100 active:opacity-75 hover:shadow-sm focus:shadow-sm',
      },
    },
  }
)

export interface ButtonProperties
  extends ComponentProps<'button'>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button: FC<ButtonProperties> = ({
  asChild = false,
  className,
  ref,
  size,
  type = 'button',
  variant,
  ...properties
}) => {
  const Comp = asChild ? Slot : 'button'
  return (
    <Comp
      className={cn(buttonVariants({ className, size, variant }))}
      ref={ref}
      type={type}
      {...properties}
    />
  )
}

Button.displayName = 'Button'

export { Button, buttonVariants }
