import { makeErrorFromUnknown } from '@/lib/utilities'
import { newrelic } from '@/newrelic'

export const logger = {
  error: (error: unknown) => {
    // eslint-disable-next-line no-console -- we will log to console here
    console.error(error)
    newrelic?.noticeError(makeErrorFromUnknown(error))
  },
}
