import { useApi } from '@/contexts/ApiProvider'
import { useTaskFilter } from '@/lib/hooks/useTaskFilters'
import { useSuspenseQuery } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'

interface CreateQueryOptionParameters {
  api: RouteContext['api']
  spaceId?: string
}

export const createAllWorkQueryOptions = ({
  api,
  spaceId,
}: CreateQueryOptionParameters) => {
  return {
    queryFn: async () => await api.fetchAllWork(spaceId),
    queryKey: spaceId ? ['tasks', spaceId] : ['tasks'],
  }
}

export const useAllWork = (spaceId?: string) => {
  const api = useApi()
  const { filterTasks } = useTaskFilter()

  const queryOptions = createAllWorkQueryOptions({ api, spaceId })
  const { data } = useSuspenseQuery(queryOptions)

  return { tasks: filterTasks(data) }
}
