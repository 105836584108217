import { useCallback } from 'react'

import { TrashIcon } from '@/components/icons/TrashIcon'
import { AlertDialog } from '@/components/ui/alertDialog/AlertDialog'
import { Button } from '@/components/ui/button/Button'
import { useDeleteNote } from '@/features/library/hooks/useDeleteNote'
import { useBoolean } from '@/lib/hooks/useBoolean'
import { useCloseStackItem } from '@/lib/hooks/useCloseStackView'

interface DeleteDialogProperties {
  id: string
}

export const DeleteNoteDialog = ({ id }: DeleteDialogProperties) => {
  const deleteNotesControl = useBoolean(false)

  const { mutate: deleteNoteMutation } = useDeleteNote()
  const { closeStackItem } = useCloseStackItem()

  const handleDeleteNote = useCallback(() => {
    deleteNoteMutation(id)
    closeStackItem(id, 'note')
  }, [closeStackItem, deleteNoteMutation, id])

  return (
    <>
      <Button
        variant="subtle"
        className="rounded-full"
        size="sm"
        onClick={deleteNotesControl.setTrue}
        data-testid="delete-button"
      >
        <TrashIcon className="size-4" />
        <span className="sr-only pr-1">Delete Note</span>
      </Button>

      <AlertDialog
        actionText="Delete Permanently"
        cancelText="Cancel"
        description="Citations and messages using the contents of notes will not be deleted. Deleting a note can not be undone."
        onOpenChange={deleteNotesControl.toggle}
        onActionClick={handleDeleteNote}
        title={'Delete Note'}
        isDialogOpen={deleteNotesControl.value}
      />
    </>
  )
}
DeleteNoteDialog.displayName = 'DeleteNoteDialog'
