import * as React from 'react'
import {
  FormProvider,
  useForm,
  FieldErrors,
  DefaultValues,
} from 'react-hook-form'

import { useClickOutside } from '@/lib/hooks/useClickOutside'
import { useOnKeyDown } from '@/lib/hooks/useOnKeyDown'
import { useOnMetaEnter } from '@/lib/hooks/useOnMetaEnter'
import { mergeRefs } from '@/lib/utilities'

export type FormProperties<TFormValues extends Record<string, any>> = Omit<
  React.HTMLAttributes<HTMLFormElement>,
  'onSubmit'
> & {
  children: React.ReactNode
  className?: string
  defaultValues?: DefaultValues<TFormValues>
  onClickAway?: (data?: TFormValues) => void
  onCmdEnterKey?: (data: TFormValues) => void
  onEscapeKey?: (data?: TFormValues) => void
  onError?: (errors: FieldErrors<TFormValues>) => void
  onSubmit: (data: TFormValues) => void
  onChange?: (data: TFormValues) => void
}

const FormComponent = <TFormValues extends Record<string, any>>(
  {
    children,
    defaultValues,
    onBlur,
    onChange,
    onClickAway,
    onCmdEnterKey,
    onError,
    onEscapeKey,
    onSubmit,
    ...properties
  }: FormProperties<TFormValues>,
  reference: React.ForwardedRef<HTMLFormElement>
) => {
  const formMethods = useForm<TFormValues>({ defaultValues })
  const localReference = React.useRef<HTMLFormElement>(null)

  const { getValues, handleSubmit } = formMethods

  const clickAwayReference = useClickOutside<HTMLFormElement>(() =>
    onClickAway?.(getValues())
  )
  const handleMaybeEscapeKeyDown = useOnKeyDown<HTMLFormElement>(
    'Escape',
    React.useCallback(() => {
      onEscapeKey?.(getValues())
    }, [onEscapeKey, getValues])
  )
  const handleMaybeMetaEnterKeyDown = useOnMetaEnter<HTMLFormElement>(
    React.useCallback(() => {
      onCmdEnterKey?.(getValues())
    }, [onCmdEnterKey, getValues])
  )

  const handleBlur = (event: React.FocusEvent<HTMLFormElement>) => {
    if (
      localReference.current &&
      event.relatedTarget &&
      !localReference.current.contains(event.relatedTarget)
    ) {
      onBlur?.(event)
    }
  }

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLFormElement>) => {
      handleMaybeMetaEnterKeyDown(event)
      handleMaybeEscapeKeyDown(event)
    },
    [handleMaybeEscapeKeyDown, handleMaybeMetaEnterKeyDown]
  )

  return (
    <FormProvider {...formMethods}>
      <form
        name="form"
        onSubmit={(event) => {
          void handleSubmit(onSubmit, onError)(event)
        }}
        ref={mergeRefs(reference, clickAwayReference, localReference)}
        {...properties}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      >
        {children}
      </form>
    </FormProvider>
  )
}

FormComponent.displayName = 'Form'

export const Form = React.forwardRef<HTMLFormElement, FormProperties<any>>(
  FormComponent
) as <TFormValues extends Record<string, any>>(
  properties: FormProperties<TFormValues> & {
    ref?: React.ForwardedRef<HTMLFormElement>
  }
) => React.ReactElement
