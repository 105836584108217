import noop from 'lodash/noop'
import { useCallback, useMemo, useState } from 'react'
import invariant from 'tiny-invariant'
import { v7 as uuidv7 } from 'uuid'

import { FileMetadata } from '@/gql/generated/graphql'
import { useXhrUpload } from '@/lib/hooks/useXhrUpload'

export interface UploadState {
  key: string
  loading: boolean
  file: File
  data?: FileMetadata
  error?: Error
  progress: number
  abort?: () => void
  done: boolean
}

interface Properties {
  onBeforeUpload?: (uploadItems: UploadState[]) => void
}
export const useFileUpload = ({ onBeforeUpload = noop }: Properties = {}) => {
  const [hashMap, setHashMap] = useState<Record<string, UploadState>>({})
  const state = useMemo(() => Object.values(hashMap), [hashMap])

  const xhrUpload = useXhrUpload()

  const onFileUploadProgress = useCallback(
    (progress: number, uploadItem: { key: string }) => {
      setHashMap((previous) => {
        const previousUploadItemState = previous[uploadItem.key]
        invariant(
          previousUploadItemState,
          `Upload item with key "${uploadItem.key}" in onFileUploadProgress not found!`
        )
        return {
          ...previous,
          [uploadItem.key]: {
            ...previousUploadItemState,
            loading: progress !== 100,
            progress,
          },
        }
      })
    },
    []
  )

  const upload = useCallback(
    (files: File[]) => {
      const uploadItems = files.map((file) => ({
        done: false,
        file,
        key: uuidv7(),
        loading: false,
        progress: 0,
      }))
      onBeforeUpload(uploadItems)

      for (const uploadItem of uploadItems) {
        setHashMap((previous) => {
          return {
            ...previous,
            [uploadItem.key]: {
              ...uploadItem,
              abort: abortController,
            },
          }
        })

        const abortController = xhrUpload({
          onError: (error) => {
            setHashMap((previous) => {
              const previousUploadItemState = previous[uploadItem.key]
              invariant(
                previousUploadItemState,
                `Upload item with key "${uploadItem.key}" in onError not found!`
              )
              return {
                ...previous,
                [uploadItem.key]: {
                  ...previousUploadItemState,
                  done: true,
                  error,
                  loading: false,
                  progress: 0,
                },
              }
            })
          },
          onProgress: onFileUploadProgress,
          onSuccess: (fileMetadata) => {
            setHashMap((previous) => {
              const previousUploadItemState = previous[uploadItem.key]
              invariant(
                previousUploadItemState,
                `Upload item with key "${uploadItem.key}" in onSuccess not found!`
              )
              return {
                ...previous,
                [uploadItem.key]: {
                  ...previousUploadItemState,
                  abortController: undefined,
                  data: fileMetadata,
                  done: true,
                  error: undefined,
                  loading: false,
                  progress: 100,
                },
              }
            })
          },
          uploadItem,
        })
      }
    },
    [onBeforeUpload, onFileUploadProgress, xhrUpload]
  )

  const getById = useCallback((id: string) => hashMap[id], [hashMap])

  const removeById = useCallback((id: string) => {
    setHashMap((previous) => {
      const { [id]: _, ...rest } = previous
      return rest
    })
  }, [])

  const clearState = useCallback(() => {
    setHashMap({})
  }, [])

  return { clearState, getById, removeById, state, upload }
}
