import set from 'lodash/fp/set'
import Lottie, { LottieComponentProps } from 'lottie-react'
import { FC, useMemo } from 'react'

import animationData from '@/features/task/components/helix-loader/animationData.json'
import { cn } from '@/lib/utilities'

const animationDataWithColor = (color: [number, number, number, number]) =>
  set('assets[2].layers[0].shapes[0].it[1].c.k', color, animationData)

export const HelixLoader: FC<
  Omit<LottieComponentProps, 'animationData'> & {
    theme?: 'black' | 'default'
  }
> = ({ className, theme = 'default', ...properties }) => {
  const themedAnimationData = useMemo(
    () =>
      theme === 'black' ? animationDataWithColor([0, 0, 0, 1]) : animationData,
    [theme]
  )

  return (
    <Lottie
      animationData={themedAnimationData}
      className={cn('h-32 w-32', className)}
      loop
      {...properties}
    />
  )
}

HelixLoader.displayName = 'HelixLoader'
