import { FC } from 'react'

import { Avatar } from '@/components/ui/avatar/Avatar'
import { Tooltip } from '@/components/ui/tooltip/Tooltip'
import { ShowAgentThinkingButton } from '@/features/task/components/chat/chat-message-payloads/AgentInvocationMessagePayload'
import { TaskUpdateMessagePayloadForUser } from '@/features/task/components/chat/chat-message-payloads/TaskUpdateMessagePayload'
import { MessageWithPayload } from '@/features/task/components/chat/chat-view/MessageWithPayload'
import { processPayloads } from '@/features/task/components/chat/chat-view/processPayloads'
import { useChatContext } from '@/features/task/components/chat/ChatProvider'
import { TaskThreadEvent } from '@/features/task/components/chat/threadPayloadTypes'
import { UnassignedIcon } from '@/features/task/components/UnassignedIcon'
import { useUsers } from '@/features/task/hooks/useUsers'
import { useBoolean } from '@/lib/hooks/useBoolean'
import { formatMessageTimestamp } from '@/lib/utilities'
import * as Collapsible from '@radix-ui/react-collapsible'

interface ThreadEventProperties {
  event: TaskThreadEvent
  index: number
  threadEvents: TaskThreadEvent[]
}
// eslint-disable-next-line complexity -- we would have to do too much prop drilling to avoid this
const ThreadEvent: FC<ThreadEventProperties> = ({
  event,
  index,
  threadEvents,
}) => {
  const { users } = useUsers()
  const { toggle: toggleAgentInvocation, value: isAgentInvocationOpen } =
    useBoolean()

  const { newMessageCallback, previousMessageCallback } = useChatContext()

  const isNewMessage = threadEvents.length === index + 1
  const isPreviousMessage = threadEvents.length - 2 === index
  const containerReference = isNewMessage
    ? newMessageCallback
    : isPreviousMessage
      ? previousMessageCallback
      : undefined

  const localizedDate = new Date(event.createdAt).toLocaleString()
  const payload = processPayloads(event.payload)
  const { agentInvocation, message, taskUpdate } = payload
  const author = agentInvocation?.agent ?? message?.author ?? taskUpdate?.actor

  return (
    <Collapsible.Root asChild>
      <div
        key={event.id}
        data-testid={`message-${event.id}`}
        ref={containerReference}
      >
        <div className="flex items-center gap-4">
          <Avatar
            alt={author?.name}
            boringAvatarSeed={author?.id}
            src={author?.avatarUrl}
            className="size-6 min-h-6 min-w-6 rounded-full"
            FallbackAvatarIcon={author?.id ? undefined : UnassignedIcon}
          />

          <div className="flex items-center gap-2 truncate">
            <p className="text-md-bold text-mono-ink-strong">
              {author?.name ?? 'Unknown User'}
            </p>

            {taskUpdate && (
              <TaskUpdateMessagePayloadForUser taskUpdate={taskUpdate} />
            )}

            <Tooltip
              content={localizedDate}
              side="top"
              data-testid={`message-timestamp-tooltip-${message?.id}`}
            >
              <span
                className="text-xs-regular text-film-strongest"
                data-testid={`message-timestamp-${message?.id}`}
              >
                {formatMessageTimestamp(event.createdAt)}
              </span>
            </Tooltip>

            {agentInvocation?.completedAt &&
              agentInvocation.steps.length > 0 && (
                <ShowAgentThinkingButton
                  isOpen={isAgentInvocationOpen}
                  toggle={toggleAgentInvocation}
                />
              )}
          </div>
        </div>
        <MessageWithPayload event={event} payload={payload} users={users} />
      </div>
    </Collapsible.Root>
  )
}

ThreadEvent.displayName = 'ThreadEvent'

export const ChatViewEventsThread = () => {
  const { threadEvents } = useChatContext()

  return threadEvents.map((event, index) => (
    <ThreadEvent
      key={event.id}
      event={event}
      index={index}
      threadEvents={threadEvents}
    />
  ))
}

ChatViewEventsThread.displayName = 'ChatViewEventsThread'
