import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, FC, useMemo } from 'react'
import usePrevious from 'react-use/lib/usePrevious'

import { CrossMediumIcon } from '@/components/icons/CrossMediumIcon'
import { ExpandArrowsIcon } from '@/components/icons/ExpandArrowsIcon'
import { Button } from '@/components/ui/button/Button'
import { DeleteNoteDialog } from '@/features/library/components/DeleteNoteDialog'
import { NotesStackCardBody } from '@/features/library/components/NotesStackCardBody'
import { TaskStackCardBody } from '@/features/task/components/TaskStackCardBody'
import { AgentsStackCardBody } from '@/features/team/components/AgentsStackCardBody'
import { useCloseStackItem } from '@/lib/hooks/useCloseStackView'
import { useDocumentKeyCapture } from '@/lib/hooks/useDocumentKeyCapture'
import { SearchParameters } from '@/routes/_private'
import { useNavigate, useSearch, NavigateOptions } from '@tanstack/react-router'

export type StackType = 'task' | 'note' | 'agent'
export type StackSearchParameters = Exclude<keyof SearchParameters, 'filter'>

const isStackType = (type: string): type is StackType => {
  return ['task', 'note', 'agent'].includes(type)
}

export const stackSearchParameters: Record<StackType, StackSearchParameters> = {
  agent: 'agents',
  note: 'notes',
  task: 'stack',
}

export interface StackCardBodyProperties {
  id: string
}

const stacks: Record<
  StackType,
  {
    component: FC<StackCardBodyProperties>
    expandNavigationParameters?: (stackItemId: string) => NavigateOptions
    deleteDialog?: FC<StackCardBodyProperties>
  }
> = {
  agent: {
    component: AgentsStackCardBody,
  },
  note: {
    component: NotesStackCardBody,
    deleteDialog: DeleteNoteDialog,
  },
  task: {
    component: TaskStackCardBody,
    expandNavigationParameters: (stackItemId) => ({
      params: { taskId: stackItemId },
      to: '/tasks/$taskId',
    }),
  },
}

export interface StackCardProperties {
  stackItemId: string
  index: number
  stackLength: number
  haveLengthChanged: boolean
  closeTask: (taskId: string) => void
  stackType: StackType
  'data-testid'?: string
}

const StackCard: FC<StackCardProperties> = ({
  closeTask,
  haveLengthChanged,
  index,
  stackItemId,
  stackLength,
  stackType,
  ...rest
}) => {
  const {
    [stackType]: {
      component: StackComponent,
      deleteDialog: DeleteDialog,
      expandNavigationParameters,
    },
  } = stacks

  const navigate = useNavigate()
  const handleCloseTask = useCallback(() => {
    closeTask(stackItemId)
  }, [closeTask, stackItemId])

  const handleExpandTask = useCallback(() => {
    if (expandNavigationParameters) {
      void navigate(expandNavigationParameters(stackItemId))
    }
  }, [expandNavigationParameters, navigate, stackItemId])

  return (
    <motion.div
      initial={{
        right: haveLengthChanged
          ? index === 0 && stackLength > 1
            ? '20%'
            : '-100%'
          : 0,
      }}
      animate={{
        right:
          stackLength - 1 === index
            ? 0
            : stackLength - 2 === index
              ? '19.5%'
              : '20%',
      }}
      exit={{ right: '-100%' }}
      transition={{ bounce: 0, duration: 0.5, type: 'spring' }}
      key={stackItemId}
      className="fixed top-0 z-40 flex h-full w-168 flex-col pl-0"
      data-testid={rest['data-testid'] ?? `stack-card`}
    >
      <div className="@container/task flex w-full flex-1 flex-col overflow-hidden bg-mono-paper-darker pr-0.5 shadow-overlay-lg">
        <div className="flex justify-between p-2.5">
          <Button
            variant="subtle"
            className="rounded-full"
            size="sm"
            onClick={handleCloseTask}
            data-testid="close-task-button"
          >
            <CrossMediumIcon className="size-4" />
            <span className="pr-1">Close</span>
          </Button>
          {expandNavigationParameters && (
            <Button
              variant="subtle"
              className="rounded-full"
              size="sm"
              onClick={handleExpandTask}
              data-testid="expand-task-button"
            >
              <ExpandArrowsIcon className="size-4" />
              <span className="pr-1">Expand</span>
            </Button>
          )}

          {!!DeleteDialog && <DeleteDialog id={stackItemId} />}
        </div>
        <StackComponent id={stackItemId} />
      </div>
    </motion.div>
  )
}

StackCard.displayName = 'StackCard'

export const Stack = () => {
  const searchParameters = useSearch({
    from: '/_private',
  })

  const { currentStack, stackType } = useMemo(() => {
    for (const [stackType, searchParameter] of Object.entries(
      stackSearchParameters
    )) {
      if (searchParameters[searchParameter]) {
        const currentStack = searchParameters[searchParameter] ?? []
        return {
          currentStack,
          stackType: isStackType(stackType) ? stackType : undefined,
        }
      }
    }

    return { currentStack: [], stackType: undefined }
  }, [searchParameters])

  const previousStack = usePrevious(currentStack)

  const { closeStackItem } = useCloseStackItem()
  const closeItem = useCallback(
    (idToClose: string) => {
      if (!stackType) return
      closeStackItem(idToClose, stackType)
    },
    [closeStackItem, stackType]
  )

  const haveLengthChanged = currentStack.length !== previousStack?.length
  const stacksInView = currentStack.slice(-3)
  const topLevelTaskId = currentStack.at(-1)

  useDocumentKeyCapture(
    'Escape',
    useCallback(
      ({ event }) => {
        const radixPopperContentWrapper = document.querySelectorAll(
          '[data-radix-popper-content-wrapper]'
        )
        const hasOpenPopper = radixPopperContentWrapper.length > 0
        const isDialogOpen =
          document.querySelectorAll('[role="alertdialog"]').length > 0

        if (
          event.target instanceof HTMLInputElement ||
          event.target instanceof HTMLTextAreaElement ||
          (event.target instanceof HTMLDivElement &&
            event.target.getAttribute('contenteditable') === 'true') ||
          hasOpenPopper ||
          isDialogOpen
        ) {
          return
        }
        if (topLevelTaskId) {
          closeItem(topLevelTaskId)
        }
      },
      [closeItem, topLevelTaskId]
    ),
    true,
    'capture'
  )

  return (
    <AnimatePresence>
      {stackType &&
        stacksInView.map((itemId, index) => {
          return (
            <StackCard
              key={itemId}
              stackItemId={itemId}
              index={index}
              haveLengthChanged={haveLengthChanged}
              stackLength={stacksInView.length}
              closeTask={closeItem}
              stackType={stackType}
              data-testid={`stack-card-${itemId}`}
            />
          )
        })}
    </AnimatePresence>
  )
}

Stack.displayName = 'Stack'
