import { FC } from 'react'

import {
  Outlet,
  createRootRouteWithContext,
  RouteContext,
} from '@tanstack/react-router'

const RootLayout: FC = () => {
  return <Outlet />
}
RootLayout.displayName = 'RootLayout'

export const Route = createRootRouteWithContext<RouteContext>()({
  component: RootLayout,
})
