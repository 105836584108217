import { FC, useCallback, useMemo } from 'react'

import { InterloomLogo } from '@/components/icons/InterloomLogo'
import { Button } from '@/components/ui/button/Button'
import { LoadingSpinner } from '@/components/ui/loading-spinner/LoadingSpinner'
import { useOIDCAuth } from '@/contexts/OidcAuthContext'
import { cn } from '@/lib/utilities'
import {
  createFileRoute,
  Navigate,
  useRouteContext,
} from '@tanstack/react-router'

const Signin: FC = () => {
  const { loading, signIn, user } = useOIDCAuth()
  const {
    configurationVariables: {
      env: { PUBLIC_SIGN_IN_BACKGROUND_IMAGE_URL },
    },
  } = useRouteContext({
    from: '/_auth/signin',
  })

  const syncSignIn = useCallback(() => {
    void signIn()
  }, [signIn])

  const signInBackgroundImage = useMemo(() => {
    if (PUBLIC_SIGN_IN_BACKGROUND_IMAGE_URL) {
      return PUBLIC_SIGN_IN_BACKGROUND_IMAGE_URL
    }

    return '/default-sign-in-background.webp'
  }, [PUBLIC_SIGN_IN_BACKGROUND_IMAGE_URL])

  if (user) {
    return <Navigate to="/" />
  }

  return (
    <div
      data-testid="sign-in-page"
      className="bg-mono-paper-dark flex h-screen items-stretch gap-2.5 p-2.5"
    >
      <div className="hidden flex-6 items-center justify-center text-mono-paper sm:flex">
        <div className="relative size-full overflow-hidden">
          <img
            alt="Sign In Background Image"
            className="size-full rounded-lg object-cover"
            src={signInBackgroundImage}
          />
        </div>
      </div>
      <div className="flex flex-4 items-center justify-center">
        <div className="w-full max-w-[320px] px-4 text-center">
          <h1 className="text-2xl font-semibold text-mono-ink-strong">
            Welcome
          </h1>
          <p className="mt-2 text-xs leading-4 font-medium text-mono-ink-subtle">
            Press the button below to begin
          </p>
          <Button
            data-testid="sign-in-button"
            variant="solid"
            disabled={loading}
            className="mt-7 w-full"
            onClick={syncSignIn}
          >
            <span className="relative block">
              <InterloomLogo className={cn('size-6', loading && 'opacity-5')} />
              <LoadingSpinner
                className={cn(
                  'absolute top-0 size-6 opacity-0 ease-out',
                  loading && 'opacity-1'
                )}
              />
            </span>
            Sign in
          </Button>
          <p className="mt-6 text-xs leading-4 font-medium text-mono-ink-subtle">
            By clicking continue, you agree to our{' '}
            <a
              href="https://interloom.com/en"
              className="text-sm font-normal underline"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://interloom.com/en"
              className="text-sm font-normal underline"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
Signin.displayName = 'Signin'

export const Route = createFileRoute('/_auth/signin')({
  component: Signin,
})
