import { FC, Suspense, useCallback, useMemo } from 'react'

import { SavableTextArea } from '@/components/ui/savable-text-area/SavableTextArea'
import { Skeleton } from '@/components/ui/skeleton/Skeleton'
import { useNotes } from '@/features/library/hooks/useNotes'
import { useUpdateNote } from '@/features/library/hooks/useUpdateNote'
import { cn } from '@/lib/utilities'

interface NoteBodyProperties {
  id: string
  className?: string
}

const NoteBody: FC<NoteBodyProperties> = ({ className, id }) => {
  const { note } = useNotes(id)
  const { mutate: mutateNote } = useUpdateNote()

  const handleBodyUpdate = useCallback(
    (body?: string) => {
      mutateNote({ body, id })
    },
    [id, mutateNote]
  )

  return (
    <SavableTextArea
      onSave={handleBodyUpdate}
      text={note.body ?? undefined}
      placeholder="Enter note..."
      className={className}
      minRows={20}
      disableEmptySave
    />
  )
}

NoteBody.displayName = 'NoteBody'

const SuspendedNoteBody: FC<NoteBodyProperties> = (properties) => {
  return (
    <Suspense
      fallback={useMemo(
        () => (
          <Skeleton
            className={cn('h-150 bg-film-subtle', properties.className)}
          />
        ),
        [properties.className]
      )}
    >
      <NoteBody {...properties} />
    </Suspense>
  )
}

SuspendedNoteBody.displayName = 'SuspendedNoteBody'

export { SuspendedNoteBody as NoteBody }
