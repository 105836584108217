import BoringAvatar from 'boring-avatars'
import { FC, ReactNode, useMemo } from 'react'

import { Tooltip } from '@/components/ui/tooltip/Tooltip'
import { IconComponent } from '@/lib/types'
import { cn } from '@/lib/utilities'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

const boringAvatarColors = [
  '#752830',
  '#f85f3a',
  '#479596',
  '#a65966',
  '#ffaf43',
]

interface Properties extends Omit<AvatarPrimitive.AvatarImageProps, 'src'> {
  tooltip?: ReactNode
  src?: string | null
  testId?: string
  // Usually the objectId is the user's id or agent's id. Used for generating the boring avatar.
  boringAvatarSeed?: string
  FallbackAvatarIcon?: IconComponent
}

const FallBackAvatar: FC<{
  className?: string
  boringAvatarSeed?: string
  FallbackAvatarIcon?: IconComponent
}> = ({ boringAvatarSeed, className, FallbackAvatarIcon }) => {
  if (FallbackAvatarIcon) {
    return <FallbackAvatarIcon className={className} />
  }

  return (
    <BoringAvatar
      size={112}
      className={className}
      name={boringAvatarSeed}
      colors={boringAvatarColors}
    />
  )
}
FallBackAvatar.displayName = 'FallBackAvatar'

export const Avatar: FC<Properties> = ({
  boringAvatarSeed,
  className,
  FallbackAvatarIcon,
  src,
  testId,
  tooltip,
  ...properties
}) => {
  const avatarComponent = useMemo(
    () => (
      <AvatarPrimitive.Root
        data-testid={testId ?? 'avatar-image'}
        className="flex items-center"
      >
        <AvatarPrimitive.Image
          src={src ?? undefined}
          className={cn('object-cover', className)}
          {...properties}
        />
        <AvatarPrimitive.Fallback>
          <FallBackAvatar
            FallbackAvatarIcon={FallbackAvatarIcon}
            boringAvatarSeed={boringAvatarSeed}
            className={className}
          />
        </AvatarPrimitive.Fallback>
      </AvatarPrimitive.Root>
    ),
    [testId, src, className, properties, FallbackAvatarIcon, boringAvatarSeed]
  )

  if (tooltip) {
    return <Tooltip content={tooltip}>{avatarComponent}</Tooltip>
  }

  return avatarComponent
}

Avatar.displayName = 'Avatar'
