import { IconComponent } from '@/lib/types'

export const SettingsCogIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...properties}
    >
      <path
        fill="currentColor"
        d="M12 2c-.79 0-1.55.099-2.273.268a.75.75 0 0 0-.576.651l-.159 1.45a1.503 1.503 0 0 1-2.096 1.21l-1.333-.587a.75.75 0 0 0-.85.172A10 10 0 0 0 2.43 9.102a.75.75 0 0 0 .273.82l1.182.867c.388.284.615.732.615 1.211s-.227.927-.615 1.21l-1.182.865a.75.75 0 0 0-.273.823 10 10 0 0 0 2.284 3.938.75.75 0 0 0 .849.172l1.333-.586a1.503 1.503 0 0 1 2.096 1.208l.159 1.45a.75.75 0 0 0 .573.652C10.45 21.9 11.211 22 12 22c.79 0 1.55-.099 2.273-.268a.75.75 0 0 0 .576-.651l.159-1.45a1.503 1.503 0 0 1 2.096-1.21l1.334.587a.75.75 0 0 0 .849-.172 10 10 0 0 0 2.283-3.938.75.75 0 0 0-.273-.822l-1.185-.865a1.5 1.5 0 0 1 0-2.422l1.185-.865a.75.75 0 0 0 .273-.822 10 10 0 0 0-2.283-3.938.75.75 0 0 0-.85-.172l-1.333.586a1.497 1.497 0 0 1-2.096-1.208l-.159-1.453a.74.74 0 0 0-.573-.646A10 10 0 0 0 12 2m0 1.5c.487 0 .956.089 1.425.17l.093.861A3.01 3.01 0 0 0 15 6.805a3.01 3.01 0 0 0 2.708.148l.792-.349a8.5 8.5 0 0 1 1.43 2.461l-.703.516a3 3 0 0 0 0 4.838l.703.516a8.5 8.5 0 0 1-1.43 2.46l-.792-.348a3.01 3.01 0 0 0-2.708.148 3.01 3.01 0 0 0-1.482 2.274l-.094.862c-.468.08-.937.169-1.424.169s-.956-.088-1.424-.17l-.094-.861A3.01 3.01 0 0 0 9 17.195a3.01 3.01 0 0 0-2.708-.148l-.792.349a8.5 8.5 0 0 1-1.43-2.461l.703-.516a3 3 0 0 0 0-4.838l-.703-.516A8.5 8.5 0 0 1 5.5 6.602l.792.349c.875.385 1.88.33 2.708-.146a3.01 3.01 0 0 0 1.482-2.274l.094-.862c.468-.08.937-.169 1.424-.169M12 8c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4m0 1.5c1.39 0 2.5 1.11 2.5 2.5s-1.11 2.5-2.5 2.5-2.5-1.11-2.5-2.5 1.11-2.5 2.5-2.5"
      />
    </svg>
  )
}

SettingsCogIcon.displayName = 'SettingsCogIcon'
