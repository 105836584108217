import { useMemo } from 'react'

import { ExpandArrowIcon } from '@/components/icons/ExpandArrowIcon'
import { Button } from '@/components/ui/button/Button'
import { LoadingIndicator } from '@/components/ui/loading-indicator/LoadingIndicator'
import { TaskThreadEventPayloadAgentInvocation } from '@/features/task/components/chat/threadPayloadTypes'
import { cn } from '@/lib/utilities'
import * as ReactCollapsible from '@radix-ui/react-collapsible'

const JsonView = ({ jsonText, title }: { title: string; jsonText: string }) => {
  const prettifiedJson = useMemo(() => {
    try {
      return JSON.stringify(JSON.parse(jsonText), undefined, 2)
    } catch {
      return jsonText
    }
  }, [jsonText])

  return (
    <>
      <p className="mt-2.5 py-2.5 text-sm font-medium text-ink-subtle">
        {title}
      </p>
      <div className="rounded-md border border-film-subtle bg-film-subtle p-2">
        <pre className="text-sm">{prettifiedJson}</pre>
      </div>
    </>
  )
}

JsonView.displayName = 'JsonView'

interface AgentInvocationMessagePayloadProperties {
  invocation: TaskThreadEventPayloadAgentInvocation
}

export function AgentInvocationMessagePayload({
  invocation,
}: AgentInvocationMessagePayloadProperties) {
  if (!invocation.completedAt) {
    return (
      <LoadingIndicator
        data-testid="agent-invocation-loading"
        className="size-10"
      />
    )
  }

  return (
    <ReactCollapsible.Content className="relative my-2.5 ml-4 flex flex-col gap-2 pl-6">
      {invocation.steps.map((step) => (
        <div
          key={step.id}
          className="relative rounded-2xl border border-film-faint bg-film-subtle p-2.5"
        >
          <div className="absolute top-5.75 -left-6.5 size-2 rounded-full bg-film-stronger" />
          <div className="absolute top-10.25 -bottom-6 -left-6 border-l-4 border-film-stronger" />
          <p className="border-b border-film-normal pt-1 pb-2.5 font-medium">
            {step.tool.name}
          </p>

          <JsonView title="REQUEST" jsonText={step.input} />
          {step.output && <JsonView title="RESULT" jsonText={step.output} />}
        </div>
      ))}

      <div className="absolute bottom-2 -left-0.25 size-2 rounded-full bg-film-stronger" />
      <p className="pt-4 font-medium">Concluded</p>
    </ReactCollapsible.Content>
  )
}

AgentInvocationMessagePayload.displayName = 'AgentInvocationMessagePayload'

export function ShowAgentThinkingButton({
  isOpen,
  toggle,
}: {
  isOpen: boolean
  toggle: () => void
}) {
  return (
    <ReactCollapsible.Trigger asChild onClick={toggle}>
      <Button
        variant="naked"
        className="h-auto min-h-0 gap-0 px-2 text-xs font-medium text-ink-subtle"
        data-testid="expand-invocation"
      >
        {isOpen ? 'Hide' : 'Show'} thinking
        <ExpandArrowIcon className={cn(isOpen && 'rotate-180')} />
      </Button>
    </ReactCollapsible.Trigger>
  )
}

ShowAgentThinkingButton.displayName = 'ShowThinkingButton'
