import { FC } from 'react'

import { ChatContextMenu } from '@/features/task/components/chat/chat-context-menu/ChatContextMenu'
import { AgentInvocationMessagePayload } from '@/features/task/components/chat/chat-message-payloads/AgentInvocationMessagePayload'
import { AudioMessagePayload } from '@/features/task/components/chat/chat-message-payloads/AudioMessagePayload'
import { ChatMessagePayload } from '@/features/task/components/chat/chat-message-payloads/ChatMessagePayload'
import { ImageMessagePayload } from '@/features/task/components/chat/chat-message-payloads/ImageMessagePayload'
import { OtherFilesMessagePayload } from '@/features/task/components/chat/chat-message-payloads/OtherFilesMessagePayload'
import { TaskUpdateMessagePayload } from '@/features/task/components/chat/chat-message-payloads/TaskUpdateMessagePayload'
import { VideoMessagePayload } from '@/features/task/components/chat/chat-message-payloads/VideoMessagePayload'
import { processPayloads } from '@/features/task/components/chat/chat-view/processPayloads'
import { TaskThreadEvent } from '@/features/task/components/chat/threadPayloadTypes'
import { FetchAllUsersQuery } from '@/gql/generated/graphql'
import { cn } from '@/lib/utilities'

interface MessageWithPayloadProperties {
  event: TaskThreadEvent
  payload: ReturnType<typeof processPayloads>
  users: FetchAllUsersQuery['users']
}

export const MessageWithPayload: FC<MessageWithPayloadProperties> = ({
  event,
  payload,
  users,
}) => {
  const {
    agentInvocation,
    audioFileMetadata,
    imgFileMetadata,
    message,
    otherFileMetadata,
    taskUpdate,
    videoFileMetadata,
  } = payload

  return (
    <ChatContextMenu disabled={!!taskUpdate} threadEvent={event}>
      <div
        className={cn(
          'mt-1 ml-10',
          {
            'w-fit rounded-lg border border-film-faint bg-film-subtle p-2':
              !taskUpdate,
          },
          {
            'hover:bg-film-normal': !taskUpdate,
          }
        )}
      >
        {imgFileMetadata.length > 0 && (
          <ImageMessagePayload imageFiles={imgFileMetadata} />
        )}

        {videoFileMetadata.length > 0 && (
          <VideoMessagePayload videoFiles={videoFileMetadata} />
        )}

        {audioFileMetadata.length > 0 && (
          <AudioMessagePayload audioFiles={audioFileMetadata} />
        )}

        {otherFileMetadata.length > 0 && (
          <OtherFilesMessagePayload files={otherFileMetadata} />
        )}

        {agentInvocation && (
          <AgentInvocationMessagePayload invocation={agentInvocation} />
        )}
        {!!message && message.body.text && (
          <ChatMessagePayload message={message.body.text} users={users} />
        )}

        {!!taskUpdate && <TaskUpdateMessagePayload taskUpdate={taskUpdate} />}
      </div>
    </ChatContextMenu>
  )
}

MessageWithPayload.displayName = 'MessageWithPayload'
