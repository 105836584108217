import React from 'react'

import {
  ContextMenuPortal,
  ContextMenuRoot,
  ContextMenuTrigger,
} from '@/components/ui/context-menu/ContextMenu'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export interface ListItemProperties<T extends { id: string }> {
  contextMenuOpen: boolean
  deleteDialogOpen: boolean
  index: number
  item: T
  sortable: boolean
  selectedItemIds: string[]
  getItemProperties: (id: string) => {
    isHighlighted: boolean
    ref: (node: HTMLElement | null) => void
    [key: string]: any
  }
  highlightState: { highlightCursor?: string }
  renderItem: (parameters: {
    contextMenuOpen: boolean
    deleteDialogOpen: boolean
    item: T
    itemsIds: string[]
    focused: boolean
    onDeleteDialogOpen: () => void
    selected: boolean
    selectedItemIds: string[]
  }) => React.ReactNode
  renderContextMenu?: (
    item: T,
    onDeleteDialogOpen: () => void
  ) => React.ReactNode
  setContextMenuOpen: (open: boolean) => void
  onDeleteDialogOpen: () => void
  itemsReference: React.RefObject<Array<HTMLLIElement | null>>
  itemsIds: string[]
}

export function ListItem<T extends { id: string }>({
  contextMenuOpen,
  deleteDialogOpen,
  getItemProperties,
  highlightState,
  index,
  item,
  itemsIds,
  itemsReference,
  onDeleteDialogOpen,
  renderContextMenu,
  renderItem,
  selectedItemIds,
  setContextMenuOpen,
  sortable,
}: ListItemProperties<T>) {
  const isSortable = sortable && selectedItemIds.length <= 1

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: item.id,
  })

  const style = {
    position: 'relative' as const,
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
  }

  const sortAttributes = isSortable
    ? { ...attributes, ...listeners, style }
    : {}

  const { isHighlighted, ...highlightProperties } = getItemProperties(item.id)

  const setCombinedReference = (element: HTMLLIElement | null) => {
    itemsReference.current[index] = element
    if (setNodeRef) setNodeRef(element)
    if (highlightProperties.ref) highlightProperties.ref(element)
  }

  const content = (
    <li
      {...sortAttributes}
      {...highlightProperties}
      ref={setCombinedReference}
      tabIndex={0}
      className="focus:outline-none"
      role="option"
      aria-selected={isHighlighted}
    >
      {renderItem({
        contextMenuOpen,
        deleteDialogOpen,
        focused: item.id === highlightState.highlightCursor,
        item,
        itemsIds,
        onDeleteDialogOpen,
        selected: isHighlighted,
        selectedItemIds,
      })}
    </li>
  )

  return renderContextMenu ? (
    <ContextMenuRoot modal onOpenChange={setContextMenuOpen}>
      <ContextMenuTrigger asChild>{content}</ContextMenuTrigger>
      <ContextMenuPortal>
        {renderContextMenu(item, onDeleteDialogOpen)}
      </ContextMenuPortal>
    </ContextMenuRoot>
  ) : (
    content
  )
}

ListItem.displayName = 'ListItem'
