import { FC, useCallback, useMemo } from 'react'
import { ToastContentProps } from 'react-toastify'

import { Button } from '@/components/ui/button/Button'
import { Notification } from '@/features/notification/components/Notification'
import { useMarkNotificationsAsRead } from '@/features/notification/hooks/useMarkNotificationsAsRead'
import { ViewerNotification } from '@/features/notification/hooks/useNotificationPull'
import { Link } from '@tanstack/react-router'

interface NotificationToastProperties {
  notification: ViewerNotification

  // have to pass it because toast can't read the api context
  markNotificationsAsReadMutation: ReturnType<
    typeof useMarkNotificationsAsRead
  >['mutate']
}

export const NotificationToast: FC<
  ToastContentProps<NotificationToastProperties>
> = ({
  closeToast,
  data: { markNotificationsAsReadMutation, notification },
}) => {
  const handleShowTask = useCallback(() => {
    markNotificationsAsReadMutation([notification.id])
    closeToast()
  }, [closeToast, markNotificationsAsReadMutation, notification.id])

  const taskId = notification.task?.id
  const parameters = useMemo(() => ({ taskId: taskId ?? '' }), [taskId])

  return (
    <div
      className="flex flex-col gap-3"
      data-testid={`notification-toast-${notification.id}`}
    >
      <Notification notification={notification} hideTimestamp />

      <div className="flex justify-end gap-2">
        <Button
          variant="naked"
          className="border-film-subtle"
          onClick={closeToast}
        >
          Dismiss
        </Button>
        {taskId && (
          <Button asChild className="text-mono-ink-strong">
            <Link
              to="/tasks/$taskId"
              params={parameters}
              onClick={handleShowTask}
            >
              Show
            </Link>
          </Button>
        )}
      </div>
    </div>
  )
}

NotificationToast.displayName = 'NotificationToast'
