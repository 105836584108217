import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import noop from 'lodash/noop'
import { useCallback } from 'react'

import { FileMetadata } from '@/gql/generated/graphql'
import { getApiHost } from '@/lib/getApiHost'
import { logger } from '@/lib/logger'
import { makeErrorFromUnknown } from '@/lib/utilities'

type OnSuccess = (fileMetadata: FileMetadata) => void

type OnError = (error: Error) => void

const getUploadFileParameters = (file: File) => {
  const formData = new FormData()

  // Add the operations part
  formData.append(
    'operations',
    JSON.stringify({
      query: `
            mutation($file: Upload!){
              uploadFile(file: $file) {
                ... on FileMetadata { __typename, id, name, fileUrl, type, size, createdAt }
                ... on ImageFileMetadata { __typename, id, name, fileUrl, type, size, height, width, createdAt }
              }
            }
            `,
      variables: { file: '' },
    })
  )

  // Add the map part
  formData.append(
    'map',
    JSON.stringify({
      file: ['variables.file'],
    })
  )

  formData.append('file', file)
  return {
    body: formData,
    method: 'POST',
    url: `${getApiHost()}/api/v1/graphql`,
  }
}

const isFileMetadata = (input: unknown): input is FileMetadata =>
  isObject(input) &&
  'id' in input &&
  isString(input.id) &&
  'name' in input &&
  isString(input.name) &&
  'fileUrl' in input &&
  isString(input.fileUrl) &&
  'type' in input &&
  isString(input.type)

const isFileUploadResponse = (
  input: unknown
): input is {
  data: { uploadFile: FileMetadata }
} =>
  isObject(input) &&
  'data' in input &&
  isObject(input.data) &&
  'uploadFile' in input.data &&
  isFileMetadata(input.data.uploadFile)

export interface XhrUploadProperties {
  uploadItem: { file: File; key: string }
  onProgress?: (
    progress: number,
    uploadItem: { file: File; key: string }
  ) => void
  onSuccess?: OnSuccess
  onError?: OnError
}
export const useXhrUpload = () =>
  useCallback(
    ({
      onError = noop,
      onProgress = noop,
      onSuccess = noop,
      uploadItem,
    }: XhrUploadProperties) => {
      const xhr = new XMLHttpRequest()
      xhr.withCredentials = true

      const requestOptions = getUploadFileParameters(uploadItem.file)

      xhr.upload.addEventListener('progress', (event) => {
        onProgress(Math.round((event.loaded / event.total) * 100), uploadItem)
      })

      xhr.addEventListener('load', () => {
        try {
          if (!isString(xhr.response)) {
            throw new Error('Response is not a string!')
          }

          const parsedResponse: unknown = JSON.parse(xhr.response)
          if (!isFileUploadResponse(parsedResponse)) {
            throw new Error('Invalid response format!')
          }

          onSuccess(parsedResponse.data.uploadFile)
        } catch (error) {
          logger.error(error)
          onError(makeErrorFromUnknown(error))
        }
      })

      xhr.addEventListener('error', (error) => {
        logger.error(error)
        onError(new Error('Upload failed!'))
      })
      xhr.addEventListener('abort', () => {
        onError(new Error('Upload aborted!'))
      })

      xhr.open('POST', requestOptions.url)
      xhr.send(requestOptions.body)

      return () => {
        xhr.abort()
      }
    },
    []
  )
