import { FC, useMemo } from 'react'

import { OtherFilesMessagePayload } from '@/features/task/components/chat/chat-message-payloads/OtherFilesMessagePayload'
import { TaskThreadEventPayloadFileMetadata } from '@/features/task/components/chat/threadPayloadTypes'
import { useBoolean } from '@/lib/hooks/useBoolean'

interface AudioMessagePayloadProperties {
  audioFiles: TaskThreadEventPayloadFileMetadata[]
}

const AudioElement: FC<{ file: TaskThreadEventPayloadFileMetadata }> = ({
  file,
}) => {
  const { setTrue: setHasError, value: hasError } = useBoolean(false)
  const files = useMemo(() => [file], [file])

  return hasError ? (
    <OtherFilesMessagePayload files={files} />
  ) : (
    <audio
      data-testid="audio-player"
      controls
      src={file.fileUrl}
      onError={setHasError}
      onContextMenu={(event) => {
        event.stopPropagation()
      }}
    />
  )
}

AudioElement.displayName = 'AudioElement'

export const AudioMessagePayload: FC<AudioMessagePayloadProperties> = ({
  audioFiles,
}) => {
  return (
    <div className="mb-2 flex flex-wrap gap-2">
      {audioFiles.map((file) => (
        <AudioElement key={file.id} file={file} />
      ))}
    </div>
  )
}

AudioMessagePayload.displayName = 'AudioMessagePayload'
