import { useCallback } from 'react'
import ReactFocusLock, { MoveFocusInside } from 'react-focus-lock'

import { Button } from '@/components/ui/button/Button'
import { Checkbox } from '@/components/ui/checkbox/Checkbox'
import { Form } from '@/components/ui/form/Form'
import { InputField } from '@/components/ui/input-field/InputField'
import { useClickOutside } from '@/lib/hooks/useClickOutside'
import { useDocumentKeyCapture } from '@/lib/hooks/useDocumentKeyCapture'
import { cn } from '@/lib/utilities'

interface SubtaskPopoverRenameFormProperties {
  isCompleted: boolean
  isRenaming: boolean
  onRename: (title: string) => void
  setNotRenaming: () => void
  taskId: string
  title: string
}

export const SubtaskPopoverRenameForm = ({
  isCompleted,
  isRenaming,
  onRename,
  setNotRenaming,
  taskId,
  title,
}: SubtaskPopoverRenameFormProperties) => {
  const outsideClickReference = useClickOutside<HTMLFormElement>(setNotRenaming)

  useDocumentKeyCapture('Escape', setNotRenaming)

  const handleSubmit = useCallback(
    ({ title: newTitle }: Record<string, any>) => {
      if (newTitle !== title) {
        onRename(newTitle as string)
      }
      setNotRenaming()
    },
    [onRename, setNotRenaming, title]
  )

  const handleContextMenu = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
  }, [])

  return (
    <ReactFocusLock>
      <Form
        onSubmit={handleSubmit}
        className={cn('flex flex-col p-1.5')}
        data-popover={isRenaming}
        ref={outsideClickReference}
        onContextMenu={handleContextMenu}
      >
        <div className="flex items-center space-x-1.5">
          <div className="flex h-8 w-8 items-center justify-center rounded-md">
            <Checkbox id={taskId} checked={isCompleted} />
          </div>

          <MoveFocusInside className="grow">
            <InputField
              defaultValue={title}
              name="title"
              className="w-full border-none bg-transparent focus:outline-hidden"
              selectAllInitially
            />
          </MoveFocusInside>
        </div>

        <div className="ml-auto flex space-x-2">
          <Button onClick={setNotRenaming} type="button" size={'sm'}>
            Discard
            <span className="rounded-sm bg-film-normal p-1">ESC</span>
          </Button>
          <Button type="submit" variant={'solid'} size={'sm'}>
            Save
          </Button>
        </div>
      </Form>
    </ReactFocusLock>
  )
}

SubtaskPopoverRenameForm.displayName = 'SubtaskPopoverRenameForm'
