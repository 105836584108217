import { FC, useCallback, ComponentProps } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

interface RadioGroupProperties extends RadioGroupPrimitive.RadioGroupProps {
  name: string
  values: Array<{ value: string; label: string }>
}

export const RadioGroup: FC<RadioGroupProperties> = ({
  name,
  values,
  ...rest
}) => {
  const { control } = useFormContext()

  const radioGroup = useCallback(
    ({
      field: { name, onBlur, onChange, ref, value: currentValue },
    }: ComponentProps<ComponentProps<typeof Controller>['render']>) => (
      <RadioGroupPrimitive.Root
        name={name}
        ref={ref}
        value={currentValue}
        onBlur={onBlur}
        onValueChange={onChange}
        {...rest}
      >
        {values.map(({ label, value }) => {
          const id = `radio-${value}`

          return (
            <div key={value} className="flex items-center gap-2">
              <RadioGroupPrimitive.Item
                id={id}
                value={value}
                className="size-5 rounded-full border-2 border-mono-ink-strong"
              >
                <RadioGroupPrimitive.Indicator className="flex size-full items-center justify-center after:size-3 after:rounded-full after:bg-mono-ink-strong" />
              </RadioGroupPrimitive.Item>
              <label htmlFor={id} className="text-md-regular font-normal">
                {label}
              </label>
            </div>
          )
        })}
      </RadioGroupPrimitive.Root>
    ),
    [rest, values]
  )

  return <Controller name={name} control={control} render={radioGroup} />
}

RadioGroup.displayName = 'RadioGroup'
