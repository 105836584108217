import uniqueId from 'lodash/uniqueId'
import React, { forwardRef, useCallback, useMemo, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import Markdown, { Options } from 'react-markdown'
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize'
import remarkGfm from 'remark-gfm'

import { useBoolean } from '@/lib/hooks/useBoolean'
import { cn, mergeRefs } from '@/lib/utilities'

interface Controlled {
  mode: 'controlled'
  value: string
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

interface Uncontrolled {
  mode: 'uncontrolled'
}

type NativeProperties = React.HTMLAttributes<HTMLTextAreaElement>
type AutosizeProperties = Omit<
  TextareaAutosizeProps,
  'onChange' | 'value' | 'onFocus'
>

export type TextAreaProperties = NativeProperties &
  AutosizeProperties & {
    focused?: boolean
    placeholder?: string
    name: string
  } & (Controlled | Uncontrolled)

const remarkPlugins: Options['remarkPlugins'] = [remarkGfm]

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProperties>(
  (properties, reference) => {
    const {
      setFalse: localBlur,
      setTrue: localFocus,
      value: isLocallyFocused,
    } = useBoolean()
    const textAreaReference = useRef<HTMLTextAreaElement>(null)
    const elementId = useRef<string>(uniqueId('text-area-'))
    const { className, focused, mode, name, onChange, ...remainingProperties } =
      properties
    const isFocused = mode === 'uncontrolled' ? isLocallyFocused : focused
    const onBlur = mode === 'uncontrolled' ? localBlur : properties.onBlur
    const onFocus = mode === 'uncontrolled' ? localFocus : properties.onFocus
    const { getValues, register } = useFormContext()

    const uncontrolledProperties = useMemo(
      () => (mode === 'uncontrolled' ? { ...register(name) } : undefined),
      [mode, name, register]
    )
    const controlledProperties =
      mode === 'controlled'
        ? {
            ref: mergeRefs(reference, textAreaReference),
            value: properties.value,
          }
        : undefined

    const combinedOnChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(event)
        void uncontrolledProperties?.onChange(event)
      },
      [onChange, uncontrolledProperties]
    )

    const label = mode === 'controlled' ? properties.value : getValues()[name]

    return (
      <>
        <label
          className={cn(
            'markdown border-transparent bg-film-subtle hover:border-film-strong hover:bg-film-normal',
            {
              'absolute -z-10 h-0 w-0 overflow-hidden border-0 p-0': isFocused,
              'relative box-border block max-h-[600px] min-h-9 w-full overflow-hidden rounded-sm border-[1px] border-transparent bg-film-subtle p-1.5 leading-snug font-normal tracking-tight whitespace-pre-wrap transition-colors duration-200':
                !isFocused,
              'text-film-stronger': !label,
            }
          )}
          htmlFor={elementId.current}
          data-testid={`${(remainingProperties as Record<string, unknown>)['data-testid']?.toString() ?? ''}-label`}
        >
          <Markdown remarkPlugins={remarkPlugins}>{label}</Markdown>
        </label>

        <TextareaAutosize
          id={elementId.current}
          className={cn(
            {
              'absolute -z-10 w-0 overflow-hidden border-0 p-0': !isFocused,
              'transition-100 block w-full resize-none overflow-auto rounded-sm border-[1.5px] border-blue-ink-subtle bg-mono-paper p-1.5 leading-snug font-normal tracking-tight text-mono-ink-strong caret-sky-500 outline-hidden transition-colors ease-in-out placeholder:text-film-stronger/15':
                isFocused,
            },
            className
          )}
          {...remainingProperties}
          {...controlledProperties}
          {...uncontrolledProperties}
          onChange={combinedOnChange}
          onFocus={onFocus}
          onBlur={onBlur}
          rows={1}
        />
      </>
    )
  }
)

TextArea.displayName = 'TextArea'
