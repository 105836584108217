import { forwardRef } from 'react'

import { cn } from '@/lib/utilities'
import {
  ContextMenuContentProps,
  ContextMenuItemProps,
  ContextMenuSubContentProps,
  ContextMenuSeparatorProps,
  ContextMenuSubTriggerProps,
} from '@radix-ui/react-context-menu'
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu'

export const ContextMenuRoot = ContextMenuPrimitive.Root
export const ContextMenuTrigger = ContextMenuPrimitive.Trigger
export const ContextMenuPortal = ContextMenuPrimitive.Portal
export const ContextMenuSub = ContextMenuPrimitive.Sub

export const ContextMenuContent = forwardRef<
  HTMLDivElement,
  ContextMenuContentProps
>(({ className, ...rest }, reference) => {
  return (
    <ContextMenuPrimitive.Content
      ref={reference}
      className={cn(
        'z-50 w-63 rounded-lg bg-mono-paper p-1 shadow-just-shadow',
        className
      )}
      loop
      alignOffset={0}
      avoidCollisions
      {...rest}
    />
  )
})

ContextMenuContent.displayName = 'ContextMenuContent'

export const ContextMenuSubTrigger = forwardRef<
  HTMLDivElement,
  ContextMenuSubTriggerProps
>(({ className, ...rest }, reference) => {
  return (
    <ContextMenuPrimitive.SubTrigger
      ref={reference}
      className={cn(
        'w-full justify-start hover:shadow-none focus:bg-sky-600 focus:text-mono-paper focus:shadow-none',
        className
      )}
      {...rest}
    />
  )
})

ContextMenuSubTrigger.displayName = 'ContextMenuSubTrigger'

export const ContextMenuSubContent = forwardRef<
  HTMLDivElement,
  ContextMenuSubContentProps
>(({ className, ...rest }, reference) => {
  return (
    <ContextMenuPrimitive.SubContent
      ref={reference}
      className={cn(
        'z-50 max-h-108 w-50 overflow-auto rounded-lg bg-mono-paper p-1 shadow-just-shadow',
        className
      )}
      loop
      avoidCollisions
      {...rest}
    />
  )
})

ContextMenuSubContent.displayName = 'ContextMenuSubContent'

export const ContextMenuItem = forwardRef<HTMLDivElement, ContextMenuItemProps>(
  ({ className, ...rest }, reference) => {
    return (
      <ContextMenuPrimitive.Item
        ref={reference}
        className={cn(
          'w-full justify-start hover:shadow-none focus:bg-sky-600 focus:text-mono-paper focus:shadow-none',
          className
        )}
        {...rest}
      />
    )
  }
)

ContextMenuItem.displayName = 'ContextMenuItem'

export const ContextMenuSeparator = forwardRef<
  HTMLDivElement,
  ContextMenuSeparatorProps
>(({ className, ...rest }, reference) => {
  return (
    <ContextMenuPrimitive.Separator
      ref={reference}
      className={cn('my-1 h-0.25 bg-film-normal', className)}
      {...rest}
    />
  )
})

ContextMenuSeparator.displayName = 'ContextMenuSeparator'
