import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { TaskData } from '@/features/task/hooks/useTask'
import {
  OrderSubtasksInput,
  OrderSubtasksMutation,
} from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'
import { arrayMove } from '@dnd-kit/sortable'
import { useMutation, useQueryClient } from '@tanstack/react-query'

type MutationDTO = OrderSubtasksInput & {
  activeId?: string
  overId?: string
}

export const useOrderSubtasks = (
  taskId: string,
  parameters?: {
    orderType: 'orderBySwapping' | 'orderByPlacingAfter'
  }
) => {
  const api = useApi()
  const queryClient = useQueryClient()

  const orderSubtasksMutation = useMutation<
    OrderSubtasksMutation,
    Error,
    MutationDTO
  >({
    mutationFn: async ({ insertAfterSubtaskWithId, orderedSubtaskIds }) =>
      await api.orderSubtasks(
        orderedSubtaskIds,
        insertAfterSubtaskWithId ?? undefined
      ),
    mutationKey: ['orderSubtasks'],
    onError: () => {
      logger.error('Failed to order subtasks')
      toast.error('Failed to order subtasks')
    },
    onMutate: async (variables) => {
      const { activeId, overId } = variables

      queryClient.setQueryData(['task', taskId], (previousData: TaskData) => {
        const oldIndex = previousData.subtasks.findIndex(
          (item) => item.id === activeId
        )
        const newIndex = previousData.subtasks.findIndex(
          (item) => item.id === overId
        )

        return {
          ...previousData,
          subtasks: arrayMove
            ? arrayMove(
                previousData.subtasks,
                oldIndex,
                parameters?.orderType === 'orderByPlacingAfter'
                  ? newIndex + 1
                  : newIndex
              )
            : previousData.subtasks,
        }
      })

      return { previousData: queryClient.getQueryData(['task', taskId]) }
    },
  })

  return orderSubtasksMutation
}
