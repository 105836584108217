import { ClientError } from 'graphql-request'
import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { FetchNotesQuery } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const queryKey = ['notes']
export const useCreateNote = () => {
  const api = useApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.createNote,
    onMutate: (payload) => {
      const previousState = queryClient.getQueryData<FetchNotesQuery>(queryKey)

      queryClient.setQueryData<FetchNotesQuery>(queryKey, (previousState) => {
        if (!previousState || Boolean(payload.fileIds?.length)) return

        const { fileIds, ...rest } = payload

        return {
          notes: [
            {
              ...rest,
              files: [],
              id: 'unsynchronised-note-id',
            },
            ...previousState.notes,
          ],
        }
      })

      return previousState
    },
    // Partition: Important to keep these definitions below onMutate property so the TS compiler infers context properly
    onError: (error, _variables, context) => {
      queryClient.setQueryData(queryKey, context)

      logger.error(error)

      if (error instanceof ClientError) {
        toast.error(error.response.errors?.[0]?.message)
      } else {
        toast.error('Note update failed!')
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey,
      })
    },
  })
}
