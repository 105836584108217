import { FC, PropsWithChildren, ReactNode } from 'react'

import { TaskIcon } from '@/features/task/components/TaskIcon'
import { cn } from '@/lib/utilities'
import { TaskStatus } from '@/tests/generated-api-types'

export interface Properties {
  taskId: string
  status?: TaskStatus
  action?: ReactNode
}

export const Subtask: FC<PropsWithChildren<Properties>> = ({
  action,
  children,
  status,
  taskId,
}) => {
  return (
    <div
      className={cn(
        'flex h-11 items-center gap-1.5 border-b border-film-subtle p-1.5 last:border-none'
      )}
      data-testid={`subtask-${taskId}`}
    >
      <TaskIcon status={status} size="md" />

      <div className="w-1 grow truncate">{children}</div>
      {action}
    </div>
  )
}

Subtask.displayName = 'Subtask'
