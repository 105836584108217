import React from 'react'

import { cn } from '@/lib/utilities'

const Skeleton = ({
  className,
  ...properties
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('animate-pulse rounded-md', className)}
      {...properties}
    />
  )
}

Skeleton.displayName = 'Skeleton'

export { Skeleton }
