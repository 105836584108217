/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PrivateImport } from './routes/_private'
import { Route as AuthImport } from './routes/_auth'
import { Route as PrivateIndexImport } from './routes/_private/index'
import { Route as PrivateTeamImport } from './routes/_private/team'
import { Route as PrivateSettingsImport } from './routes/_private/settings'
import { Route as PrivateLibraryImport } from './routes/_private/library'
import { Route as AuthSigninImport } from './routes/_auth/signin'
import { Route as AuthAuthCallbackImport } from './routes/_auth/auth-callback'
import { Route as PrivateSpacesIndexImport } from './routes/_private/spaces.index'
import { Route as PrivateTasksTaskIdImport } from './routes/_private/tasks.$taskId'
import { Route as PrivateSpacesSpaceIdImport } from './routes/_private/spaces.$spaceId'
import { Route as PrivateTasksTaskIdIndexImport } from './routes/_private/tasks.$taskId.index'
import { Route as PrivateSpacesSpaceIdIndexImport } from './routes/_private/spaces.$spaceId.index'

// Create/Update Routes

const PrivateRoute = PrivateImport.update({
  id: '/_private',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const PrivateIndexRoute = PrivateIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/index.lazy').then((d) => d.Route),
)

const PrivateTeamRoute = PrivateTeamImport.update({
  id: '/team',
  path: '/team',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() => import('./routes/_private/team.lazy').then((d) => d.Route))

const PrivateSettingsRoute = PrivateSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/settings.lazy').then((d) => d.Route),
)

const PrivateLibraryRoute = PrivateLibraryImport.update({
  id: '/library',
  path: '/library',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/library.lazy').then((d) => d.Route),
)

const AuthSigninRoute = AuthSigninImport.update({
  id: '/signin',
  path: '/signin',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAuthCallbackRoute = AuthAuthCallbackImport.update({
  id: '/auth-callback',
  path: '/auth-callback',
  getParentRoute: () => AuthRoute,
} as any)

const PrivateSpacesIndexRoute = PrivateSpacesIndexImport.update({
  id: '/spaces/',
  path: '/spaces/',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/spaces.index.lazy').then((d) => d.Route),
)

const PrivateTasksTaskIdRoute = PrivateTasksTaskIdImport.update({
  id: '/tasks/$taskId',
  path: '/tasks/$taskId',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/tasks.$taskId.lazy').then((d) => d.Route),
)

const PrivateSpacesSpaceIdRoute = PrivateSpacesSpaceIdImport.update({
  id: '/spaces/$spaceId',
  path: '/spaces/$spaceId',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/spaces.$spaceId.lazy').then((d) => d.Route),
)

const PrivateTasksTaskIdIndexRoute = PrivateTasksTaskIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PrivateTasksTaskIdRoute,
} as any)

const PrivateSpacesSpaceIdIndexRoute = PrivateSpacesSpaceIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PrivateSpacesSpaceIdRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_private': {
      id: '/_private'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PrivateImport
      parentRoute: typeof rootRoute
    }
    '/_auth/auth-callback': {
      id: '/_auth/auth-callback'
      path: '/auth-callback'
      fullPath: '/auth-callback'
      preLoaderRoute: typeof AuthAuthCallbackImport
      parentRoute: typeof AuthImport
    }
    '/_auth/signin': {
      id: '/_auth/signin'
      path: '/signin'
      fullPath: '/signin'
      preLoaderRoute: typeof AuthSigninImport
      parentRoute: typeof AuthImport
    }
    '/_private/library': {
      id: '/_private/library'
      path: '/library'
      fullPath: '/library'
      preLoaderRoute: typeof PrivateLibraryImport
      parentRoute: typeof PrivateImport
    }
    '/_private/settings': {
      id: '/_private/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof PrivateSettingsImport
      parentRoute: typeof PrivateImport
    }
    '/_private/team': {
      id: '/_private/team'
      path: '/team'
      fullPath: '/team'
      preLoaderRoute: typeof PrivateTeamImport
      parentRoute: typeof PrivateImport
    }
    '/_private/': {
      id: '/_private/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof PrivateIndexImport
      parentRoute: typeof PrivateImport
    }
    '/_private/spaces/$spaceId': {
      id: '/_private/spaces/$spaceId'
      path: '/spaces/$spaceId'
      fullPath: '/spaces/$spaceId'
      preLoaderRoute: typeof PrivateSpacesSpaceIdImport
      parentRoute: typeof PrivateImport
    }
    '/_private/tasks/$taskId': {
      id: '/_private/tasks/$taskId'
      path: '/tasks/$taskId'
      fullPath: '/tasks/$taskId'
      preLoaderRoute: typeof PrivateTasksTaskIdImport
      parentRoute: typeof PrivateImport
    }
    '/_private/spaces/': {
      id: '/_private/spaces/'
      path: '/spaces'
      fullPath: '/spaces'
      preLoaderRoute: typeof PrivateSpacesIndexImport
      parentRoute: typeof PrivateImport
    }
    '/_private/spaces/$spaceId/': {
      id: '/_private/spaces/$spaceId/'
      path: '/'
      fullPath: '/spaces/$spaceId/'
      preLoaderRoute: typeof PrivateSpacesSpaceIdIndexImport
      parentRoute: typeof PrivateSpacesSpaceIdImport
    }
    '/_private/tasks/$taskId/': {
      id: '/_private/tasks/$taskId/'
      path: '/'
      fullPath: '/tasks/$taskId/'
      preLoaderRoute: typeof PrivateTasksTaskIdIndexImport
      parentRoute: typeof PrivateTasksTaskIdImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthAuthCallbackRoute: typeof AuthAuthCallbackRoute
  AuthSigninRoute: typeof AuthSigninRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthAuthCallbackRoute: AuthAuthCallbackRoute,
  AuthSigninRoute: AuthSigninRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface PrivateSpacesSpaceIdRouteChildren {
  PrivateSpacesSpaceIdIndexRoute: typeof PrivateSpacesSpaceIdIndexRoute
}

const PrivateSpacesSpaceIdRouteChildren: PrivateSpacesSpaceIdRouteChildren = {
  PrivateSpacesSpaceIdIndexRoute: PrivateSpacesSpaceIdIndexRoute,
}

const PrivateSpacesSpaceIdRouteWithChildren =
  PrivateSpacesSpaceIdRoute._addFileChildren(PrivateSpacesSpaceIdRouteChildren)

interface PrivateTasksTaskIdRouteChildren {
  PrivateTasksTaskIdIndexRoute: typeof PrivateTasksTaskIdIndexRoute
}

const PrivateTasksTaskIdRouteChildren: PrivateTasksTaskIdRouteChildren = {
  PrivateTasksTaskIdIndexRoute: PrivateTasksTaskIdIndexRoute,
}

const PrivateTasksTaskIdRouteWithChildren =
  PrivateTasksTaskIdRoute._addFileChildren(PrivateTasksTaskIdRouteChildren)

interface PrivateRouteChildren {
  PrivateLibraryRoute: typeof PrivateLibraryRoute
  PrivateSettingsRoute: typeof PrivateSettingsRoute
  PrivateTeamRoute: typeof PrivateTeamRoute
  PrivateIndexRoute: typeof PrivateIndexRoute
  PrivateSpacesSpaceIdRoute: typeof PrivateSpacesSpaceIdRouteWithChildren
  PrivateTasksTaskIdRoute: typeof PrivateTasksTaskIdRouteWithChildren
  PrivateSpacesIndexRoute: typeof PrivateSpacesIndexRoute
}

const PrivateRouteChildren: PrivateRouteChildren = {
  PrivateLibraryRoute: PrivateLibraryRoute,
  PrivateSettingsRoute: PrivateSettingsRoute,
  PrivateTeamRoute: PrivateTeamRoute,
  PrivateIndexRoute: PrivateIndexRoute,
  PrivateSpacesSpaceIdRoute: PrivateSpacesSpaceIdRouteWithChildren,
  PrivateTasksTaskIdRoute: PrivateTasksTaskIdRouteWithChildren,
  PrivateSpacesIndexRoute: PrivateSpacesIndexRoute,
}

const PrivateRouteWithChildren =
  PrivateRoute._addFileChildren(PrivateRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof PrivateRouteWithChildren
  '/auth-callback': typeof AuthAuthCallbackRoute
  '/signin': typeof AuthSigninRoute
  '/library': typeof PrivateLibraryRoute
  '/settings': typeof PrivateSettingsRoute
  '/team': typeof PrivateTeamRoute
  '/': typeof PrivateIndexRoute
  '/spaces/$spaceId': typeof PrivateSpacesSpaceIdRouteWithChildren
  '/tasks/$taskId': typeof PrivateTasksTaskIdRouteWithChildren
  '/spaces': typeof PrivateSpacesIndexRoute
  '/spaces/$spaceId/': typeof PrivateSpacesSpaceIdIndexRoute
  '/tasks/$taskId/': typeof PrivateTasksTaskIdIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthRouteWithChildren
  '/auth-callback': typeof AuthAuthCallbackRoute
  '/signin': typeof AuthSigninRoute
  '/library': typeof PrivateLibraryRoute
  '/settings': typeof PrivateSettingsRoute
  '/team': typeof PrivateTeamRoute
  '/': typeof PrivateIndexRoute
  '/spaces': typeof PrivateSpacesIndexRoute
  '/spaces/$spaceId': typeof PrivateSpacesSpaceIdIndexRoute
  '/tasks/$taskId': typeof PrivateTasksTaskIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_private': typeof PrivateRouteWithChildren
  '/_auth/auth-callback': typeof AuthAuthCallbackRoute
  '/_auth/signin': typeof AuthSigninRoute
  '/_private/library': typeof PrivateLibraryRoute
  '/_private/settings': typeof PrivateSettingsRoute
  '/_private/team': typeof PrivateTeamRoute
  '/_private/': typeof PrivateIndexRoute
  '/_private/spaces/$spaceId': typeof PrivateSpacesSpaceIdRouteWithChildren
  '/_private/tasks/$taskId': typeof PrivateTasksTaskIdRouteWithChildren
  '/_private/spaces/': typeof PrivateSpacesIndexRoute
  '/_private/spaces/$spaceId/': typeof PrivateSpacesSpaceIdIndexRoute
  '/_private/tasks/$taskId/': typeof PrivateTasksTaskIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/auth-callback'
    | '/signin'
    | '/library'
    | '/settings'
    | '/team'
    | '/'
    | '/spaces/$spaceId'
    | '/tasks/$taskId'
    | '/spaces'
    | '/spaces/$spaceId/'
    | '/tasks/$taskId/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/auth-callback'
    | '/signin'
    | '/library'
    | '/settings'
    | '/team'
    | '/'
    | '/spaces'
    | '/spaces/$spaceId'
    | '/tasks/$taskId'
  id:
    | '__root__'
    | '/_auth'
    | '/_private'
    | '/_auth/auth-callback'
    | '/_auth/signin'
    | '/_private/library'
    | '/_private/settings'
    | '/_private/team'
    | '/_private/'
    | '/_private/spaces/$spaceId'
    | '/_private/tasks/$taskId'
    | '/_private/spaces/'
    | '/_private/spaces/$spaceId/'
    | '/_private/tasks/$taskId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  PrivateRoute: typeof PrivateRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  PrivateRoute: PrivateRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/_private"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/auth-callback",
        "/_auth/signin"
      ]
    },
    "/_private": {
      "filePath": "_private.tsx",
      "children": [
        "/_private/library",
        "/_private/settings",
        "/_private/team",
        "/_private/",
        "/_private/spaces/$spaceId",
        "/_private/tasks/$taskId",
        "/_private/spaces/"
      ]
    },
    "/_auth/auth-callback": {
      "filePath": "_auth/auth-callback.tsx",
      "parent": "/_auth"
    },
    "/_auth/signin": {
      "filePath": "_auth/signin.tsx",
      "parent": "/_auth"
    },
    "/_private/library": {
      "filePath": "_private/library.tsx",
      "parent": "/_private"
    },
    "/_private/settings": {
      "filePath": "_private/settings.tsx",
      "parent": "/_private"
    },
    "/_private/team": {
      "filePath": "_private/team.tsx",
      "parent": "/_private"
    },
    "/_private/": {
      "filePath": "_private/index.tsx",
      "parent": "/_private"
    },
    "/_private/spaces/$spaceId": {
      "filePath": "_private/spaces.$spaceId.tsx",
      "parent": "/_private",
      "children": [
        "/_private/spaces/$spaceId/"
      ]
    },
    "/_private/tasks/$taskId": {
      "filePath": "_private/tasks.$taskId.tsx",
      "parent": "/_private",
      "children": [
        "/_private/tasks/$taskId/"
      ]
    },
    "/_private/spaces/": {
      "filePath": "_private/spaces.index.tsx",
      "parent": "/_private"
    },
    "/_private/spaces/$spaceId/": {
      "filePath": "_private/spaces.$spaceId.index.tsx",
      "parent": "/_private/spaces/$spaceId"
    },
    "/_private/tasks/$taskId/": {
      "filePath": "_private/tasks.$taskId.index.tsx",
      "parent": "/_private/tasks/$taskId"
    }
  }
}
ROUTE_MANIFEST_END */
