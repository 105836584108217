import { cva, VariantProps } from 'class-variance-authority'
import debounce from 'lodash/debounce'
import { ComponentProps, FC, ReactNode } from 'react'

import { CaretDownIcon } from '@/components/icons/CaretDownIcon'
import { RadioCircleSolidTinted } from '@/components/ui/select/RadioCircleSolidTinted'
import { useBoolean } from '@/lib/hooks/useBoolean'
import { IconComponent } from '@/lib/types'
import { cn } from '@/lib/utilities'
import * as RadixSelect from '@radix-ui/react-select'

const Root = (properties: RadixSelect.SelectProps) => {
  const { setFalse, toggle, value } = useBoolean()

  const handleOpenChange = (open: boolean) => {
    toggle()
    properties.onOpenChange?.(open)
  }

  const handleValueChange = (value: string) => {
    properties.onValueChange?.(value)
    debounce(() => {
      setFalse()
    }, 100)()
  }

  return (
    <RadixSelect.Root
      {...properties}
      open={value}
      onValueChange={handleValueChange}
      onOpenChange={handleOpenChange}
    />
  )
}

Root.displayName = 'Root'

export const triggerVariants = cva(
  [
    'overflow-hidden',
    'focus-visible:bg-sky-50 focus-visible:text-blue-ink-strong focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-0 focus-visible:outline-sky-300',
    'hover:border-film-subtle data-[state="open"]:border-film-stronger data-[state="open"]:bg-film-strong',
  ],
  {
    defaultVariants: {
      variant: 'full',
    },
    variants: {
      variant: {
        full: [
          'flex h-8 grow flex-row items-center justify-start gap-1 rounded-md border p-1 text-mono-ink-strong',
          'border-transparent bg-film-subtle hover:bg-film-normal data-placeholder:text-mono-ink-subtle',
        ],
        iconOnly:
          'rounded-lg border border-film-subtle px-3 hover:bg-film-normal',
      },
    },
  }
)

export interface TriggerProperties
  extends Omit<RadixSelect.SelectTriggerProps, 'value'>,
    VariantProps<typeof triggerVariants> {
  icon: ReactNode
  placeholder?: ReactNode
}

const Trigger = ({
  className,
  icon,
  placeholder,
  variant = 'full',
  ...rest
}: TriggerProperties) => {
  return (
    <RadixSelect.Trigger
      {...rest}
      className={cn(triggerVariants({ className, variant }))}
    >
      <RadixSelect.Icon>{icon}</RadixSelect.Icon>
      {variant === 'full' && (
        <>
          <span className="block grow truncate text-left text-sm-regular">
            <RadixSelect.Value placeholder={placeholder} />
          </span>
          <RadixSelect.Icon>
            <CaretDownIcon />
          </RadixSelect.Icon>
        </>
      )}
    </RadixSelect.Trigger>
  )
}

Trigger.displayName = 'Trigger'

const Content = ({
  children,
  className,
  ...rest
}: RadixSelect.SelectContentProps) => {
  return (
    <RadixSelect.Portal>
      <RadixSelect.Content
        asChild
        position="popper"
        sideOffset={8}
        avoidCollisions
        collisionPadding={{ bottom: 20, left: 20, right: 20, top: 20 }}
        {...rest}
        className={cn(
          'z-50 max-h-[var(--radix-popper-available-height)] min-w-[var(--radix-select-trigger-width)]',
          className
        )}
      >
        <RadixSelect.Viewport className="rounded-lg bg-mono-paper p-1 shadow-just-shadow">
          {children}
        </RadixSelect.Viewport>
      </RadixSelect.Content>
    </RadixSelect.Portal>
  )
}

Content.displayName = 'Content'

interface SelectItemProperties
  extends Omit<ComponentProps<typeof RadixSelect.Item>, 'children'> {
  Icon: IconComponent
  text: string
  forceChecked?: boolean
}

const Item: FC<SelectItemProperties> = ({
  className,
  forceChecked = false,
  Icon,
  text,
  ...properties
}) => {
  const { setTrue, value } = useBoolean(false)

  const checkedIndicator = (
    <div className="flex h-6 w-6 items-center justify-center">
      <RadioCircleSolidTinted
        className="size-6 min-h-6 min-w-6"
        innerCircleClass={cn({
          'fill-sky-50': !value,
          'fill-sky-700': value,
        })}
        outerCircleClass={cn({
          'fill-sky-50': value,
          'fill-sky-500': !value,
        })}
      />
    </div>
  )

  return (
    <RadixSelect.Item
      className={cn(
        'transition-bg transition-50 flex h-8 cursor-pointer items-center rounded-md border border-transparent p-1 text-sm-regular outline-hidden',
        {
          'bg-sky-700 text-sky-50': value,
          'data-highlighted:border-sky-300 data-highlighted:bg-sky-50 data-highlighted:text-blue-ink-strong':
            !value,
        },
        className
      )}
      onMouseUp={setTrue}
      {...(forceChecked ? { 'data-state': 'checked' } : {})}
      {...properties}
    >
      <Icon className="h-6 w-6" />
      <div className="grow px-1">
        <RadixSelect.ItemText>{text}</RadixSelect.ItemText>
      </div>
      {value || forceChecked ? (
        checkedIndicator
      ) : (
        <RadixSelect.ItemIndicator>
          {checkedIndicator}
        </RadixSelect.ItemIndicator>
      )}
    </RadixSelect.Item>
  )
}
Item.displayName = 'SelectItem'

export default {
  Content,
  Item,
  Root,
  Trigger,
}
