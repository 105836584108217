import { useApi } from '@/contexts/ApiProvider'
import { FetchSpacesQuery } from '@/gql/generated/graphql'
import { useSuspenseQuery } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'

interface CreateQueryOptionParameters {
  api: RouteContext['api']
  id?: string
}

export const createSpacesQueryOptions = ({
  api,
  id,
}: CreateQueryOptionParameters) => {
  return {
    queryFn: api.fetchSpaces,
    queryKey: id ? ['space', id] : ['spaces'],
  }
}

export function useSpaces(): FetchSpacesQuery
export function useSpaces(id: string): {
  space: FetchSpacesQuery['spaces'][number]
}

export function useSpaces(id?: string) {
  const api = useApi()

  const queryOptions = createSpacesQueryOptions({ api, id })

  const { data } = useSuspenseQuery(queryOptions)

  if (id) {
    // Id filter is not supported by the server yet
    const space = { space: data.spaces.find((space) => space.id === id) }

    if (!space) {
      throw new Error('Space not found!')
    }

    return space
  }

  return data
}
