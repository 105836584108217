import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { List } from '@/components/ui/list/List'
import { useApi } from '@/contexts/ApiProvider'
import { DeleteTasksDialog } from '@/features/task/components/DeleteTaskDialog/DeleteTasksDialog'
import { PasteTasksDialog } from '@/features/task/components/subtasks//PasteTaskDialog'
import { SubtaskPopoverContent } from '@/features/task/components/subtasks/SubtaskPopoverContent'
import { SubtasksListItem } from '@/features/task/components/subtasks/SubtasksListItem'
import { useDeleteTask } from '@/features/task/hooks/useDeleteTask'
import { useOrderSubtasks } from '@/features/task/hooks/useOrderSubtasks'
import { createTaskQueryOptions, TaskData } from '@/features/task/hooks/useTask'
import { useUpdateTaskStatus } from '@/features/task/hooks/useUpdateTaskStatus'
import { makeErrorFromUnknown } from '@/lib/utilities'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate, useSearch } from '@tanstack/react-router'

type SubtaskData = TaskData['subtasks']
interface SubtasksListProperties {
  subtasks: SubtaskData
  taskId: string
}

export const SubtasksList = ({ subtasks, taskId }: SubtasksListProperties) => {
  const api = useApi()
  const queryClient = useQueryClient()

  const [taskToRename, setTaskToRename] = useState<string | undefined>()
  const [taskToMove, setTaskToMove] = useState<SubtaskData[number]>()
  const [taskToBePastedAfter, setTaskToBePastedAfter] =
    useState<SubtaskData[number]>()

  const { stack } = useSearch({
    from: '/_private',
  })

  const navigate = useNavigate()
  const { updateTaskStatus } = useUpdateTaskStatus()

  const deleteTaskMutation = useDeleteTask({ taskId })

  const handleDelete = useCallback(
    (ids: string[]) => {
      for (const id of ids) {
        deleteTaskMutation.mutate(id)
      }
    },
    [deleteTaskMutation]
  )

  const orderSubtasksMutation = useOrderSubtasks(taskId)

  const handleOnSortEnd = useCallback(
    ({
      activeIndex,
      overIndex,
    }: {
      activeIndex: number
      overIndex: number
    }) => {
      const active = subtasks[activeIndex]
      const over = subtasks[overIndex]

      if (active && over) {
        const insertAfterSubtaskWithId =
          activeIndex < overIndex
            ? over.id
            : overIndex - 1 >= 0
              ? subtasks[overIndex - 1]?.id
              : undefined

        orderSubtasksMutation.mutate({
          activeId: active.id,
          insertAfterSubtaskWithId,
          orderedSubtaskIds: [active.id],
          overId: over.id,
        })
      }
    },
    [orderSubtasksMutation, subtasks]
  )

  const navigateToTask = useCallback(
    (taskId: string) => {
      return () => {
        void navigate({
          params: { taskId },
          to: '/tasks/$taskId',
        })
      }
    },
    [navigate]
  )

  const handleTaskStatusChange = useCallback(
    (taskId: string) => {
      return (status: SubtaskData[number]['status']) => {
        updateTaskStatus({
          id: taskId,
          status,
        })
      }
    },
    [updateTaskStatus]
  )

  const setRenaming = useCallback(
    (id?: string) => {
      return () => {
        setTaskToRename(id)
      }
    },
    [setTaskToRename]
  )

  const handleCopyToClipboard = useCallback((task: SubtaskData[number]) => {
    void navigator.clipboard.writeText(`${location.origin}/tasks/${task.id}`)
    setTaskToMove(task)
    toast.success('Link copied to clipboard')
  }, [])

  const handleCopyFromContextMenu = useCallback(
    (task: SubtaskData[number]) => {
      return () => {
        handleCopyToClipboard(task)
      }
    },
    [handleCopyToClipboard]
  )

  const handleClosePasteDialog = useCallback(() => {
    setTaskToBePastedAfter(undefined)
    setTaskToMove(undefined)
  }, [])

  const handlePaste = useCallback(
    (pastedText: string, taskIdToBeAfter: SubtaskData[number]) => {
      if (pastedText.trim() === '') {
        return
      }

      const taskUrlSegments = new URL(pastedText).pathname.split('/')
      const taskId = taskUrlSegments.at(-1)
      const isSubtaskAlreadyPresent = subtasks.some(
        (subtask) => subtask.id === taskId
      )

      if (isSubtaskAlreadyPresent) {
        toast.info('The task is already present', {
          toastId: 'subtask-already-present',
        })
        return
      }

      if (taskId) {
        const queryOptions = createTaskQueryOptions({ api, taskId })
        void queryClient.fetchQuery({ ...queryOptions }).then((data) => {
          setTaskToMove(data)
          setTaskToBePastedAfter(taskIdToBeAfter)
        })
      }
    },
    [api, queryClient, subtasks]
  )

  const handlePasteFromContextMenu = useCallback(
    (taskToBePastedAfter: SubtaskData[number]) => {
      return () => {
        void navigator.clipboard
          .readText()
          .then((pastedText) => {
            handlePaste(pastedText, taskToBePastedAfter)
          })
          .catch((error: unknown) => {
            toast.error(makeErrorFromUnknown(error).message)
          })
      }
    },
    [handlePaste]
  )

  const handlePasteByEvent = useCallback(
    (event: ClipboardEvent, itemToBePastedAfter?: SubtaskData[number]) => {
      if (event.clipboardData && itemToBePastedAfter) {
        const pastedText = event.clipboardData.getData('text')
        handlePaste(pastedText, itemToBePastedAfter)
      }
    },
    [handlePaste]
  )

  const renderDeleteItem = useCallback(
    (parameters: {
      focusedIndex: number
      isOpen: boolean
      selectedItems: SubtaskData
      onClose: () => void
    }) => {
      return (
        <DeleteTasksDialog
          onDeleteClick={handleDelete}
          itemsToDelete={parameters.selectedItems}
          isDialogOpen={parameters.isOpen}
          onOpenChange={parameters.onClose}
        />
      )
    },
    [handleDelete]
  )

  const renderContextMenu = useCallback(
    (task: SubtaskData[number], onDeleteDialogOpen: () => void) => {
      return (
        <div
          onKeyDown={(event) => {
            event.stopPropagation()
          }}
        >
          <SubtaskPopoverContent
            assigneeId={task.assignee?.id}
            onTaskStatusChange={handleTaskStatusChange(task.id)}
            onCopyToClipboard={handleCopyFromContextMenu(task)}
            onDelete={onDeleteDialogOpen}
            onRename={setRenaming(task.id)}
            onOpenTask={navigateToTask(task.id)}
            onPasteTask={handlePasteFromContextMenu(task)}
            title={task.title}
            taskId={task.id}
          />
        </div>
      )
    },
    [
      handleCopyFromContextMenu,
      handlePasteFromContextMenu,
      handleTaskStatusChange,
      navigateToTask,
      setRenaming,
    ]
  )

  const renderItem = useCallback(
    (parameters: {
      contextMenuOpen: boolean
      deleteDialogOpen: boolean
      item: SubtaskData[number]
      focused: boolean
      selected: boolean
      selectedItemIds: string[]
      onDeleteDialogOpen: () => void
      itemsIds: string[]
    }) => (
      <SubtasksListItem
        isContextMenuOpen={parameters.contextMenuOpen}
        isRenaming={taskToRename === parameters.item.id}
        setNotRenaming={setRenaming(undefined)}
        task={parameters.item}
        focused={parameters.focused}
        selected={parameters.selected}
        selectedItemIds={parameters.selectedItemIds}
        isDeleteDialogOpen={parameters.deleteDialogOpen}
        adjacentTaskIds={parameters.itemsIds}
      />
    ),
    [setRenaming, taskToRename]
  )

  return (
    <>
      <List
        containerClassName="rounded-lg border border-film-subtle shadow-sm focus:outline-hidden"
        initialHighlightedItem={stack?.find((id) =>
          subtasks.some((subtask) => subtask.id === id)
        )}
        items={subtasks}
        preserveHighlight={Boolean(stack?.length && stack.at(-1) !== taskId)}
        renderDeleteItem={renderDeleteItem}
        renderContextMenu={renderContextMenu}
        renderItem={renderItem}
        testId="subtaskList"
        sortable
        onSortEnd={handleOnSortEnd}
        onCopy={handleCopyToClipboard}
        onPaste={handlePasteByEvent}
        disableKeyboardInteraction={!!taskToRename}
      />

      {taskToMove && (
        <PasteTasksDialog
          taskToMove={taskToMove}
          parentTaskId={taskId}
          taskToMoveOverId={taskToBePastedAfter?.id ?? ''}
          isDialogOpen={!!taskToBePastedAfter}
          onOpenChange={handleClosePasteDialog}
        />
      )}
    </>
  )
}

SubtasksList.displayName = 'SubtasksList'
