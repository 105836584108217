import { ClassValue, clsx } from 'clsx'
import { differenceInDays } from 'date-fns/differenceInDays'
import { formatDistance } from 'date-fns/formatDistance'
import { ClientError } from 'graphql-request'
import isError from 'lodash/isError'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import negate from 'lodash/negate'
import { twMerge } from 'tailwind-merge'

/**
 * A Utility function to efficiently merge Tailwind CSS classes in JS without style conflicts.
 *
 * Usage:
 * ```tsx
 * <div className={cn('w-32 h-32', className)} />
 * ```
 *
 * @param {string} inputs the variants and classNames
 * @returns {string} the merged classnames
 */

export const cn = (...inputs: ClassValue[]): string => twMerge(clsx(inputs))

export const makeErrorFromUnknown = (input: unknown): Error =>
  isError(input) ? input : new Error(isString(input) ? input : 'Unknown error.')

/**
 * A function that merges React refs into one.
 * Supports both functions and ref objects created using createRef() and useRef().
 *
 * Usage:
 * ```tsx
 * <div ref={mergeRefs(ref1, ref2, ref3)} />
 * ```
 *
 * @param {(React.Ref<T> | undefined)[]} inputReferences Array of refs
 * @returns {React.Ref<T> | React.RefCallback<T>} Merged refs
 */

// React refs are called "refs" and not "references".
// This function merges react refs
// eslint-disable-next-line unicorn/prevent-abbreviations
export const mergeRefs = <T>(
  ...inputReferences: Array<React.Ref<T> | undefined>
): React.Ref<T> | React.RefCallback<T> | undefined => {
  const filteredInputReferences = inputReferences.filter(Boolean)

  if (filteredInputReferences.length <= 1) {
    const firstReference = filteredInputReferences[0]

    return firstReference ?? undefined
  }

  return function mergedReferences(reference) {
    for (const inputReference of filteredInputReferences) {
      if (isFunction(inputReference)) {
        inputReference(reference)
      } else if (inputReference) {
        inputReference.current = reference
      }
    }
  }
}

export const isMac = () => navigator.userAgent.toUpperCase().includes('MAC')

export const callWith =
  <T extends []>(...arguments_: T) =>
  (function_: (...arguments__: T) => void) => {
    function_(...arguments_)
  }

export const isNotNil = <T>(value?: T): value is NonNullable<T> =>
  negate(isNil)(value)

export const formatMessageTimestamp = (timestamp: string) => {
  try {
    const date = new Date(timestamp)
    const now = new Date()
    const diff = differenceInDays(now, date)
    if (diff > 2) {
      return date.toLocaleDateString()
    }

    return `${formatDistance(now, date)} ago`
  } catch {
    return timestamp
  }
}

export const isCtrlPressed = (event: { metaKey: boolean; ctrlKey: boolean }) =>
  isMac() ? event.metaKey : event.ctrlKey

export const isImageFile = (file: { type: string }) =>
  file.type.startsWith('image/')

export const isVideoFile = (file: { mimeType: string }) =>
  file.mimeType.startsWith('video/')
export const isAudioFile = (file: { mimeType: string }) =>
  file.mimeType.startsWith('audio/')

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 B'

  const marker = isMac() ? 1000 : 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const measureIndex = Math.floor(Math.log(bytes) / Math.log(marker))

  return `${Number.parseFloat((bytes / Math.pow(marker, measureIndex)).toFixed(decimals))} ${sizes[measureIndex]}`
}

export const parseError = (error: unknown, defaultMessage: string) => {
  if (error instanceof ClientError)
    return error.response.errors?.[0]?.message ?? defaultMessage
  if (isError(error)) return error.message
  if (isString(error)) return error

  return defaultMessage
}
