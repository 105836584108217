import { PeopleIcon } from '@/components/icons/PeopleIcon'
import { BreadcrumbTitle } from '@/components/layout/BreadCrumbTitle'
import { Api } from '@/contexts/ApiProvider'
import { createAllWorkQueryOptions } from '@/features/spaces/hooks/useAllWork'
import { useSpaces } from '@/features/spaces/hooks/useSpaces'
import { createUsersQueryOptions } from '@/features/task/hooks/useUsers'
import { useDocumentTitle } from '@/lib/hooks/useDocumentTitle'
import { createFileRoute, useParams } from '@tanstack/react-router'

export interface AllWorkLoaderData {
  tasks: Awaited<ReturnType<Api['fetchAllWork']>>
}

export const Route = createFileRoute('/_private/spaces/$spaceId')({
  loader: async ({ context, params }) => ({
    space: await context.queryClient.ensureQueryData(
      createAllWorkQueryOptions({ api: context.api, spaceId: params.spaceId })
    ),
    users: await context.queryClient.ensureQueryData(
      createUsersQueryOptions({ api: context.api })
    ),
  }),
})

const SpaceBreadcrumb = () => {
  const { spaceId } = useParams({ from: '/_private/spaces/$spaceId' })
  const { space } = useSpaces(spaceId)
  useDocumentTitle(space.name)

  return (
    <BreadcrumbTitle
      Icon={PeopleIcon}
      title={space.name}
      className="duration-300 animate-in fade-in"
      data-testid="task-breadcrumb"
    />
  )
}
SpaceBreadcrumb.displayName = 'SpaceBreadcrumb'

Route.interloom = {
  Breadcrumb: SpaceBreadcrumb,
  defaultPageTitle: 'Spaces',
  tooltipText: 'Spaces',
}
