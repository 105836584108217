import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'

import { NotesStickyIcon } from '@/components/icons/NotesStickyIcon'
import { Button } from '@/components/ui/button/Button'
import { EditableTitle } from '@/components/ui/editable-title/EditableTitle'
import { Form } from '@/components/ui/form/Form'
import { TextArea } from '@/components/ui/text-area/TextArea'
import { useCreateNote } from '@/features/library/hooks/useCreateNote'
import { useCloseStackItem } from '@/lib/hooks/useCloseStackView'

export const NewNote: FC = () => {
  const [title, setTitle] = useState('New Note')
  const [body, setBody] = useState('')
  const { mutate: createNoteMutation } = useCreateNote()
  const { closeStackItem } = useCloseStackItem()

  const closeNewNoteStackItem = useCallback(() => {
    closeStackItem('new', 'note')
  }, [closeStackItem])

  const saveNote = useCallback(() => {
    createNoteMutation({ body, title })
    closeNewNoteStackItem()
  }, [body, closeNewNoteStackItem, createNoteMutation, title])

  const handleBodyChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setBody(event.target.value)
    },
    []
  )

  return (
    <div className="flex h-full flex-col justify-between overflow-y-auto">
      <EditableTitle
        defaultTitle={title}
        label="Note name"
        icon={useMemo(
          () => (
            <NotesStickyIcon className="h-9 w-9 rounded-sm bg-film-subtle p-1" />
          ),
          []
        )}
        titleOnSubmit={setTitle}
        className="border-b border-film-normal p-0 px-3"
      />
      <Form
        onSubmit={saveNote}
        onReset={closeNewNoteStackItem}
        className="flex h-full flex-col justify-between overflow-y-auto"
      >
        <div className="flex-1 overflow-y-auto px-15 py-7">
          <TextArea
            name="note"
            mode="controlled"
            value={body}
            onChange={handleBodyChange}
            focused
            autoFocus
            minRows={20}
            data-testid="note-body"
            className="bg-film-subtle"
            placeholder="Enter note..."
          />
        </div>
        <div className="flex justify-end gap-4 border-t border-film-normal py-7 pr-12">
          <Button
            type="reset"
            variant="subtle"
            data-testid="note-cancel-button"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="solid"
            disabled={!body}
            data-testid="note-save-button"
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  )
}

NewNote.displayName = 'NewNote'
