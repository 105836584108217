import { IconComponent } from '@/lib/types'

export const ExpandArrowIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M7.42725 9.5L5.92725 11L12.0002 17.0677L18.0679 11L16.5679 9.5L12.0002 14.0677L7.42725 9.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

ExpandArrowIcon.displayName = 'ExpandArrowIcon'
