import { IconComponent } from '@/lib/types'

export const TaskCircularCheckboxHalfStartedIcon: IconComponent = ({
  ...properties
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...properties}
    >
      <path
        fill="currentColor"
        fillOpacity="0.2"
        fillRule="evenodd"
        d="M12 19.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15"
        clipRule="evenodd"
      />

      <g fill="currentColor">
        <path d="M12 19.5a7.5 7.5 0 0 0 0-15z" />
        <path
          fillRule="evenodd"
          d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16m0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10"
          clipRule="evenodd"
        />
      </g>
    </svg>
  )
}

TaskCircularCheckboxHalfStartedIcon.displayName =
  'TaskCircularCheckboxHalfStartedIcon'
