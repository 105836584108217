import { useTaskDetailPageContext } from '@/features/task/hooks/useTaskDetailPageContext'
import {
  CreateMessageInput,
  GetThreadEventsQuery,
  FileMetadata,
} from '@/gql/generated/graphql'
import { useMutation } from '@tanstack/react-query'

type ThreadEvents = GetThreadEventsQuery['tasks'][number]

interface UseCreateChatMessageProperties {
  handleMutate?: (
    variables: CreateMessageInput & {
      fileMetaData?: FileMetadata[]
    }
  ) => Promise<ThreadEvents | undefined>
  handleError?: (
    error: Error,
    _variables: CreateMessageInput,
    previousThread?: ThreadEvents
  ) => unknown
  handleSettled?: () => void
}

export const useCreateChatMessage = ({
  handleError,
  handleMutate,
  handleSettled,
}: UseCreateChatMessageProperties) => {
  const { api } = useTaskDetailPageContext()

  const createMessageMutation = useMutation({
    mutationFn: async ({ fileMetaData, ...apiVariables }) => {
      return await api.createMessage(apiVariables)
    },
    mutationKey: ['tasks', 'threads', 'messages'],
    onError: handleError,
    onMutate: handleMutate,
    onSettled: handleSettled,
  })

  return createMessageMutation
}
