import { JSX } from 'react'

import { FetchAllUsersQuery } from '@/gql/generated/graphql'

interface IChatMessageProperties {
  users: FetchAllUsersQuery['users']
  message: string
}

export const ChatMessage: React.FC<IChatMessageProperties> = ({
  message,
  users,
}) => {
  const userTagRegex =
    /<@([A-Z])([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})>/g
  const messageParts: Array<string | JSX.Element> = []
  let lastIndex = 0
  let match

  while ((match = userTagRegex.exec(message)) !== null) {
    if (match.index > lastIndex) {
      messageParts.push(message.slice(lastIndex, match.index))
    }

    const userId = match[2]
    const taggedUser = users.find((u) => u.id === userId)

    messageParts.push(
      <span key={match.index} className="text-gold">
        {`@${taggedUser?.name}`}
      </span>
    )

    lastIndex = match.index + match[0].length
  }

  if (lastIndex < message.length) {
    messageParts.push(message.slice(Math.max(0, lastIndex)))
  }

  return <>{messageParts}</>
}

ChatMessage.displayName = 'ChatMessage'
