import { BotRobotMachineIcon } from '@/components/icons/BotRobotMachineIcon'
import { Api } from '@/contexts/ApiProvider'
import { createAgentsQueryOptions } from '@/features/team/hooks/useAgents'
import { createFileRoute } from '@tanstack/react-router'

interface TeamLoaderData {
  agents: Awaited<ReturnType<Api['fetchAgents']>>
}

export const Route = createFileRoute('/_private/team')({
  loader: async ({ context }): Promise<TeamLoaderData> => ({
    agents: await context.queryClient.ensureQueryData(
      createAgentsQueryOptions(context)
    ),
  }),
})

const TeamBreadcrumb = () => {
  return (
    <div className="flex items-center gap-2 text-xs-regular text-mono-ink-subtle">
      <BotRobotMachineIcon className="size-4" />
      <p>Team</p>
    </div>
  )
}

TeamBreadcrumb.displayName = 'TeamBreadcrumb'

Route.interloom = {
  Breadcrumb: TeamBreadcrumb,
  defaultPageTitle: 'Team',
  NavigationIcon: BotRobotMachineIcon,
  tooltipText: 'Team',
}
