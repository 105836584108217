import { useCallback } from 'react'

import { MagicIcon } from '@/components/icons/MagicIcon'
import { PlusCircleIcon } from '@/components/icons/PlusCircleIcon'
import { PlusMathIcon } from '@/components/icons/PlusMathIcon'
import { TaskCompletedIcon } from '@/components/icons/TaskCompletedIcon'
import { Button } from '@/components/ui/button/Button'
import Panel from '@/components/ui/panel/Panel'
import { CreateSubtaskForm } from '@/features/task/components/CreateSubtaskForm'
import { SubtasksList } from '@/features/task/components/subtasks/SubtasksList'
import { TaskData } from '@/features/task/hooks/useTask'
import { CreateTaskInput, RefineTaskInput } from '@/gql/generated/graphql'
import { useBoolean } from '@/lib/hooks/useBoolean'
import { cn } from '@/lib/utilities'

type SubtaskData = TaskData['subtasks'][number]

interface SubtasksContainerProperties {
  isRefiningTask: boolean
  onRefineTask: (input?: Omit<RefineTaskInput, 'taskId'>) => void
  onSaveSubtask: (data: CreateTaskInput) => void
  subtasks: SubtaskData[]
  taskId: string
}

export const SubtasksContainer = ({
  isRefiningTask,
  onRefineTask,
  onSaveSubtask,
  subtasks,
  taskId,
}: SubtasksContainerProperties) => {
  const {
    setFalse: setNotCreatingNewTask,
    setTrue: setCreatingNewTask,
    value: isCreatingNewTask,
  } = useBoolean()

  const handleGroundedPlanning = useCallback(() => {
    onRefineTask()
  }, [onRefineTask])

  return (
    <Panel.Root
      className={cn({
        'border-indigo-300 bg-gradient-indigo': isRefiningTask,
      })}
    >
      <Panel.Top>
        <Panel.Title>Tasks</Panel.Title>
        <Panel.Button
          data-testid="button-add-subtask-top"
          onClick={setCreatingNewTask}
        >
          <PlusMathIcon className="size-6" />
        </Panel.Button>
      </Panel.Top>

      <Panel.Content className={cn({ 'bg-mono-paper': !isRefiningTask })}>
        {subtasks.length > 0 && (
          <SubtasksList taskId={taskId} subtasks={subtasks} />
        )}

        {subtasks.length <= 0 && !isCreatingNewTask && (
          <>
            <div className="flex h-[180px] items-center justify-center gap-2 text-mono-ink-subtle">
              <TaskCompletedIcon className="size-6" />
              <div className="text-md-regular">No Tasks</div>
            </div>
            <div className="mx-6 h-[1px] bg-black/5" />
            <div className="flex items-center justify-start gap-3 px-6 py-3">
              <Button
                className="flex-1 border hover:shadow-sm focus:border-sky-500 focus:bg-sky-100 focus:text-sky-600 focus:shadow-sm active:bg-sky-100 active:opacity-75"
                data-testid="button-add-subtask"
                onClick={setCreatingNewTask}
                size={'sm'}
              >
                <PlusMathIcon className="size-4" />
                <span>Create new task</span>
              </Button>
              <Button
                className="flex-1 border hover:shadow-sm focus:border-sky-500 focus:bg-sky-100 focus:text-sky-600 focus:shadow-sm active:bg-sky-100 active:opacity-75"
                data-testid="button-plan-automatically"
                onClick={handleGroundedPlanning}
                size={'sm'}
              >
                <MagicIcon className="size-6" />
                <span>Plan automatically</span>
              </Button>
            </div>
          </>
        )}
      </Panel.Content>

      {isCreatingNewTask && (
        <CreateSubtaskForm
          onCloseForm={setNotCreatingNewTask}
          saveSubtask={onSaveSubtask}
        />
      )}

      {subtasks.length > 0 && !isCreatingNewTask && (
        <div className="animate-fadeIn flex items-center justify-start pt-4 pb-2">
          <Button
            data-testid="button-add-subtask"
            onClick={setCreatingNewTask}
            variant={'subtle'}
            size="sm"
          >
            <PlusCircleIcon className="size-5" />
            Create new task
          </Button>
        </div>
      )}
    </Panel.Root>
  )
}

SubtasksContainer.displayName = 'SubtasksContainer'
