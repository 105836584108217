import { ComponentProps, FC, PropsWithChildren } from 'react'

import { Tooltip } from '@/components/ui/tooltip/Tooltip'
import { cn } from '@/lib/utilities'
import { Link } from '@tanstack/react-router'

interface Properties extends PropsWithChildren<ComponentProps<typeof Link>> {
  tooltipText?: string
  activeIndicatorClassName?: string
}

export const NavigationItem: FC<Properties> = ({
  activeIndicatorClassName,
  children,
  to,
  tooltipText,
  ...properties
}) => {
  return (
    <Tooltip
      content={tooltipText}
      side="left"
      data-testid={`NavigationItemTooltip-${to}`}
    >
      <div
        className="relative h-11 w-13.5"
        data-testid={`NavigationItemContainer-${to}`}
      >
        <Link
          className="group relative flex h-full w-full items-center justify-center rounded-md border border-film-strong hover:border-film-strongest hover:bg-film-normal"
          to={to}
          {...properties}
        >
          <span>{children}</span>
          <span
            className={cn(
              "pointer-events-none absolute -right-3.75 h-full w-1.25 rounded-full bg-mono-ink-strong opacity-0 transition-opacity group-data-[status='active']:opacity-100",
              activeIndicatorClassName
            )}
          />
        </Link>
      </div>
    </Tooltip>
  )
}
NavigationItem.displayName = 'NavigationItem'
