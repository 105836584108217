import {
  TaskThreadEvent,
  TaskThreadEventPayloadAgentInvocation,
  TaskThreadEventPayloadFileMetadata,
  TaskThreadEventPayloadImageFileMetadata,
  TaskThreadEventPayloadMessage,
  TaskThreadEventPayloadTaskUpdate,
} from '@/features/task/components/chat/threadPayloadTypes'
import { isAudioFile, isVideoFile } from '@/lib/utilities'

export const processPayloads = (payloads: TaskThreadEvent['payload']) => {
  const result: {
    imgFileMetadata: TaskThreadEventPayloadImageFileMetadata[]
    otherFileMetadata: TaskThreadEventPayloadFileMetadata[]
    videoFileMetadata: TaskThreadEventPayloadFileMetadata[]
    audioFileMetadata: TaskThreadEventPayloadFileMetadata[]
    message?: TaskThreadEventPayloadMessage
    taskUpdate?: TaskThreadEventPayloadTaskUpdate
    agentInvocation?: TaskThreadEventPayloadAgentInvocation
  } = {
    audioFileMetadata: [],
    imgFileMetadata: [],
    otherFileMetadata: [],
    videoFileMetadata: [],
  }

  for (const payload of payloads) {
    switch (payload.__typename) {
      case 'FileMetadata': {
        if (isVideoFile(payload)) {
          result.videoFileMetadata.push(payload)
          break
        }
        if (isAudioFile(payload)) {
          result.audioFileMetadata.push(payload)
          break
        }

        result.otherFileMetadata.push(payload)
        break
      }
      case 'ImageFileMetadata': {
        result.imgFileMetadata.push(payload)
        break
      }
      case 'Message': {
        result.message = payload
        break
      }
      case 'TaskUpdate': {
        result.taskUpdate = payload
        break
      }
      case 'AgentInvocation': {
        result.agentInvocation = payload
        break
      }
    }
  }

  return result
}
