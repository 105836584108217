import debounce from 'lodash/debounce'
import isNumber from 'lodash/isNumber'
import noop from 'lodash/noop'
import { useState, useCallback, useMemo, useRef, useEffect } from 'react'

import { ListItem } from '@/components/ui/list/ListItem'
import { useList } from '@/components/ui/list/useList'
import { useDocumentKeyCapture } from '@/lib/hooks/useDocumentKeyCapture'
import { usePasteEvent } from '@/lib/hooks/usePasteEvent'
import { cn, isMac } from '@/lib/utilities'
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

const deleteReferenceKeys = ['Meta', 'Backspace']
const macCopyTaskUrlKeys = ['Meta', 'c']
const windowsCopyTaskUrlKeys = ['Control', 'c']
const macContextMenuKeyCombination = ['Meta', 'k']
const otherContextMenuKeyCombination = ['Control', 'k']

interface ListProperties<T extends { id: string }> {
  disableKeyboardInteraction?: boolean
  containerClassName?: string
  preserveHighlight?: boolean
  initialHighlightedItem?: string
  items: T[]
  renderItem: (parameters: {
    contextMenuOpen: boolean
    deleteDialogOpen: boolean
    item: T
    itemsIds: string[]
    focused: boolean
    onDeleteDialogOpen: () => void
    selected: boolean
    selectedItemIds: string[]
  }) => React.ReactNode
  sortable?: boolean
  onCopy?: (item: T) => void
  onPaste?: (event: ClipboardEvent, item?: T) => void
  onSortEnd?: (parameters: {
    items: T[]
    activeIndex: number
    overIndex: number
  }) => void
  onSortEndDebounceMs?: number
  renderContextMenu?: (
    item: T,
    onDeleteDialogOpen: () => void
  ) => React.ReactNode
  renderDeleteItem?: (parameters: {
    focusedIndex: number
    isOpen: boolean
    selectedItems: T[]
    onClose: () => void
  }) => React.ReactNode
  testId?: string
}

export const List = <T extends { id: string }>({
  containerClassName,
  disableKeyboardInteraction,
  initialHighlightedItem,
  items,
  onCopy,
  onPaste = noop,
  onSortEnd = noop,
  onSortEndDebounceMs,
  preserveHighlight,
  renderContextMenu,
  renderDeleteItem,
  renderItem,
  sortable = false,
  testId,
}: ListProperties<T>) => {
  const [listItems, setListItems] = useState<T[]>(items)
  const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    setListItems(items)
  }, [items])

  const containerReference = useRef<HTMLUListElement>(null)
  const itemsReference = useRef<Array<HTMLLIElement | null>>([])

  const itemIds = useMemo(() => listItems.map((item) => item.id), [listItems])

  const {
    getItemProperties,
    handleKeyDown: hookKeyDown,
    resetFocus,
    resetHighlightRange,
    selectedItems: selectedItemIds,
    setFocus,
    state: highlightState,
  } = useList(itemIds, initialHighlightedItem)

  const focusedIndex = useMemo(() => {
    return listItems.findIndex(
      (item) => item.id === highlightState.highlightCursor
    )
  }, [listItems, highlightState.highlightCursor])

  const selectedItems = useMemo(() => {
    return listItems.filter((item) => selectedItemIds.includes(item.id))
  }, [listItems, selectedItemIds])

  const handleOnSortEnd = useMemo(() => {
    if (isNumber(onSortEndDebounceMs)) {
      return debounce((items: T[], activeIndex: number, overIndex: number) => {
        setListItems(items)
        onSortEnd({ activeIndex, items, overIndex })
      }, onSortEndDebounceMs)
    }

    return (items: T[], activeIndex: number, overIndex: number) => {
      setListItems(items)
      onSortEnd({ activeIndex, items, overIndex })
    }
  }, [onSortEndDebounceMs, onSortEnd])

  const handleContainerKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLUListElement>) => {
      if (contextMenuOpen || disableKeyboardInteraction) return

      const isArrowDown = event.key === 'ArrowDown'
      const isArrowUp = event.key === 'ArrowUp'
      const isSortAction = event.ctrlKey || event.metaKey

      if ((isArrowDown || isArrowUp) && isSortAction && sortable) {
        event.preventDefault()
        const currentId = highlightState.highlightCursor
        const currentIndex = listItems.findIndex(
          (item) => item.id === currentId
        )
        if (currentIndex === -1) return

        let newIndex: number

        if (event.shiftKey) {
          newIndex = isArrowUp ? 0 : listItems.length - 1
        } else {
          const direction = isArrowDown ? 1 : -1
          newIndex = currentIndex + direction
        }
        if (newIndex < 0 || newIndex >= listItems.length) return
        const newItems = arrayMove(listItems, currentIndex, newIndex)
        setListItems(newItems)
        handleOnSortEnd(newItems, currentIndex, newIndex)
        setFocus(newItems[newIndex]?.id)
      } else {
        hookKeyDown(event)
      }
    },
    [
      contextMenuOpen,
      disableKeyboardInteraction,
      highlightState.highlightCursor,
      listItems,
      handleOnSortEnd,
      setFocus,
      hookKeyDown,
      sortable,
    ]
  )

  const handleContainerBlur = useCallback(
    (event: React.FocusEvent<HTMLUListElement>) => {
      if (!containerReference.current?.contains(event.relatedTarget)) {
        resetFocus()
      }
    },
    [resetFocus]
  )

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (over && active.id !== over.id) {
        const oldIndex = listItems.findIndex((item) => item.id === active.id)
        const newIndex = listItems.findIndex((item) => item.id === over.id)

        if (oldIndex !== -1 && newIndex !== -1) {
          const newItems = arrayMove(listItems, oldIndex, newIndex)
          setListItems(newItems)
          handleOnSortEnd(newItems, oldIndex, newIndex)

          const movedItemIndex = newItems.findIndex(
            (item) => item.id === active.id
          )
          setFocus(newItems[movedItemIndex]?.id)
        }
      }
    },
    [handleOnSortEnd, listItems, setFocus]
  )

  const handleContextMenuOpen = useCallback(() => {
    const target = itemsReference.current[focusedIndex]
    if (target) {
      target.dispatchEvent(
        new MouseEvent('contextmenu', {
          bubbles: true,
          clientX: target.getBoundingClientRect().x,
          clientY:
            target.getBoundingClientRect().height +
            target.getBoundingClientRect().y,
        })
      )
    }
  }, [focusedIndex])

  useDocumentKeyCapture(
    isMac() ? macContextMenuKeyCombination : otherContextMenuKeyCombination,
    () => {
      if (containerReference.current?.contains(document.activeElement)) {
        handleContextMenuOpen()
      }
    },
    renderContextMenu &&
      selectedItemIds.length === 1 &&
      focusedIndex >= 0 &&
      !disableKeyboardInteraction
  )

  useDocumentKeyCapture(
    isMac() ? deleteReferenceKeys : 'Delete',
    () => {
      if (containerReference.current?.contains(document.activeElement)) {
        setDeleteDialogOpen(true)
      }
    },
    renderDeleteItem &&
      focusedIndex >= 0 &&
      !contextMenuOpen &&
      selectedItems.length > 0 &&
      !disableKeyboardInteraction
  )

  useDocumentKeyCapture(
    isMac() ? macCopyTaskUrlKeys : windowsCopyTaskUrlKeys,
    () => {
      const item = listItems[focusedIndex]
      if (
        item &&
        containerReference.current?.contains(document.activeElement)
      ) {
        onCopy?.(item)
      }
    },
    onCopy &&
      focusedIndex >= 0 &&
      !contextMenuOpen &&
      selectedItems.length === 1 &&
      !disableKeyboardInteraction
  )

  usePasteEvent(
    (event) => {
      onPaste(event, listItems[focusedIndex])
    },
    !!onPaste &&
      focusedIndex >= 0 &&
      !contextMenuOpen &&
      selectedItems.length === 1 &&
      !disableKeyboardInteraction
  )

  const handleDeleteDialogOpen = useCallback(() => {
    setDeleteDialogOpen(true)
  }, [setDeleteDialogOpen])

  const listContent = useMemo(
    () => (
      <ul
        ref={containerReference}
        onKeyDown={handleContainerKeyDown}
        onBlur={
          deleteDialogOpen || contextMenuOpen || preserveHighlight
            ? undefined
            : handleContainerBlur
        }
        className={cn('outline-none', containerClassName)}
        role="listbox"
        aria-multiselectable="true"
        data-testid={testId}
      >
        {listItems.map((item, index) => (
          <ListItem
            contextMenuOpen={contextMenuOpen}
            deleteDialogOpen={deleteDialogOpen}
            key={item.id}
            index={index}
            item={item}
            itemsIds={itemIds}
            onDeleteDialogOpen={handleDeleteDialogOpen}
            sortable={sortable}
            selectedItemIds={selectedItemIds}
            highlightState={highlightState}
            getItemProperties={getItemProperties}
            renderItem={renderItem}
            renderContextMenu={renderContextMenu}
            setContextMenuOpen={setContextMenuOpen}
            itemsReference={itemsReference}
          />
        ))}
      </ul>
    ),
    [
      containerClassName,
      containerReference,
      contextMenuOpen,
      deleteDialogOpen,
      getItemProperties,
      highlightState,
      handleContainerBlur,
      handleContainerKeyDown,
      handleDeleteDialogOpen,
      itemIds,
      listItems,
      preserveHighlight,
      renderItem,
      renderContextMenu,
      selectedItemIds,
      sortable,
      setContextMenuOpen,
      testId,
    ]
  )

  const dndModifiers = useMemo(
    () => [restrictToVerticalAxis, restrictToParentElement],
    []
  )
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  )

  return (
    <>
      {sortable ? (
        <DndContext
          sensors={sensors}
          modifiers={dndModifiers}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            strategy={verticalListSortingStrategy}
            items={listItems.map((item) => item.id)}
          >
            {listContent}
          </SortableContext>
        </DndContext>
      ) : (
        listContent
      )}

      {deleteDialogOpen &&
        renderDeleteItem?.({
          focusedIndex,
          isOpen: deleteDialogOpen,
          onClose: () => {
            if (listItems[focusedIndex]) {
              itemsReference.current[focusedIndex]?.focus()
              resetHighlightRange()
            } else if (listItems[0]) {
              itemsReference.current[0]?.focus()
              setFocus(listItems[0].id)
            }
            setDeleteDialogOpen(false)
          },
          selectedItems,
        })}
    </>
  )
}

List.displayName = 'List'
