import { useCallback, useMemo, FC, JSX } from 'react'

import { CaretDownIcon } from '@/components/icons/CaretDownIcon'
import { CheckmarkDoneIcon } from '@/components/icons/CheckmarkDoneIcon'
import { CrossLargeIcon } from '@/components/icons/CrossLargeIcon'
import { Button } from '@/components/ui/button/Button'
import { Casing } from '@/components/ui/casing/Casing'
import { Form } from '@/components/ui/form/Form'
import { InputField } from '@/components/ui/input-field/InputField'
import * as PopoverPrimitive from '@/components/ui/popover/Popover'
import { useBoolean } from '@/lib/hooks/useBoolean'
import { cn } from '@/lib/utilities'

interface EditableTitleProperties {
  defaultTitle: string
  titleOnSubmit: (title: string) => void
  icon: JSX.Element
  label: string
  className?: string
}

export const EditableTitle: FC<EditableTitleProperties> = ({
  className,
  defaultTitle,
  icon,
  label,
  titleOnSubmit,
}) => {
  const popoverVisibility = useBoolean(false)
  const defaultValues = useMemo(
    () => ({
      title: defaultTitle,
    }),
    [defaultTitle]
  )

  const handleSubmit = useCallback(
    (formData: typeof defaultValues) => {
      if (formData.title && formData.title !== defaultTitle) {
        titleOnSubmit(formData.title)
      }
      popoverVisibility.setFalse()
    },
    [popoverVisibility, defaultTitle, titleOnSubmit]
  )

  return (
    <div className={cn('flex flex-row py-2', className)}>
      <Casing className="grow items-center" size="large" variant="naked">
        <PopoverPrimitive.Popover
          onOpenChange={popoverVisibility.toggle}
          open={popoverVisibility.value}
        >
          <PopoverPrimitive.PopoverTrigger
            className="flex items-center gap-2 rounded-sm border border-transparent hover:border-film-subtle hover:bg-film-subtle focus-visible:border-film-subtle focus-visible:bg-film-subtle active:bg-film-stronger"
            data-testid="update-title-popover-trigger"
          >
            {icon}
            <h1
              className="block grow text-left text-md-bold"
              data-testid="title"
            >
              {defaultTitle}
            </h1>
            <CaretDownIcon className="min-w-4" />
          </PopoverPrimitive.PopoverTrigger>
          <PopoverPrimitive.PopoverPortal>
            <PopoverPrimitive.PopoverContent className="z-50">
              <p className="mb-1 border-b border-film-subtle px-2 pb-1 text-xs-bold text-mono-ink-subtle">
                {label}
              </p>

              <Form
                defaultValues={defaultValues}
                onSubmit={handleSubmit}
                onCmdEnterKey={handleSubmit}
              >
                <InputField
                  className="h-8 w-full rounded-md border border-transparent p-2 text-sm-regular outline-hidden focus-visible:border-blue-ink-subtle"
                  data-testid="update-title-input"
                  name="title"
                />

                <div className="my-1 flex justify-end gap-1">
                  <Button
                    className="h-7 min-h-7 w-9 min-w-9"
                    data-testid="update-title-popover-cancel-button"
                    onClick={popoverVisibility.setFalse}
                  >
                    <CrossLargeIcon className="size-5 min-h-5 min-w-5" />
                  </Button>

                  <Button
                    className="h-7 min-h-7 w-9 min-w-9"
                    data-testid="update-title-popover-submit-button"
                    type="submit"
                    variant="solid"
                  >
                    <CheckmarkDoneIcon className="size-5 min-h-5 min-w-5" />
                  </Button>
                </div>
              </Form>
            </PopoverPrimitive.PopoverContent>
          </PopoverPrimitive.PopoverPortal>
        </PopoverPrimitive.Popover>
      </Casing>
    </div>
  )
}

EditableTitle.displayName = 'EditableTitle'
