import { useCallback, useState } from 'react'
import { v7 as uuid } from 'uuid'

import { Button } from '@/components/ui/button/Button'
import { Form } from '@/components/ui/form/Form'
import { InputField } from '@/components/ui/input-field/InputField'
import { TaskIcon } from '@/features/task/components/TaskIcon'
import { CreateTaskInput } from '@/gql/generated/graphql'

export interface CreateSubtaskFormProperties {
  saveSubtask: (data: CreateTaskInput) => void
  onCloseForm: () => void
}

export const CreateSubtaskForm = ({
  onCloseForm,
  saveSubtask,
}: CreateSubtaskFormProperties) => {
  const [formKey, setFormKey] = useState(uuid())
  const handleSave = useCallback(
    (
      data?: Record<string, any>,
      event?: React.SyntheticEvent,
      closeFormAfterSave = false
    ) => {
      event?.preventDefault()
      setFormKey(uuid())
      saveSubtask(data as CreateTaskInput)
      if (closeFormAfterSave) {
        onCloseForm()
      }
    },
    [onCloseForm, saveSubtask]
  )

  const handleClick = useCallback(
    (data?: Record<string, any>) => {
      handleSave(data, undefined, true)
    },
    [handleSave]
  )

  return (
    <div className="mt-2 flex flex-col">
      <Form
        className="rounded-lg bg-white p-2.5"
        onClickAway={handleClick}
        onEscapeKey={onCloseForm}
        onSubmit={handleSave}
        onCmdEnterKey={handleSave}
        tabIndex={-1}
        key={formKey}
      >
        <div className="flex flex-row gap-2">
          <div className="rounded-md bg-sky-100 p-1">
            <TaskIcon size="md" status="OPEN" className="text-sky-500" />
          </div>

          <InputField
            name="title"
            autoFocus
            className="grow caret-sky-700 outline-0"
            data-testid="subtask-title-input"
            placeholder="Enter task title"
          />
        </div>
        <div className="flex flex-row gap-2">
          <div className="grow" />
          <Button
            data-testid="button-add-subtask-cancel"
            onClick={onCloseForm}
            type="button"
            size={'sm'}
          >
            <span>Discard</span>
            <span className="rounded-sm bg-film-normal p-1">ESC</span>
          </Button>
          <Button
            data-testid="button-add-subtask-submit"
            type="submit"
            variant={'solid'}
            size={'sm'}
          >
            <span>Save</span>
          </Button>
        </div>
      </Form>
    </div>
  )
}

CreateSubtaskForm.displayName = 'CreateSubtaskForm'
