import { useCallback, FC, PropsWithChildren } from 'react'

import { PlusMathIcon } from '@/components/icons/PlusMathIcon'
import { Button, ButtonProperties } from '@/components/ui/button/Button'
import { useCreateTask } from '@/features/task/hooks/useCreateTask'
import { useViewer } from '@/lib/hooks/useViewer'
import { useMatchRoute } from '@tanstack/react-router'

interface CreateTaskButtonProperties
  extends ButtonProperties,
    PropsWithChildren {
  assignCurrentUser?: boolean
}

export const CreateTaskButton: FC<CreateTaskButtonProperties> = ({
  assignCurrentUser,
  children,
  ...rest
}) => {
  const { viewer } = useViewer()

  const matchRoute = useMatchRoute()
  const spacesMatch = matchRoute({ to: '/spaces/$spaceId' })
  const { mutate: createTask } = useCreateTask({
    ...(spacesMatch && { spaceId: spacesMatch.spaceId }),
  })

  const createNewTask = useCallback(() => {
    const taskData = {
      ...(assignCurrentUser && { assigneeId: viewer.id }),
      ...(spacesMatch && { spaceId: spacesMatch.spaceId }),
      title: 'New Task',
    }

    createTask(taskData)
  }, [assignCurrentUser, createTask, spacesMatch, viewer.id])

  return (
    <Button onClick={createNewTask} data-testid="button-plus-item" {...rest}>
      <PlusMathIcon />
      {children}
    </Button>
  )
}

CreateTaskButton.displayName = 'CreateTaskButton'
