import noop from 'lodash/noop'
import { createContext, useContext, useMemo } from 'react'

const ChatOverlayContext = createContext<{
  isChatOverlayVisible: boolean
  showChatOverlay: () => void
  hideChatOverlay: () => void
}>({
  hideChatOverlay: noop,
  isChatOverlayVisible: false,
  showChatOverlay: noop,
})

ChatOverlayContext.displayName = 'ChatOverlayContext'

export const useChatOverlay = () => useContext(ChatOverlayContext)

export const ChatOverlayProvider = ({
  children,
  hideChatOverlay,
  isChatOverlayVisible,
  showChatOverlay,
}: {
  children: React.ReactNode
  isChatOverlayVisible: boolean
  showChatOverlay: () => void
  hideChatOverlay: () => void
}) => {
  const value = useMemo(
    () => ({
      hideChatOverlay,
      isChatOverlayVisible,
      showChatOverlay,
    }),
    [hideChatOverlay, isChatOverlayVisible, showChatOverlay]
  )

  return (
    <ChatOverlayContext.Provider value={value}>
      {children}
    </ChatOverlayContext.Provider>
  )
}

ChatOverlayProvider.displayName = 'ChatOverlayProvider'
