import { FC, ComponentProps } from 'react'

import { Button } from '@/components/ui/button/Button'
import { cn } from '@/lib/utilities'

const PanelRoot: FC<ComponentProps<'div'>> = ({
  children,
  className,
  ref,
  ...rest
}) => (
  <div
    ref={ref}
    className={cn(
      'rounded-xl border border-transparent bg-film-subtle p-2',
      className
    )}
    {...rest}
  >
    {children}
  </div>
)
PanelRoot.displayName = 'PanelRoot'

const PanelTop: FC<ComponentProps<'div'>> = ({
  children,
  className,
  ref,
  ...rest
}) => (
  <div
    ref={ref}
    className={cn('flex items-center justify-between', className)}
    {...rest}
  >
    {children}
  </div>
)
PanelTop.displayName = 'PanelTop'

const PanelTitle: FC<ComponentProps<'p'>> = ({
  children,
  className,
  ref,
  ...rest
}) => (
  <p
    ref={ref}
    className={cn(
      'px-2 text-xs font-medium text-mono-ink-subtle uppercase',
      className
    )}
    {...rest}
  >
    {children}
  </p>
)
PanelTitle.displayName = 'PanelTitle'

export const PanelButton: FC<ComponentProps<typeof Button>> = ({
  children,
  className,
  ref,
  ...rest
}) => (
  <Button
    ref={ref}
    className={cn(
      'w-8 border hover:shadow-sm focus:border-sky-500 focus:bg-sky-100 focus:text-sky-600 focus:shadow-sm active:bg-sky-100 active:opacity-75',
      className
    )}
    size="sm"
    variant="subtle"
    {...rest}
  >
    {children}
  </Button>
)
PanelButton.displayName = 'PanelButton'

const PanelContent: FC<ComponentProps<'div'>> = ({
  children,
  className,
  ref,
  ...rest
}) => (
  <div
    ref={ref}
    className={cn('mt-2 flex flex-col rounded-lg', className)}
    {...rest}
  >
    {children}
  </div>
)
PanelContent.displayName = 'PanelContent'

const Panel = {
  Button: PanelButton,
  Content: PanelContent,
  Root: PanelRoot,
  Title: PanelTitle,
  Top: PanelTop,
}

export default Panel
