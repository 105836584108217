import React, { useReducer, useEffect, useCallback, useMemo } from 'react'

interface HighlightState {
  items: string[]
  highlightCursor?: string
  highlightRange?: number
}

type Action =
  | { type: 'init'; payload: string[] }
  | { type: 'update'; payload: string[] }
  | { type: 'setFocus'; payload?: string }
  | { type: 'arrowDown' }
  | { type: 'arrowUp' }
  | { type: 'shiftArrowDown' }
  | { type: 'shiftArrowUp' }
  | { type: 'resetHighlightRange' }
  | { type: 'resetFocus' }

const listReducer = (state: HighlightState, action: Action): HighlightState => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        highlightCursor: action.payload[0] ?? undefined,
        highlightRange: 0,
        items: action.payload,
      }
    }
    case 'update': {
      return {
        ...state,
        items: action.payload,
      }
    }

    case 'setFocus': {
      if (state.highlightCursor === action.payload) {
        return state
      }
      return {
        ...state,
        highlightCursor: action.payload,
        highlightRange: 0,
      }
    }
    case 'arrowDown': {
      if (!state.highlightCursor) return state
      const index = state.items.indexOf(state.highlightCursor)
      if (index === -1 || index >= state.items.length - 1) return state
      return {
        ...state,
        highlightCursor: state.items[index + 1],
        highlightRange: 0,
      }
    }
    case 'arrowUp': {
      if (!state.highlightCursor) return state
      const index = state.items.indexOf(state.highlightCursor)
      if (index <= 0) return state
      return {
        ...state,
        highlightCursor: state.items[index - 1],
        highlightRange: 0,
      }
    }
    case 'shiftArrowDown': {
      if (!state.highlightCursor || state.highlightRange === undefined)
        return state
      const index = state.items.indexOf(state.highlightCursor)
      const newRange = state.highlightRange + 1
      if (index + newRange >= state.items.length) return state
      return {
        ...state,
        highlightRange: newRange,
      }
    }
    case 'shiftArrowUp': {
      if (!state.highlightCursor || state.highlightRange === undefined)
        return state
      const index = state.items.indexOf(state.highlightCursor)
      const newRange = state.highlightRange - 1
      if (index + newRange < 0) return state
      return {
        ...state,
        highlightRange: newRange,
      }
    }
    case 'resetHighlightRange': {
      return {
        ...state,
        highlightRange: 0,
      }
    }

    case 'resetFocus': {
      return {
        ...state,
        highlightCursor: undefined,
        highlightRange: undefined,
      }
    }
    default: {
      return state
    }
  }
}

const getSelectedItems = (state: HighlightState): string[] => {
  if (!state.highlightCursor || state.highlightRange === undefined) return []
  const index = state.items.indexOf(state.highlightCursor)
  const range = state.highlightRange
  const start = index
  const end = index + range
  const min = Math.min(start, end)
  const max = Math.max(start, end)
  return state.items.slice(min, max + 1)
}

export const useList = (items: string[], initialHighlightedItem?: string) => {
  const [state, dispatch] = useReducer(listReducer, {
    highlightCursor: undefined,
    highlightRange: 0,
    items,
  })

  useEffect(() => {
    dispatch({ payload: items, type: 'update' })
    if (initialHighlightedItem) {
      dispatch({ payload: initialHighlightedItem, type: 'setFocus' })
    }
  }, [initialHighlightedItem, items])

  const selectedItems = useMemo(() => getSelectedItems(state), [state])

  const setFocus = useCallback((id?: string) => {
    dispatch({ payload: id, type: 'setFocus' })
  }, [])

  const resetHighlightRange = useCallback(() => {
    dispatch({ type: 'resetHighlightRange' })
  }, [])

  const resetFocus = useCallback(() => {
    dispatch({ type: 'resetFocus' })
  }, [])

  const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      if (event.shiftKey) {
        dispatch({ type: 'shiftArrowDown' })
      } else {
        dispatch({ type: 'arrowDown' })
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      if (event.shiftKey) {
        dispatch({ type: 'shiftArrowUp' })
      } else {
        dispatch({ type: 'arrowUp' })
      }
    }
  }, [])

  const getItemProperties = useCallback(
    (id: string) => {
      return {
        isHighlighted:
          state.highlightCursor === id || selectedItems.includes(id),
        onClick: () => {
          setFocus(id)
        },
        onFocus: (event: React.FocusEvent<HTMLElement>) => {
          if (event.target === document.activeElement) {
            setFocus(id)
          }
        },
        ref: (element: HTMLElement | null) => {
          if (
            state.highlightCursor === id &&
            element &&
            document.activeElement !== element
          ) {
            element.focus()
          }
        },
      }
    },
    [selectedItems, setFocus, state.highlightCursor]
  )

  return {
    getItemProperties,
    handleKeyDown,
    resetFocus,
    resetHighlightRange,
    selectedItems,
    setFocus,
    state,
  }
}
