import { TaskTodoStackIcon } from '@/components/icons/TaskTodoStackIcon'
import { createSpacesQueryOptions } from '@/features/spaces/hooks/useSpaces'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_private/spaces/')({
  loader: async ({ context }) => ({
    spaces: await context.queryClient.ensureQueryData(
      createSpacesQueryOptions({ api: context.api })
    ),
  }),
})

Route.interloom = {
  defaultPageTitle: 'Spaces',
  NavigationIcon: TaskTodoStackIcon,
  tooltipText: 'Spaces',
}
