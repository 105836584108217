import { ComponentType, FC } from 'react'

import { CancelCrossFilledStatusIcon } from '@/components/icons/CancelCrossFilledStatusIcon'
import { CheckedCircleSolidIcon } from '@/components/icons/CheckedCircleSolidIcon'
import { TaskCircularCheckboxHalfStartedIcon } from '@/components/icons/TaskCircularCheckboxHalfStartedIcon'
import { TaskUncheckedCircleSolidIcon } from '@/components/icons/TaskUncheckedCircleSolidIcon'
import { cn } from '@/lib/utilities'
import { TaskStatus } from '@/tests/generated-api-types'

export interface Properties {
  className?: string
  status?: TaskStatus
  size?: 'md' | 'lg' | 'sm'
}

export const taskStatusIcons: Record<
  TaskStatus,
  {
    component: ComponentType<{ className?: string }>
    colorClass: string
  }
> = {
  CANCELLED: {
    colorClass: 'text-ink-subtle',
    component: CancelCrossFilledStatusIcon,
  },
  COMPLETED: {
    colorClass: 'text-completed-task',
    component: CheckedCircleSolidIcon,
  },
  OPEN: {
    colorClass: 'text-posca-contrast',
    component: TaskUncheckedCircleSolidIcon,
  },
  STARTED: {
    colorClass: 'text-started-task',
    component: TaskCircularCheckboxHalfStartedIcon,
  },
}

export const TaskIcon: FC<Properties> = ({
  className,
  size = 'lg',
  status,
}) => {
  const sizeClass = cn({
    'size-4 min-w-4 min-h-4': size === 'sm',
    'size-6 min-w-6 min-h-6': size === 'md',
    'size-8 min-w-8 min-h-8': size === 'lg',
  })

  const IconComponent = status
    ? taskStatusIcons[status].component
    : TaskUncheckedCircleSolidIcon

  const colorClass = status && taskStatusIcons[status].colorClass

  return <IconComponent className={cn(sizeClass, colorClass, className)} />
}

TaskIcon.displayName = 'TaskIcon'
