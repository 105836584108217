import { useCallback } from 'react'

import { stackSearchParameters, StackType } from '@/components/layout/Stack'
import { useNavigate, useRouter } from '@tanstack/react-router'

export const useCloseStackItem = () => {
  const router = useRouter()
  const navigate = useNavigate()

  const closeStackItem = useCallback(
    (idToClose: string, stackType: StackType) => {
      const activeSearchParameter = stackSearchParameters[stackType]
      const { [activeSearchParameter]: activeSearchValue, ...rest } =
        router.state.location.search

      const updatedSearchParameter = activeSearchValue?.filter(
        (id) => id !== idToClose
      )

      void navigate({
        search: updatedSearchParameter?.length
          ? { ...rest, [activeSearchParameter]: updatedSearchParameter }
          : rest,
        to: '.',
      })
    },
    [navigate, router.state.location]
  )

  return { closeStackItem }
}
