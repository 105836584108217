import { FC, MouseEvent } from 'react'

import { TaskThreadEventPayloadImageFileMetadata } from '@/features/task/components/chat/threadPayloadTypes'
import { getApiHost } from '@/lib/getApiHost'
import { cn } from '@/lib/utilities'

const MAX_IMAGE_HEIGHT = 235
const MAX_IMAGE_WIDTH = 380
const MIN_IMAGE_SIZE = 100
interface ImageMessagePayloadProperties {
  imageFiles: TaskThreadEventPayloadImageFileMetadata[]
}

const scaleToAspectRatio = ({
  originalHeight,
  originalWidth,
}: {
  originalHeight: number
  originalWidth: number
}) => {
  if (originalWidth === 0 || originalHeight === 0) {
    return { height: MIN_IMAGE_SIZE, width: MIN_IMAGE_SIZE }
  }

  const aspectRatio = originalWidth / originalHeight
  let targetWidth = Math.max(
    MIN_IMAGE_SIZE,
    Math.min(MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT * aspectRatio)
  )
  let targetHeight = targetWidth / aspectRatio

  if (targetHeight > MAX_IMAGE_HEIGHT) {
    targetHeight = MAX_IMAGE_HEIGHT
    targetWidth = MAX_IMAGE_HEIGHT * aspectRatio
  } else if (targetHeight < MIN_IMAGE_SIZE) {
    targetHeight = MIN_IMAGE_SIZE
    targetWidth = MIN_IMAGE_SIZE * aspectRatio
  }

  return { height: targetHeight, width: targetWidth }
}

const handleContextMenu = (event: MouseEvent<HTMLImageElement>) => {
  event.stopPropagation()
}

export const ImageMessagePayload: FC<ImageMessagePayloadProperties> = ({
  imageFiles,
}) => {
  return (
    <div className="mb-2 flex flex-wrap gap-2">
      {imageFiles.map((file) => {
        const { height, width } = scaleToAspectRatio({
          originalHeight: file.height,
          originalWidth: file.width,
        })

        return (
          <img
            key={file.id}
            src={getApiHost() + file.fileUrl}
            alt={file.name}
            className={cn(`rounded-md object-cover`, {
              'object-fill':
                height === MIN_IMAGE_SIZE && width === MIN_IMAGE_SIZE,
            })}
            height={height}
            width={width}
            onContextMenu={handleContextMenu}
          />
        )
      })}
    </div>
  )
}

ImageMessagePayload.displayName = 'ImageMessagePayload'
