import { IconComponent } from '@/lib/types'

export const MailEnvelopeIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...properties}
    >
      <path
        fill="inherit"
        d="M5.25 4A3.26 3.26 0 0 0 2 7.25v9.5A3.26 3.26 0 0 0 5.25 20h13.5A3.26 3.26 0 0 0 22 16.75v-9.5A3.26 3.26 0 0 0 18.75 4zm0 1.5h13.5c.974 0 1.75.776 1.75 1.75v.552L12 12.398 3.5 7.802V7.25c0-.974.776-1.75 1.75-1.75M3.5 9.508l8.143 4.4a.74.74 0 0 0 .714 0l8.143-4.4v7.242a1.74 1.74 0 0 1-1.75 1.75H5.25a1.74 1.74 0 0 1-1.75-1.75z"
      ></path>
    </svg>
  )
}

MailEnvelopeIcon.displayName = 'MailEnvelopeIcon'
