import { IconComponent } from '@/lib/types'

export const CancelCrossFilledStatusIcon: IconComponent = ({
  ...properties
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...properties}
    >
      <path
        fill="currentColor"
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2m3.781 12.719a.754.754 0 0 1 0 1.062.753.753 0 0 1-1.062 0L12 13.06 9.281 15.78a.753.753 0 0 1-1.062 0 .754.754 0 0 1 0-1.062L10.94 12 8.22 9.281a.754.754 0 0 1 0-1.062.754.754 0 0 1 1.062 0L12 10.94l2.719-2.721a.754.754 0 0 1 1.062 0 .754.754 0 0 1 0 1.062L13.06 12z"
      />
    </svg>
  )
}

CancelCrossFilledStatusIcon.displayName = 'CancelCrossFilledStatusIcon'
