import { motion } from 'framer-motion'
import noop from 'lodash/noop'
import { useCallback, useMemo } from 'react'

import { Avatar } from '@/components/ui/avatar/Avatar'
import { ArrowRightIcon } from '@/features/task/components/subtasks/ArrowRightIcon'
import { SubtaskPopoverRenameForm } from '@/features/task/components/subtasks/SubtaskPopoverRenameForm'
import { TaskStatusCheckbox } from '@/features/task/components/TaskStatusCheckbox'
import { TaskData } from '@/features/task/hooks/useTask'
import { useTaskStack } from '@/features/task/hooks/useTaskStack'
import { useUpdateTask } from '@/features/task/hooks/useUpdateTask'
import { staggerMotionVariants } from '@/features/task/utils/constants'
import { useTaskItemKeyNavigation } from '@/lib/hooks/useTaskItemKeyNavigation'
import { cn } from '@/lib/utilities'
import { Link } from '@tanstack/react-router'

type SubtaskData = TaskData['subtasks'][number]

interface ListItemProperties {
  task: SubtaskData
  isHighlighted: boolean
  selectedItemIds: string[]
}

const ListItem = ({
  isHighlighted,
  selectedItemIds,
  task,
}: ListItemProperties) => {
  const linkParameters = useMemo(
    () => ({
      taskId: task.id,
    }),
    [task.id]
  )

  const isFirstSelectedItem = selectedItemIds[0] === task.id
  const isLastSelectedItem = selectedItemIds.at(-1) === task.id

  const disableEventPropagation = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation()
    },
    []
  )

  return (
    <div
      className={cn(
        'flex h-11.5 cursor-pointer items-center border border-transparent border-b-film-subtle px-3.75',
        {
          'rounded-b-none': isFirstSelectedItem && selectedItemIds.length > 1,
          'rounded-lg border-sky-300 bg-sky-50': isHighlighted,
          'rounded-t-none': isLastSelectedItem && selectedItemIds.length > 1,
          'rounded-t-none rounded-b-none':
            !isFirstSelectedItem &&
            !isLastSelectedItem &&
            selectedItemIds.length > 1,
        }
      )}
    >
      <TaskStatusCheckbox
        id={task.id}
        data-testid="checkbox"
        status={task.status}
        onTaskStatusChange={noop}
        className="mr-3 shrink-0 rounded-full"
      />

      <p
        className={cn('overflow-hidden text-nowrap text-ellipsis', {
          'text-mono-ink-subtle line-through': task.status === 'CANCELLED',
        })}
      >
        {task.title}
      </p>

      <div className="ml-auto flex items-center gap-3 pl-1">
        {task.assignee && (
          <Avatar
            src={task.assignee.avatarUrl}
            alt={task.assignee.name}
            tooltip={task.assignee.name}
            boringAvatarSeed={task.assignee.id}
            className="size-5 max-w-none rounded"
          />
        )}

        <Link
          data-testid={`open-subtask-${task.id}`}
          className="flex h-6 w-6 min-w-6 transform items-center justify-center rounded-full bg-film-subtle focus:rounded-lg focus:bg-sky-50 focus:outline focus:outline-sky-300"
          to="/tasks/$taskId"
          params={linkParameters}
          onClick={disableEventPropagation}
        >
          <ArrowRightIcon className="size-3" />
          <span className="sr-only">Go to {task.title} task</span>
        </Link>
      </div>
    </div>
  )
}

ListItem.displayName = 'ListItem'

interface SubtasksListItemProperties {
  task: SubtaskData
  isContextMenuOpen: boolean
  isDeleteDialogOpen: boolean
  isRenaming: boolean
  setNotRenaming: () => void
  focused: boolean
  selected: boolean
  selectedItemIds: string[]
  adjacentTaskIds?: string[]
}

export const SubtasksListItem = ({
  adjacentTaskIds,
  focused,
  isContextMenuOpen,
  isDeleteDialogOpen,
  isRenaming,
  selected,
  selectedItemIds,
  setNotRenaming,
  task,
}: SubtasksListItemProperties) => {
  const isHighlighted = selected || focused

  const { handleOpenStack, navigateToTask } = useTaskStack(
    task.id,
    adjacentTaskIds
  )

  const { updateTask } = useUpdateTask(task.id)

  const taskNavigationParameters = useMemo(
    () => !isRenaming && !isDeleteDialogOpen && !isContextMenuOpen && focused,
    [isContextMenuOpen, isDeleteDialogOpen, isRenaming, focused]
  )

  useTaskItemKeyNavigation(
    navigateToTask,
    handleOpenStack,
    taskNavigationParameters
  )

  const handleUpdateTitle = useCallback(
    (title: string) => {
      updateTask({
        id: task.id,
        title,
      })
    },
    [task, updateTask]
  )

  const handleOpenStackByClicking = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.button === 0 && taskNavigationParameters) {
        handleOpenStack()
      }
    },
    [handleOpenStack, taskNavigationParameters]
  )

  return (
    <motion.div
      variants={staggerMotionVariants}
      initial="hidden"
      animate="visible"
      data-testid={`subtask-listitem-${task.id}`}
      id={task.id}
      onDoubleClick={navigateToTask}
      onClick={handleOpenStackByClicking}
    >
      {isRenaming && (
        <SubtaskPopoverRenameForm
          isCompleted={!!task.completedAt}
          isRenaming={isRenaming}
          onRename={handleUpdateTitle}
          setNotRenaming={setNotRenaming}
          taskId={task.id}
          title={task.title}
        />
      )}

      <ListItem
        selectedItemIds={selectedItemIds}
        isHighlighted={isHighlighted}
        task={task}
      />
    </motion.div>
  )
}

SubtasksListItem.displayName = 'SubtasksListItem'
