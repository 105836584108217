import { FC, useCallback } from 'react'

import { StackCardBodyProperties } from '@/components/layout/Stack'
import { TaskHeader } from '@/features/task/components/TaskHeader'
import { TaskThread } from '@/features/task/components/TaskThread'
import { useUpdateTask } from '@/features/task/hooks/useUpdateTask'
import { UpdateTaskInput } from '@/gql/generated/graphql'

export const TaskStackCardBody: FC<StackCardBodyProperties> = ({ id }) => {
  const { updateTask } = useUpdateTask(id)

  const handleUpdateTask = useCallback(
    (data: Partial<UpdateTaskInput>) => {
      updateTask({
        ...data,
        id: id ?? '',
      })
    },
    [id, updateTask]
  )

  return (
    <>
      <TaskHeader onSubmit={handleUpdateTask} hideTabs taskId={id} />
      <TaskThread
        id={id}
        isChatOverlayVisible={false}
        data-testid="thread-tab-content"
      />
    </>
  )
}
TaskStackCardBody.displayName = 'TaskStackCardBody'
