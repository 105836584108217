import { Variants } from 'framer-motion'

export const TASK_ROUTE_PATH = '/_private/tasks/$taskId'
export const UNGROUNDED_TASK_ERROR = 'Task refinement not possible.'

export const staggerMotionVariants: Variants = {
  hidden: {
    opacity: 0,
    y: 0,
  },
  /* v8 ignore next 7 */
  visible: (index, isLastItem) => ({
    opacity: 1,
    transition: {
      delay: isLastItem ? 0 : index * 0.03,
    },
    y: 0,
  }),
}
