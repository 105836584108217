import { useCallback, useMemo, Suspense } from 'react'

import { ForwardSlash } from '@/components/icons/ForwardSlash'
import { PeopleIcon } from '@/components/icons/PeopleIcon'
import { ThreeDotsVerticalIcon } from '@/components/icons/ThreeDotsVerticalIcon'
import { BreadcrumbTitle } from '@/components/layout/BreadCrumbTitle'
import { Skeleton } from '@/components/ui/skeleton/Skeleton'
import { TaskIcon } from '@/features/task/components/TaskIcon'
import { useTask } from '@/features/task/hooks/useTask'
import { TASK_ROUTE_PATH } from '@/features/task/utils/constants'
import { useDocumentTitle } from '@/lib/hooks/useDocumentTitle'
import { useParams } from '@tanstack/react-router'

const BreadcrumbComponent = () => {
  const { taskId } = useParams({ from: TASK_ROUTE_PATH })
  const { task } = useTask(taskId)
  useDocumentTitle(task.title)
  const ParentTaskIcon = useCallback(
    () => <TaskIcon status={task.parentTask?.status} size="sm" />,
    [task.parentTask]
  )

  const CurrentTaskIcon = useCallback(
    () => <TaskIcon status={task.status} size="sm" />,
    [task.status]
  )

  const parentTaskParameters = useMemo(
    () => ({ taskId: task.parentTask?.id }),
    [task.parentTask?.id]
  )
  const currentTaskParameters = useMemo(() => ({ taskId: task.id }), [task.id])
  const currentSpaceParameters = useMemo(
    () => ({ spaceId: task.space.id }),
    [task.space.id]
  )

  return (
    <>
      <BreadcrumbTitle
        Icon={PeopleIcon}
        title={task.space.name}
        className="duration-300 animate-in fade-in"
        data-testid="space-breadcrumb"
        to="/spaces/$spaceId"
        params={currentSpaceParameters}
      />
      <ForwardSlash
        className="size-4 opacity-[0.18] duration-300 animate-in fade-in"
        data-testid="grandparent-breadcrumb-slash"
      />
      {!!task.parentTask?.parentTask && (
        <>
          <ThreeDotsVerticalIcon
            className={'rotate-90 opacity-50 duration-300 animate-in fade-in'}
            data-testid="granparent-breadcrumb"
          />
          <ForwardSlash
            className="size-4 opacity-[0.18] duration-300 animate-in fade-in"
            data-testid="grandparent-breadcrumb-slash"
          />
        </>
      )}

      {!!task.parentTask && (
        <>
          <BreadcrumbTitle
            Icon={ParentTaskIcon}
            title={task.parentTask.title}
            data-testid="parent-task-breadcrumb"
            to="/tasks/$taskId"
            className="duration-300 animate-in fade-in"
            params={parentTaskParameters}
          />

          <ForwardSlash className="size-4 opacity-[0.18]" />
        </>
      )}

      <BreadcrumbTitle
        Icon={CurrentTaskIcon}
        title={task.title}
        className="duration-300 animate-in fade-in"
        data-testid="task-breadcrumb"
        to="/tasks/$taskId"
        params={currentTaskParameters}
      />
    </>
  )
}

BreadcrumbComponent.displayName = 'TaskBreadcrumb'

const SuspendedBreadcrumbComponent = () => {
  const skeleton = useMemo(
    () => (
      <div className="flex flex-row items-center gap-0.5">
        <Skeleton className="size-4 rounded-full bg-film-normal" />
        <Skeleton className="h-4 w-[35px] rounded-xs bg-film-normal" />
        <Skeleton className="h-4 w-[20px] rounded-xs bg-film-normal" />
        <Skeleton className="h-4 w-[100px] rounded-xs bg-film-normal" />
      </div>
    ),
    []
  )

  return (
    <Suspense fallback={skeleton}>
      <BreadcrumbComponent />
    </Suspense>
  )
}

SuspendedBreadcrumbComponent.displayName = 'SuspendedBreadcrumbComponent'

export const TaskBreadcrumb = Object.assign(SuspendedBreadcrumbComponent, {
  Title: BreadcrumbTitle,
})
