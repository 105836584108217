import Lottie, { LottieComponentProps } from 'lottie-react'
import { FC } from 'react'

import animationData from '@/components/ui/loading-indicator/animationData.json'
export const LoadingIndicator: FC<
  Omit<LottieComponentProps, 'animationData'>
> = ({ ...properties }) => {
  return <Lottie animationData={animationData} loop {...properties} />
}
LoadingIndicator.displayName = 'LoadingIndicator'
