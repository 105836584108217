import { IconComponent } from '@/lib/types'
import { cn } from '@/lib/utilities'
import { Link, LinkProps } from '@tanstack/react-router'

interface Properties extends LinkProps {
  Icon: IconComponent
  textClassName?: string
  title: string
  className?: string
}

const activeProperties = { className: 'pointer-events-none' }

export const BreadcrumbTitle = ({
  className,
  Icon,
  textClassName,
  title,
  ...properties
}: Properties) => {
  return (
    <Link
      {...properties}
      className={cn(
        'flex flex-row items-center gap-0.5 rounded-sm p-0.5 text-xs-regular opacity-50 hover:opacity-100',
        className
      )}
      title={title}
      activeProps={activeProperties}
    >
      <Icon className="size-4 min-w-4" />
      <span className={cn('w-full max-w-80 grow truncate', textClassName)}>
        {title}
      </span>
    </Link>
  )
}
BreadcrumbTitle.displayName = 'BreadcrumbTitle'
