import { FC } from 'react'

import { HomeHouseStartIcon } from '@/components/icons/HomeHouseStartIcon'
import { Api } from '@/contexts/ApiProvider'
import { createViewerQueryOptions } from '@/features/index/hooks/useViewerWithTasks'
import { createFileRoute } from '@tanstack/react-router'

export interface MyTasksLoaderData {
  viewer: Awaited<ReturnType<Api['fetchViewerWithTasks']>>
}

export const Route = createFileRoute('/_private/')({
  loader: async ({ context }): Promise<MyTasksLoaderData> => ({
    viewer: await context.queryClient.ensureQueryData(
      createViewerQueryOptions(context)
    ),
  }),
})

const HomeBreadcrumb: FC = () => {
  return (
    <div className="flex items-center gap-2 text-xs-regular text-mono-ink-subtle">
      <HomeHouseStartIcon className="size-4" />
      <p>{Route.interloom?.defaultPageTitle}</p>
    </div>
  )
}

HomeBreadcrumb.displayName = 'HomeBreadcrumb'

Route.interloom = {
  Breadcrumb: HomeBreadcrumb,
  defaultPageTitle: 'My Tasks',
  NavigationIcon: HomeHouseStartIcon,
  tooltipText: 'Home',
}
