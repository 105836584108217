import { IconComponent } from '@/lib/types'

export const CheckedCircleSolidIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...properties}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 12C2 6.487 6.487 2 12 2s10 4.487 10 10-4.487 10-10 10S2 17.513 2 12m15.162-2.67a.9.9 0 0 0-1.38-1.155l-5.208 6.22-2.393-2.525a.9.9 0 0 0-1.306 1.238l3.087 3.259a.9.9 0 0 0 1.344-.042z"
        clipRule="evenodd"
      />
    </svg>
  )
}

CheckedCircleSolidIcon.displayName = 'CheckedCircleSolidIcon'
