import { FC, PropsWithChildren } from 'react'

import { PlusCircleIcon } from '@/components/icons/PlusCircleIcon'
import { TrashIcon } from '@/components/icons/TrashIcon'
import { Button } from '@/components/ui/button/Button'
import {
  ContextMenuContent,
  ContextMenuItem,
} from '@/components/ui/context-menu/ContextMenu'
import { DeleteThreadDialog } from '@/features/task/components/chat/chat-context-menu/DeleteThreadDialog'
import { NewTaskFromPostDialog } from '@/features/task/components/chat/chat-context-menu/NewTaskFromPostDialog'
import { TaskThreadEvent } from '@/features/task/components/chat/threadPayloadTypes'
import { useBoolean } from '@/lib/hooks/useBoolean'
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu'

interface ChatContextMenuProperties extends PropsWithChildren {
  disabled?: boolean
  threadEvent: TaskThreadEvent
}

export const ChatContextMenu: FC<ChatContextMenuProperties> = ({
  children,
  disabled,
  threadEvent,
}) => {
  const {
    setTrue: openNewTaskDialog,
    setValue: setIsNewTaskDialogOpen,
    value: isNewTaskDialogOpen,
  } = useBoolean()
  const {
    setTrue: openDeleteDialog,
    setValue: setIsDeleteDialog,
    value: isDeleteDialogOpen,
  } = useBoolean()

  if (disabled) return <>{children}</>

  return (
    <ContextMenuPrimitive.Root>
      <ContextMenuPrimitive.Trigger
        asChild
        data-testid="chat-context-menu-trigger"
      >
        {children}
      </ContextMenuPrimitive.Trigger>
      <ContextMenuPrimitive.Portal>
        <ContextMenuContent data-testid="chat-context-menu">
          <ContextMenuItem asChild>
            <Button variant="naked" size="sm" onClick={openNewTaskDialog}>
              <PlusCircleIcon />
              Create new task from post
            </Button>
          </ContextMenuItem>

          <ContextMenuItem asChild>
            <Button variant="naked" size="sm" onClick={openDeleteDialog}>
              <TrashIcon />
              Delete
            </Button>
          </ContextMenuItem>
        </ContextMenuContent>
      </ContextMenuPrimitive.Portal>
      <NewTaskFromPostDialog
        isDialogOpen={isNewTaskDialogOpen}
        onOpenChange={setIsNewTaskDialogOpen}
        threadEvent={threadEvent}
      />
      <DeleteThreadDialog
        isDialogOpen={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialog}
        threadEventId={threadEvent.id}
      />
    </ContextMenuPrimitive.Root>
  )
}

ChatContextMenu.displayName = 'ChatContextMenu'
