import { IconComponent } from '@/lib/types'

export const TaskUncheckedCircleSolidIcon: IconComponent = ({
  ...properties
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...properties}
    >
      <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
        <path d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16m0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10" />
        <path
          fillOpacity="0.2"
          d="M12 19.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15"
        />
      </g>
    </svg>
  )
}

TaskUncheckedCircleSolidIcon.displayName = 'TaskUncheckedCircleSolidIcon'
