import { useCallback } from 'react'

import { TaskAssignee } from '@/components/ui/task-assignee/TaskAssignee'
import { Subtasks } from '@/features/task/components/subtasks/Subtasks'
import { TaskDescription } from '@/features/task/components/TaskDescription'
import { TaskDueAtSelect } from '@/features/task/components/TaskDueAtSelect'
import { TaskStatusSelect } from '@/features/task/components/TaskStatusSelect'
import { useCreateTask } from '@/features/task/hooks/useCreateTask'
import { useUpdateTask } from '@/features/task/hooks/useUpdateTask'
import { useUpdateTaskStatus } from '@/features/task/hooks/useUpdateTaskStatus'
import { useUsers } from '@/features/task/hooks/useUsers'
import {
  TaskStatus,
  UpdateTaskInput,
  FetchTasksByIdQuery,
} from '@/gql/generated/graphql'

interface TaskSettingsProperties {
  id: string
  task: FetchTasksByIdQuery['tasks'][number]
}

export const TaskSettings = ({ id, task }: TaskSettingsProperties) => {
  const { users } = useUsers()
  const { isUpdatingTask, updateTask } = useUpdateTask(id)
  const { isUpdatingTaskStatus, updateTaskStatus } = useUpdateTaskStatus(id)
  const { isPending: isCreatingTask, mutate: createTask } = useCreateTask({
    taskId: id,
  })

  const isMutationPending =
    isUpdatingTask || isCreatingTask || isUpdatingTaskStatus
  const handleUpdateTask = useCallback(
    (data: Partial<UpdateTaskInput>) => {
      if (!task.id) return
      updateTask({
        ...data,
        id: task.id,
      })
    },
    [task.id, updateTask]
  )

  const handleStatusChange = useCallback(
    (status: TaskStatus) => {
      if (!task.id) return
      updateTaskStatus({ id: task.id, status })
    },
    [task.id, updateTaskStatus]
  )

  return (
    <div className="w-full max-w-176 border-film-subtle px-5 pb-5 @2xl/task:w-1/2 @2xl/task:flex-1 @2xl/task:overflow-y-auto @2xl/task:border-r">
      <div className="flex gap-2 py-4">
        <TaskAssignee
          className="flex-1"
          assigneeId={task.assignee?.id}
          disabled={isMutationPending}
          onSelect={handleUpdateTask}
          users={users}
        />
        <TaskStatusSelect
          onSelect={handleStatusChange}
          isPending={isMutationPending}
          taskStatus={task.status}
        />
        <TaskDueAtSelect
          onDateChange={handleUpdateTask}
          isPending={isMutationPending}
          dueAt={task.dueAt ?? undefined}
        />
      </div>

      <TaskDescription
        key={task.id}
        onSave={handleUpdateTask}
        taskDescription={task.description ?? undefined}
      />

      <div className="my-5 h-[1px] w-full bg-black/5" />
      <Subtasks subtasks={task.subtasks} createTask={createTask} taskId={id} />
    </div>
  )
}

TaskSettings.displayName = 'TaskSettings'
