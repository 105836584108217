import { createAllWorkQueryOptions } from '@/features/spaces/hooks/useAllWork'
import { createSpacesQueryOptions } from '@/features/spaces/hooks/useSpaces'
import { createFileRoute } from '@tanstack/react-router'
export const Route = createFileRoute('/_private/spaces/$spaceId/')({
  loader: async ({ context, params }) => ({
    space: await context.queryClient.ensureQueryData(
      createSpacesQueryOptions({ api: context.api, id: params.spaceId })
    ),
    tasks: await context.queryClient.ensureQueryData(
      createAllWorkQueryOptions({ api: context.api, spaceId: params.spaceId })
    ),
  }),
})
