import { FC } from 'react'

import { FileIcon } from '@/components/icons/FileIcon'
import { supportedFileTypesIcon } from '@/features/task/components/chat/supportedFileTypes'
import { cn } from '@/lib/utilities'

interface FileTypeIconProperties {
  fileType: string
  className?: string
  defaultIconBackgroundColor?: 'grey' | 'white'
}

export const FileTypeIcon: FC<FileTypeIconProperties> = ({
  className,
  defaultIconBackgroundColor = 'grey',
  fileType,
}) => {
  const icon = supportedFileTypesIcon[fileType]
  if (!icon) {
    return (
      <div
        className={cn(
          defaultIconBackgroundColor === 'grey'
            ? 'bg-mono-paper-darkest'
            : 'bg-white',
          className
        )}
      >
        <FileIcon className="size-full fill-mono-ink-subtle" />
      </div>
    )
  }

  const { icon: Icon, iconClassName, wrapperClassName } = icon
  return (
    <div className={cn(wrapperClassName, className)}>
      <Icon className={cn('size-full', iconClassName)} />
    </div>
  )
}

FileTypeIcon.displayName = 'FileTypeIcon'
