import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts'
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useCookie } from 'react-use'

import { ConfigurationVariables } from '@/lib/configurationVariablesSchemaValidator'
import { logger } from '@/lib/logger'
import { makeErrorFromUnknown } from '@/lib/utilities'

export interface OidcAuthContextState {
  error?: Error
  loading: boolean
  signIn: () => Promise<void>
  signInCallback: () => Promise<void>
  signOut: () => Promise<void>
  user?: User
  getUser: () => Promise<User | undefined>
}

const OidcAuthContext = createContext<OidcAuthContextState | undefined>(
  undefined
)

OidcAuthContext.displayName = 'OidcAuthContext'

export const useOIDCAuth = () => {
  const context = useContext(OidcAuthContext)
  if (!context) {
    throw new Error('useOIDCAuth must be used within OidcAuthProvider')
  }

  return context
}

interface Properties extends PropsWithChildren {
  configurationVariables: ConfigurationVariables
}

const OIDC_STATE_KEY = 'oidc-state'

export const OidcAuthProvider: FC<Properties> = ({
  children,
  configurationVariables,
}) => {
  const [user, setUser] = useState<User>()
  const [networkOperationsInFlight, setNetworkOperationsInFlight] = useState(0)
  const [error, setError] = useState<Error>()
  const {
    PUBLIC_ZITADEL_CLIENT_ID,
    PUBLIC_ZITADEL_ISSUER_URL,
    PUBLIC_ZITADEL_ORGANIZATION_ID,
  } = configurationVariables.env

  const userManager = useMemo(() => {
    const redirectUri = `${globalThis.location.origin}/auth-callback`

    return new UserManager({
      authority: PUBLIC_ZITADEL_ISSUER_URL,
      automaticSilentRenew: true,
      client_id: PUBLIC_ZITADEL_CLIENT_ID,
      redirect_uri: redirectUri,
      scope: [
        'email',
        'openid',
        'profile',
        'offline_access',
        `urn:zitadel:iam:org:id:${PUBLIC_ZITADEL_ORGANIZATION_ID}`,
      ].join(' '),
      silent_redirect_uri: redirectUri,
      // By default, the lib. uses Session Storage
      userStore: new WebStorageStateStore({ store: localStorage }),
    })
  }, [
    PUBLIC_ZITADEL_CLIENT_ID,
    PUBLIC_ZITADEL_ISSUER_URL,
    PUBLIC_ZITADEL_ORGANIZATION_ID,
  ])

  const withInFlightStateUpdate = useCallback(
    async (callback: () => Promise<void>) => {
      setNetworkOperationsInFlight((previousState) => previousState + 1)
      try {
        await callback()
      } catch (error) {
        setError(makeErrorFromUnknown(error))
      } finally {
        setNetworkOperationsInFlight((previousState) => previousState - 1)
      }
    },
    []
  )

  const loading = useMemo(
    () => networkOperationsInFlight > 0,
    [networkOperationsInFlight]
  )

  const storeState = useCallback(
    async (key: string, value: unknown) => {
      await userManager.settings.stateStore.set(key, JSON.stringify(value))
    },
    [userManager]
  )

  const getState = useCallback(
    async (key: string): Promise<{ redirectUrl?: string }> => {
      const state = await userManager.settings.stateStore.get(key)
      return state
        ? (JSON.parse(state) as { redirectUrl?: string })
        : { redirectUrl: undefined }
    },
    [userManager]
  )

  const clearState = useCallback(
    async (key: string) => {
      await userManager.settings.stateStore.remove(key)
    },
    [userManager]
  )

  const isHydrated = useRef<boolean>(false)

  useEffect(() => {
    if (loading || isHydrated.current) return
    void withInFlightStateUpdate(async () => {
      setUser((await userManager.getUser()) ?? undefined)
      isHydrated.current = true
    })
  }, [loading, userManager, withInFlightStateUpdate])

  const signIn = useCallback(async () => {
    await withInFlightStateUpdate(async () => {
      const redirectUrl = new URLSearchParams(globalThis.location.search).get(
        'redirect'
      )

      const customState = { redirectUrl }
      await storeState(OIDC_STATE_KEY, customState)

      await userManager.signinRedirect({ state: customState })
    })
  }, [storeState, userManager, withInFlightStateUpdate])

  const signOut = useCallback(async () => {
    await withInFlightStateUpdate(async () => {
      try {
        await userManager.removeUser()
      } catch (error) {
        logger.error(error)
      }
      globalThis.location.reload()
    })
  }, [userManager, withInFlightStateUpdate])

  const handleSilentRenewError = useCallback(
    async (error: Error) => {
      logger.error(error)
      await signOut()
    },
    [signOut]
  )

  const signInCallbackInProgress = useRef(false)
  const signInCallback = useCallback(async () => {
    if (signInCallbackInProgress.current) return
    signInCallbackInProgress.current = true

    await withInFlightStateUpdate(async () => {
      try {
        await userManager.signinCallback()

        const state = await getState(OIDC_STATE_KEY).catch(() => {
          return { redirectUrl: undefined }
        })

        if (state.redirectUrl) {
          globalThis.location.replace(state.redirectUrl)
          await clearState(OIDC_STATE_KEY)
        }
      } catch (unknownError) {
        const error = makeErrorFromUnknown(unknownError)
        if (error.message === 'No matching state') {
          throw new Error('The code is not valid. Please try to sign in again.')
        }
        throw error
      } finally {
        signInCallbackInProgress.current = false
      }
    })
  }, [clearState, getState, userManager, withInFlightStateUpdate])

  useEffect(() => {
    userManager.events.addSilentRenewError(handleSilentRenewError)
    userManager.events.addUserLoaded(setUser)

    return () => {
      userManager.events.removeSilentRenewError(handleSilentRenewError)
      userManager.events.removeUserLoaded(setUser)
    }
  }, [setUser, userManager, handleSilentRenewError])

  const getUser = useCallback(async () => {
    const user = await userManager.getUser()
    if (!user) {
      return
    }

    if (user.expired) {
      try {
        return (await userManager.signinSilent()) ?? undefined
      } catch (maybeError) {
        logger.error(maybeError)
        await signOut()
      }
      return
    }

    return user
  }, [userManager, signOut])

  const [, setAccessTokenCookie] = useCookie('il_access_token')

  useEffect(() => {
    if (!user?.access_token) return
    setAccessTokenCookie(user.access_token)
  }, [user?.access_token, setAccessTokenCookie])

  return (
    <OidcAuthContext.Provider
      value={{
        error,
        getUser,
        loading,
        signIn,
        signInCallback,
        signOut,
        user,
      }}
    >
      {children}
    </OidcAuthContext.Provider>
  )
}
OidcAuthProvider.displayName = 'OidcAuthProvider'
