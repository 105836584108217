import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { FetchTasksByIdQuery } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'
import { useMutation, useQueryClient } from '@tanstack/react-query'

type Task = FetchTasksByIdQuery['tasks'][number]

export const useUpdateTaskStatus = (parentTaskId?: string) => {
  const api = useApi()
  const queryClient = useQueryClient()

  const parentTaskQueryKey = ['task', parentTaskId]

  const taskStatusMutation = useMutation({
    mutationFn: api.setTaskStatus,
    onMutate: ({ id, status }) => {
      const queryKey = ['task', id]
      const previousStateForTask = queryClient.getQueryData<Task>(queryKey)
      queryClient.setQueryData<Task>(queryKey, (previousState) => {
        if (!previousState) return

        return {
          ...previousState,
          status,
        }
      })

      queryClient.setQueryData<Task>(parentTaskQueryKey, (previousState) => {
        /* v8 ignore next */
        if (!previousState) return

        return {
          ...previousState,
          subtasks: previousState.subtasks.map((task) => {
            if (task.id === id) {
              return {
                ...task,
                status,
              }
            }

            return task
          }),
        }
      })

      return {
        previousState: previousStateForTask,
      }
    },
    // Partition: Important to keep these definitions below onMutate property so the TS compiler infers context properly
    onError: (error, variables, context) => {
      queryClient.setQueryData(['task', variables.id], context?.previousState)
      logger.error(error)
      toast.error('Task update failed!')
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['tasks'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['viewerWithTasks'],
      })
    },
  })

  return {
    isUpdatingTaskStatus: taskStatusMutation.isPending,
    updateTaskStatus: taskStatusMutation.mutate,
  }
}
