import { useEffect } from 'react'

import { useApi } from '@/contexts/ApiProvider'
import { TASK_REFETCH_INTERVAL } from '@/features/task/constants'
import { updateTaskListsCache } from '@/features/task/hooks/useUpdateTask'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'
interface CreateQueryOptionParameters {
  api: RouteContext['api']
  taskId: string
}

export const createTaskQueryOptions = ({
  api,
  taskId,
}: CreateQueryOptionParameters) => {
  return {
    queryFn: async () => {
      const [task] = await api.fetchTasksById([taskId])

      if (!task) {
        throw new Error('Task not found!')
      }

      return task
    },
    queryKey: ['task', taskId],
    refetchInterval: TASK_REFETCH_INTERVAL,
  }
}

export const useTask = (id: string) => {
  const api = useApi()
  const queryClient = useQueryClient()

  const queryOptions = createTaskQueryOptions({ api, taskId: id })

  const { data } = useSuspenseQuery(queryOptions)

  useEffect(() => {
    updateTaskListsCache({ queryClient: queryClient, updatedTask: data })
  }, [data, queryClient])

  return { task: data }
}

export type TaskData = ReturnType<typeof useTask>['task']
