import { FC, useMemo } from 'react'

import { OtherFilesMessagePayload } from '@/features/task/components/chat/chat-message-payloads/OtherFilesMessagePayload'
import { TaskThreadEventPayloadFileMetadata } from '@/features/task/components/chat/threadPayloadTypes'
import { useBoolean } from '@/lib/hooks/useBoolean'

interface VideoMessagePayloadProperties {
  videoFiles: TaskThreadEventPayloadFileMetadata[]
}

const VideoElement: FC<{ file: TaskThreadEventPayloadFileMetadata }> = ({
  file,
}) => {
  const { setTrue: setHasError, value: hasError } = useBoolean(false)
  const files = useMemo(() => [file], [file])

  return hasError ? (
    <OtherFilesMessagePayload files={files} />
  ) : (
    <video
      data-testid="video-player"
      className="max-h-59 rounded-lg"
      controls
      src={file.fileUrl}
      onError={setHasError}
      onContextMenu={(event) => {
        event.stopPropagation()
      }}
    />
  )
}

VideoElement.displayName = 'VideoElement'

export const VideoMessagePayload: FC<VideoMessagePayloadProperties> = ({
  videoFiles,
}) => {
  return (
    <div className="mb-2 flex flex-wrap gap-2">
      {videoFiles.map((file) => (
        <VideoElement key={file.id} file={file} />
      ))}
    </div>
  )
}

VideoMessagePayload.displayName = 'VideoMessagePayload'
