import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import { mergeRefs } from '@/lib/utilities'

type InputFieldProperties = Omit<
  React.HTMLAttributes<HTMLInputElement>,
  'onChange'
> & {
  hideLabel?: boolean
  name: string
  selectAllInitially?: boolean
  placeholder?: string
  onChange?: (value?: string) => void
  required?: boolean
}

export const InputField = React.forwardRef<
  HTMLInputElement,
  InputFieldProperties
>(
  (
    {
      hideLabel,
      name,
      onChange,
      required,
      selectAllInitially,
      ...remainingProperties
    },
    reference
  ) => {
    const { register, watch } = useFormContext()

    const inputElementReferenceCallback = React.useCallback(
      (element: HTMLInputElement | null) => {
        if (element && selectAllInitially) {
          element.selectionStart = 0
        }
      },
      [selectAllInitially]
    )

    const inputRegister = register(name, { required })
    const registerWithReference = {
      ...inputRegister,
      ref: mergeRefs(
        reference,
        inputRegister.ref,
        inputElementReferenceCallback
      ),
    }

    React.useEffect(() => {
      const subscription = watch((value, { name: fieldName }) => {
        if (fieldName === name && onChange) {
          onChange(value[name] as string)
        }
      })
      return () => {
        subscription.unsubscribe()
      }
    }, [watch, name, onChange])

    return (
      <input id={name} {...remainingProperties} {...registerWithReference} />
    )
  }
)

InputField.displayName = 'InputField'
