import { useApi } from '@/contexts/ApiProvider'
import { useTaskFilter } from '@/lib/hooks/useTaskFilters'
import { useSuspenseQuery } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'

const VIEWER_WITH_TASKS_REFETCH_INTERVAL = 5000

interface CreateQueryOptionParameters {
  api: RouteContext['api']
}

export const createViewerQueryOptions = ({
  api,
}: CreateQueryOptionParameters) => {
  return {
    queryFn: api.fetchViewerWithTasks,
    queryKey: ['viewerWithTasks'],
    refetchInterval: VIEWER_WITH_TASKS_REFETCH_INTERVAL,
  }
}

export const useViewerWithTasks = () => {
  const api = useApi()
  const { filterTasks } = useTaskFilter()

  const queryOptions = createViewerQueryOptions({ api })
  const { data } = useSuspenseQuery(queryOptions)

  return {
    viewer: {
      ...data.viewer,
      tasks: filterTasks(data.viewer.tasks),
    },
  }
}
