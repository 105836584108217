import { useCallback, useMemo, KeyboardEvent } from 'react'

import { CrossLargeIcon } from '@/components/icons/CrossLargeIcon'
import { Button } from '@/components/ui/button/Button'
import { Tooltip } from '@/components/ui/tooltip/Tooltip'
import { FileTypeIcon } from '@/features/task/components/chat/FileTypeIcon'
import { getFileType } from '@/features/task/components/chat/supportedFileTypes'
import { UploadState } from '@/lib/hooks/useFileUpload'
import { isImageFile } from '@/lib/utilities'

const GenericFile = ({ file }: { file: File }) => {
  return (
    <div className="flex h-26 w-3xs flex-col justify-between rounded-lg border border-film-strong bg-film-subtle p-2">
      <FileTypeIcon
        fileType={file.type}
        defaultIconBackgroundColor="white"
        className="size-6 rounded-sm p-0.5"
      />
      <label className="truncate text-sm text-mono-ink-strong">
        {file.name}
      </label>
      <label className="text-xs text-mono-ink-subtle">
        {getFileType(file.type)}
      </label>
    </div>
  )
}

GenericFile.displayName = 'GenericFile'

const ChatMessageInputFilePreview = ({
  fileListItem,
  onDelete,
}: {
  fileListItem: UploadState
  onDelete: (fileName: string) => void
}) => {
  const { file, key: uploadItemKey } = fileListItem

  const handleDelete = useCallback(() => {
    onDelete(uploadItemKey)
  }, [onDelete, uploadItemKey])

  const showProgressBar = useMemo(() => !fileListItem.done, [fileListItem])
  const progressBarInlineStyles = useMemo(
    () => ({
      width: `${fileListItem.progress}%`,
    }),
    [fileListItem]
  )

  return (
    <div key={uploadItemKey} className="relative" data-testid="file-preview">
      {isImageFile(file) ? (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          className="h-24 w-24 rounded-md object-cover"
        />
      ) : (
        <GenericFile file={file} />
      )}
      <Tooltip content="Delete">
        <Button
          variant="solid"
          className="focus:border-unset absolute -top-1 -right-1 size-6 min-h-0 rounded-sm border-2 border-mono-paper p-0"
          onClick={handleDelete}
        >
          <CrossLargeIcon className="size-5 p-0 text-white" />
        </Button>
      </Tooltip>
      {showProgressBar && (
        <div className="absolute bottom-0 left-0 h-2 w-full overflow-hidden bg-gray-100">
          <div
            role="progressbar"
            className="absolute h-full bg-blue-500 transition-all duration-300"
            style={progressBarInlineStyles}
          />
        </div>
      )}
    </div>
  )
}

ChatMessageInputFilePreview.displayName = 'ChatMessageInputFilePreview'

export const ChatMessageInputFileList = ({
  fileList,
  onDelete,
  onKeyPress,
}: {
  fileList: UploadState[]
  onKeyPress?: (event: KeyboardEvent<HTMLDivElement>) => void
  onDelete: (fileId: string) => void
}) => {
  const reference = useCallback((node: HTMLDivElement) => {
    if (node) {
      node.focus()
    }
  }, [])

  return (
    <div
      className="mb-3 flex flex-row flex-wrap items-center gap-2 border-b border-film-strong px-3 pb-3 focus:outline-hidden"
      onKeyDown={onKeyPress}
      ref={reference}
      tabIndex={-1}
      data-testid="file-list"
    >
      {fileList.map((fileListItem) => (
        <ChatMessageInputFilePreview
          fileListItem={fileListItem}
          onDelete={onDelete}
          key={fileListItem.key}
        />
      ))}
    </div>
  )
}

ChatMessageInputFileList.displayName = 'ChatMessageInputFileList'
