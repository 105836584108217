import { IconComponent } from '@/lib/types'

export const PullRequestMergedIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M6.75 2.5C5.72917 2.5 4.86979 2.90885 4.3151 3.53385C3.76042 4.15625 3.5 4.95833 3.5 5.75C3.5 6.54167 3.76042 7.34375 4.3151 7.96615C4.71875 8.42187 5.32552 8.69792 6 8.84635V15.1536C5.32552 15.3021 4.71875 15.5781 4.3151 16.0339C3.76042 16.6563 3.5 17.4583 3.5 18.25C3.5 19.0417 3.76042 19.8438 4.3151 20.4661C4.86979 21.0911 5.72917 21.5 6.75 21.5C8.53646 21.5 10 20.0365 10 18.25C10 16.7292 8.91667 15.5 7.5 15.1536V12.7396C8.1276 13.2135 8.90625 13.5 9.75 13.5H14.0833C14.1979 14.0312 14.4349 14.5417 14.8151 14.9661C15.3698 15.5911 16.2292 16 17.25 16C19.0365 16 20.5 14.5365 20.5 12.75C20.5 10.9635 19.0365 9.5 17.25 9.5C16.2292 9.5 15.3698 9.90885 14.8151 10.5339C14.4349 10.9583 14.1979 11.4688 14.0833 12H9.75C8.4974 12 7.5 11.0026 7.5 9.75V8.84635C8.91667 8.5 10 7.27083 10 5.75C10 3.96354 8.53646 2.5 6.75 2.5ZM6.75 4C7.72656 4 8.5 4.77344 8.5 5.75C8.5 6.71094 7.7474 7.46615 6.79427 7.49219C6.76302 7.48958 6.73438 7.48958 6.70313 7.49219C6.09115 7.47917 5.70573 7.27604 5.4349 6.97135C5.15625 6.65625 5 6.20833 5 5.75C5 5.29167 5.15625 4.84375 5.4349 4.52865C5.71354 4.21615 6.10417 4 6.75 4ZM17.25 11C18.2266 11 19 11.7734 19 12.75C19 13.7266 18.2266 14.5 17.25 14.5C16.6042 14.5 16.2135 14.2839 15.9349 13.9714C15.6719 13.6745 15.5208 13.2578 15.5052 12.8255C15.5104 12.7734 15.5104 12.724 15.5078 12.6719C15.5234 12.2422 15.6719 11.8255 15.9349 11.5286C16.2135 11.2161 16.6042 11 17.25 11ZM6.70313 16.5078C6.73438 16.5104 6.76302 16.5104 6.79427 16.5078C7.7474 16.5339 8.5 17.2891 8.5 18.25C8.5 19.2266 7.72656 20 6.75 20C6.10417 20 5.71354 19.7839 5.4349 19.4714C5.15625 19.1562 5 18.7083 5 18.25C5 17.7917 5.15625 17.3438 5.4349 17.0286C5.70573 16.724 6.09115 16.5208 6.70313 16.5078Z"
        fill="currentColor"
      />
    </svg>
  )
}

PullRequestMergedIcon.displayName = 'PullRequestMergedIcon'
