import { FC } from 'react'

import { Skeleton } from '@/components/ui/skeleton/Skeleton'
import { cn } from '@/lib/utilities'

interface EditableTitleSkeletonProperties {
  className?: string
}

export const EditableTitleSkeleton: FC<EditableTitleSkeletonProperties> = ({
  className,
}) => (
  <div
    className={cn(
      'relative flex flex-row flex-wrap items-end justify-between gap-2 border-b border-film-subtle px-4',
      className
    )}
  >
    <div className="flex h-[72px] flex-row items-center gap-2">
      <Skeleton className="my-2 size-10 rounded-lg bg-film-subtle" />
      <Skeleton className="my-2 h-5 w-[70px] bg-film-subtle" />
      <Skeleton className="my-2 h-5 w-[40px] bg-film-subtle" />
      <Skeleton className="my-2 h-5 w-[100px] bg-film-subtle" />
    </div>
  </div>
)

EditableTitleSkeleton.displayName = 'EditableTitleSkeleton'
