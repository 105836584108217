import { FC, useMemo, Suspense } from 'react'
import { toast } from 'react-toastify'

import { ProcessPathIcon } from '@/components/icons/ProcessPathIcon'
import { PullRequestMergedIcon } from '@/components/icons/PullRequestMergedIcon'
import { RadarIcon } from '@/components/icons/RadarIcon'
import { ThreadIcon } from '@/components/icons/ThreadIcon'
import { Button } from '@/components/ui/button/Button'
import { EditableTitleSkeleton } from '@/components/ui/editable-title/EditableTitleSkeleton'
import {
  TaskTitle,
  TaskTitleProperties,
} from '@/features/task/components/TaskTitle'
import { useTask } from '@/features/task/hooks/useTask'
import { IconComponent } from '@/lib/types'
import { makeErrorFromUnknown } from '@/lib/utilities'
import { TASK_DETAILS_TABS } from '@/routes/_private/tasks.$taskId'
import * as Tabs from '@radix-ui/react-tabs'
import { useFlag } from '@unleash/proxy-client-react'

export type TaskTabValue = (typeof TASK_DETAILS_TABS)[number]

const TAB_TRIGGERS: Array<{
  dataTestId: string
  value: TaskTabValue
  text: string
  Icon: IconComponent
}> = [
  {
    dataTestId: 'thread-tab',
    Icon: ThreadIcon,
    text: 'Thread',
    value: 'thread',
  },
  {
    dataTestId: 'flowchart-tab',
    Icon: ProcessPathIcon,
    text: 'Flow Chart',
    value: 'flowchart',
  },
  {
    dataTestId: 'relevant-tab',
    Icon: RadarIcon,
    text: 'Relevant',
    value: 'relevant',
  },
]

interface TaskHeaderProperties extends Omit<TaskTitleProperties, 'task'> {
  hideTabs?: boolean
  taskId: string
}

const useCopyBranchName = (task: ReturnType<typeof useTask>['task']) => {
  const isInterloomDevelopmentToolsEnabled = useFlag(
    'interloom-development-tools'
  )

  if (!isInterloomDevelopmentToolsEnabled) {
    return
  }

  return () => {
    const { id: taskId, title } = task

    /* eslint-disable unicorn/prefer-string-replace-all -- no mixin provided */
    const cleanTitle: string = title
      .toLowerCase()
      .replace(/_/g, '-')
      .replace(/\s+/g, '-')
      .replace(/[^a-zA-Z0-9-]/g, '')
      .replace(/-+/g, '-')
    /* eslint-enable unicorn/prefer-string-replace-all */

    void navigator.clipboard
      .writeText(`task-${taskId.slice(-4)}-${cleanTitle}`.slice(0, 255))
      .then(() => toast.success('Branch name copied to clipboard'))
      .catch((error: unknown) =>
        toast.error(makeErrorFromUnknown(error).message)
      )
  }
}

const TaskHeader: FC<TaskHeaderProperties> = ({
  hideTabs = false,
  onSubmit,
  taskId,
}) => {
  const { task } = useTask(taskId)
  const isFlowChartEnabled = useFlag('activity_flow_data_feature')

  const copyBranchName = useCopyBranchName(task)

  return (
    <div className="relative flex flex-row flex-wrap items-end justify-between gap-2 border-b border-film-subtle px-4">
      <div className="flex grow items-center gap-4">
        <TaskTitle onSubmit={onSubmit} task={task} />
      </div>

      {copyBranchName && (
        <Button
          size="sm"
          className="mb-5"
          title="Copy branch name"
          onClick={copyBranchName}
        >
          <PullRequestMergedIcon />
        </Button>
      )}

      {!hideTabs && (
        <Tabs.List className="mb-5 flex gap-1">
          {TAB_TRIGGERS.map(
            ({ dataTestId, Icon, text, value }) =>
              (isFlowChartEnabled || value !== 'flowchart') && (
                <Tabs.Trigger key={value} value={value} asChild>
                  <Button
                    size="sm"
                    className="bg-transparent text-mono-ink-subtle data-[state='active']:bg-film-normal data-[state='active']:text-mono-ink-strong"
                    data-testid={dataTestId}
                  >
                    <Icon />
                    {text}
                  </Button>
                </Tabs.Trigger>
              )
          )}
        </Tabs.List>
      )}
    </div>
  )
}

TaskHeader.displayName = 'TaskHeader'

const SuspendedTaskHeader = ({
  hideTabs = false,
  onSubmit,
  taskId,
}: TaskHeaderProperties) => {
  return (
    <Suspense
      fallback={useMemo(
        () => (
          <EditableTitleSkeleton />
        ),
        []
      )}
    >
      <TaskHeader onSubmit={onSubmit} taskId={taskId} hideTabs={hideTabs} />
    </Suspense>
  )
}

SuspendedTaskHeader.displayName = 'SuspendedTaskHeader'

export { SuspendedTaskHeader as TaskHeader }
