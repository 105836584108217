import { toast } from 'react-toastify'
import { v7 as uuid } from 'uuid'

import { useApi } from '@/contexts/ApiProvider'
import {
  FetchTasksByIdQuery,
  FetchAllTasksQuery,
  FetchViewerWithTasksQuery,
} from '@/gql/generated/graphql'
import { useHandleOpenStack } from '@/lib/hooks/useHandleOpenStack'
import { logger } from '@/lib/logger'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface UseCreateTaskProperties {
  spaceId?: string
  taskId?: string
  onMutate?: () => void
  skipOnError?: boolean
}

type Task = FetchTasksByIdQuery['tasks'][number]

export const useCreateTask = (properties?: UseCreateTaskProperties) => {
  const api = useApi()
  const queryClient = useQueryClient()
  const { handleOpenStack } = useHandleOpenStack('stack')

  const queryKey = properties?.taskId ? ['task', properties.taskId] : ['task']

  const createTaskMutation = useMutation({
    mutationFn: api.createTask,
    onError: properties?.skipOnError
      ? undefined
      : (error) => {
          logger.error(error)
          toast.error(error.message)
        },
    onMutate: properties?.taskId
      ? async (payload) => {
          const previousState = queryClient.getQueryData<Task>(queryKey)

          queryClient.setQueryData<Task>(queryKey, (previousState) => {
            /* v8 ignore next */
            if (!previousState) return

            return {
              ...previousState,
              subtasks: [
                ...previousState.subtasks,
                {
                  createdAt: new Date().toISOString(),
                  id: uuid(),
                  status: 'OPEN',
                  ...payload,
                },
              ],
            }
          })

          properties.onMutate?.()

          return { previousState }
        }
      : undefined,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey,
      })
    },
    onSuccess: properties?.taskId
      ? undefined
      : (newTask) => {
          handleOpenStack(newTask.id)

          queryClient.setQueryData(
            ['tasks', ...(properties?.spaceId ? [properties.spaceId] : [])],
            (previousState: FetchAllTasksQuery['tasks']) => {
              /* v8 ignore next */
              if (!previousState) return

              return [newTask, ...previousState]
            }
          )

          queryClient.setQueryData(
            ['viewerWithTasks'],
            (previousState: FetchViewerWithTasksQuery) => {
              /* v8 ignore next */
              if (!previousState) return

              return {
                ...previousState,
                viewer: {
                  ...previousState.viewer,
                  tasks: [newTask, ...previousState.viewer.tasks],
                },
              }
            }
          )
        },
  })

  return createTaskMutation
}
