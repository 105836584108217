import { FC, PropsWithChildren, ReactNode } from 'react'

import * as TooltipPrimitive from '@radix-ui/react-tooltip'

interface Properties
  extends PropsWithChildren<
    Omit<TooltipPrimitive.TooltipContentProps, 'content'>
  > {
  content: ReactNode
}

const delayDuration = process.env.NODE_ENV === 'test' ? 0 : 50

export const Tooltip: FC<Properties> = ({
  children,
  content,
  ...properties
}) => {
  if (!content) {
    return <>{children}</>
  }

  return (
    <TooltipPrimitive.Provider delayDuration={delayDuration}>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>

        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            data-testid="tooltip-content"
            forceMount
            sideOffset={4}
            align="center"
            side="top"
            className="animate-fadeIn data-[state=closed]:animate-fadeOut z-40 rounded-sm bg-mono-paper px-2.5 py-1.5 text-sm-regular text-mono-ink-subtle shadow-micro"
            {...properties}
          >
            {content}
            <TooltipPrimitive.Arrow
              offset={10}
              width={11}
              height={5}
              style={{
                clipPath: 'inset(0 -10px -10px -10px)', // Hide the shadow which overlaps the content above the arrow
              }}
              className="fill-mono-paper drop-shadow-micro filter"
            />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

Tooltip.displayName = 'Tooltip'
