import { forwardRef, Fragment, ReactNode, useCallback } from 'react'

import { Avatar } from '@/components/ui/avatar/Avatar'
import { Button } from '@/components/ui/button/Button'
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuPortal,
  ContextMenuSeparator,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
} from '@/components/ui/context-menu/ContextMenu'
import { RadioCircleSolidTinted } from '@/components/ui/select/RadioCircleSolidTinted'
import { UnassignedIcon } from '@/features/task/components/UnassignedIcon'
import { FetchAllUsersQuery, FetchSpacesQuery } from '@/gql/generated/graphql'

interface BaseMenuItem {
  content: ReactNode
  id: string
  onClick?: () => void
}

interface GroupHeaderMenuItem extends BaseMenuItem {
  type: 'groupHeader'
}

interface ItemMenuItem extends BaseMenuItem {
  type: 'item'
}

interface AssignToSubMenuItem extends BaseMenuItem {
  assigneeId?: string | null
  type: 'assignToSubMenu'
  users: FetchAllUsersQuery['users']
  onAssignUser: (assigneeId: string) => void
}

interface MoveToSubMenuItem extends BaseMenuItem {
  spaceId: string
  type: 'moveToSubMenu'
  spaces: FetchSpacesQuery['spaces']
  onMoveTask: (spaceId: string) => void
}

export type MenuItem =
  | GroupHeaderMenuItem
  | ItemMenuItem
  | AssignToSubMenuItem
  | MoveToSubMenuItem

interface GroupedContextMenuContentProperties {
  taskId: string
  testId: string
  menuItems: MenuItem[]
}

export const ContextMenuGroupHeader = ({ title }: { title: string }) => (
  <p className="mt-1.5 mb-1 px-2 text-xs-bold text-mono-ink-subtle">{title}</p>
)

ContextMenuGroupHeader.displayName = 'ContextMenuGroupHeader'

const AssignToSubMenu = ({
  element,
  taskId,
  testId,
}: {
  element: AssignToSubMenuItem
  taskId: string
  testId: string
}) => {
  const handleAssign = useCallback(
    (userId?: string) => () => {
      element.onAssignUser(userId ?? 'unassigned')
    },
    [element]
  )

  return (
    <ContextMenuSub>
      <ContextMenuSubTrigger asChild>
        <Button
          size="sm"
          variant="naked"
          data-testid={`${element.id}-${taskId}`}
        >
          {element.content}
          <span className="ml-auto text-xl">›</span>
        </Button>
      </ContextMenuSubTrigger>

      <ContextMenuPortal>
        <ContextMenuSubContent
          data-testid={`${testId}-context-submenu-${taskId}`}
        >
          <ContextMenuItem asChild onClick={handleAssign()}>
            <Button size="sm" variant="naked">
              <Avatar
                alt="Unassigned"
                FallbackAvatarIcon={UnassignedIcon}
                className="size-6 rounded-full"
              />
              No assignee
              {!element.assigneeId && (
                <RadioCircleSolidTinted
                  className="ml-auto"
                  innerCircleClass="fill-sky-50"
                  outerCircleClass="fill-sky-500"
                />
              )}
            </Button>
          </ContextMenuItem>

          <ContextMenuSeparator />
          <ContextMenuGroupHeader title="People" />

          {element.users.map((user) => (
            <ContextMenuItem
              asChild
              key={user.id}
              onClick={handleAssign(user.id)}
            >
              <Button size="sm" variant="naked">
                <Avatar
                  alt={user.name}
                  src={user.avatarUrl}
                  className="size-6 rounded-full"
                  data-testid={`dropdown-assignee-people-${user.id}-avatar`}
                />
                <span className="max-w-[65%] truncate">{user.name}</span>
                {user.id === element.assigneeId && (
                  <RadioCircleSolidTinted
                    className="ml-auto"
                    innerCircleClass="fill-sky-50"
                    outerCircleClass="fill-sky-500"
                  />
                )}
              </Button>
            </ContextMenuItem>
          ))}
        </ContextMenuSubContent>
      </ContextMenuPortal>
    </ContextMenuSub>
  )
}

AssignToSubMenu.displayName = 'AssignToSubMenu'

const MoveToSubMenu = ({
  element,
  taskId,
  testId,
}: {
  element: MoveToSubMenuItem
  taskId: string
  testId: string
}) => {
  const handleMove = useCallback(
    (spaceId: string) => () => {
      element.onMoveTask(spaceId)
    },
    [element]
  )

  return (
    <ContextMenuSub>
      <ContextMenuSubTrigger asChild>
        <Button
          size="sm"
          variant="naked"
          data-testid={`${element.id}-${taskId}`}
        >
          {element.content}
          <span className="ml-auto text-xl">›</span>
        </Button>
      </ContextMenuSubTrigger>

      <ContextMenuPortal>
        <ContextMenuSubContent
          data-testid={`${testId}-context-submenu-${taskId}`}
        >
          {element.spaces.map((space) => (
            <ContextMenuItem
              asChild
              key={space.id}
              onClick={handleMove(space.id)}
            >
              <Button size="sm" variant="naked">
                <span className="max-w-[65%] truncate">{space.name}</span>
                {space.id === element.spaceId && (
                  <RadioCircleSolidTinted
                    className="ml-auto"
                    innerCircleClass="fill-sky-50"
                    outerCircleClass="fill-sky-500"
                  />
                )}
              </Button>
            </ContextMenuItem>
          ))}
        </ContextMenuSubContent>
      </ContextMenuPortal>
    </ContextMenuSub>
  )
}

MoveToSubMenu.displayName = 'MoveToSubMenu'

export const GroupedContextMenuContent = forwardRef<
  HTMLDivElement,
  GroupedContextMenuContentProperties
>(({ menuItems, taskId, testId }, reference) => {
  return (
    <ContextMenuContent
      ref={reference}
      data-testid={`${testId}-context-menu-${taskId}`}
      autoFocus
    >
      {menuItems.map((element) => {
        if (element.type === 'groupHeader') {
          return <Fragment key={element.id}>{element.content}</Fragment>
        }

        if (element.type === 'assignToSubMenu') {
          return (
            <AssignToSubMenu
              key={element.id}
              element={element}
              taskId={taskId}
              testId={testId}
            />
          )
        }

        if (element.type === 'moveToSubMenu') {
          return (
            <MoveToSubMenu
              key={element.id}
              element={element}
              taskId={taskId}
              testId={testId}
            />
          )
        }

        return (
          <ContextMenuItem asChild key={element.id}>
            <Button
              size="sm"
              variant="naked"
              onClick={element.onClick}
              data-testid={`${element.id}-${taskId}`}
              className="group"
            >
              {element.content}
            </Button>
          </ContextMenuItem>
        )
      })}
    </ContextMenuContent>
  )
})

GroupedContextMenuContent.displayName = 'GroupedContextMenuContent'
